import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Avatar } from '../General/Avatar';
import SignOutContainer from '../../containers/SignOutContainer';
import { HomeOutlined, CustomerServiceOutlined, LoginOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n/index';

const AdminHeader = ({ userData, firebase }) => {
  const dropdownRef = useRef(null);
  const dropdownDesktopRef = useRef(null);
  const { t } = useTranslation();
  const changeLanguage = lng => {
    i18n.changeLanguage(lng);
  };

  useEffect(() => {
    // Add event listener to close the popup when clicked outside
    const handleClickOutside = event => {
      if (dropdownDesktopRef.current && !dropdownDesktopRef.current.contains(event.target)) {
        dropdownDesktopRef.current.classList.remove('show');
        // Your code to handle closing the popup goes here
      }
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        dropdownRef.current.classList.remove('show');
        // Your code to handle closing the popup goes here
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  function onOpenToggler(e) {
    e.stopPropagation();
    dropdownRef.current.classList.toggle('show');
  }

  function onOpenTogglerDesktop(e) {
    e.stopPropagation();
    dropdownDesktopRef.current.classList.toggle('show');
  }

  function showSupport() {
    window.HubSpotConversations.widget.open();
  }

  function hideDropDown() {
    dropdownRef.current.classList.remove('show');
    dropdownDesktopRef.current.classList.remove('show');
  }

  return (
    <>
      <header
        className="dynamic-top"
        // className="dynamic-top out-of-view stick-it"
        data-controller="class-toggler"
        data-class-toggler-name-value="mobile-search"
        data-stick-it-target="header"
      >
        <nav data-stick-it-target="nav" className="navbar navbar-main navbar-height navbar-expand-lg theme-transparent">
          <div className="container-fluid gap-3">
            <div className="d-flex flex-grow-1 align-items-center">
              <a id="brand" className="navbar-brand" href="https://decorify.pro/vi/?ref=decorify">
                <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="130" zoomAndPan="magnify" viewBox="0 0 150 32.999998" height="44" version="1.0">
                  <defs>
                    <g />
                  </defs>
                  <g fill="#fff" fillOpacity="1">
                    <g transform="translate(15.39844, 24.226069)">
                      <g>
                        <path d="M 20.773438 -19.277344 C 17.890625 -19.304688 15.335938 -16.28125 13.894531 -13.894531 C 14.285156 -15.253906 14.867188 -17.140625 15.253906 -18.5 C 15.171875 -19.25 13.089844 -19.582031 12.5625 -18.75 C 9.320312 -14.808594 3.382812 -16.058594 2.523438 -12.148438 C 2.109375 -10.261719 3.46875 -8.375 6.046875 -8.460938 C 6.824219 -8.460938 7.042969 -9.902344 6.574219 -10.011719 C 5.546875 -10.261719 5.324219 -10.957031 5.546875 -11.925781 C 5.992188 -13.949219 9.402344 -13.699219 11.648438 -16.140625 C 10.039062 -10.871094 8.402344 -4.742188 6.960938 -0.472656 C 6.960938 0.472656 9.707031 0.5 10.039062 -0.304688 C 10.621094 -1.664062 11.425781 -4.910156 12.648438 -8.875 C 14.226562 -12.785156 17.527344 -17.140625 19.691406 -17.140625 C 21.46875 -17.167969 20.605469 -14.144531 20.023438 -12.230469 C 18.027344 -5.769531 15.949219 1.164062 20.441406 2.328125 C 21.660156 2.578125 22.742188 0.664062 22.132812 0.167969 C 19.414062 -1.25 21.605469 -6.378906 22.992188 -11.566406 C 23.574219 -13.699219 25.542969 -19.25 20.773438 -19.277344 Z M 20.773438 -19.277344 " />
                      </g>
                    </g>
                  </g>
                  <g fill="#fff" fillOpacity="1">
                    <g transform="translate(40.04733, 24.226069)">
                      <g>
                        <path d="M 6.988281 -11.816406 C 3.714844 -11.757812 0.75 -9.261719 -0.0273438 -5.4375 C -0.859375 -1.21875 1.332031 0.277344 4.023438 0.277344 C 5.851562 0.277344 7.40625 -0.417969 8.652344 -1.636719 C 10.679688 -1.21875 13.035156 -1.636719 14.839844 -5.574219 C 15.003906 -5.933594 14.035156 -6.460938 13.867188 -6.128906 C 12.675781 -3.328125 11.066406 -2.664062 9.707031 -2.800781 C 12.066406 -6.683594 11.925781 -11.898438 6.988281 -11.816406 Z M 6.628906 -9.847656 C 8.707031 -9.957031 8.402344 -6.878906 7.320312 -4.328125 C 6.546875 -4.492188 5.824219 -3.46875 6.351562 -2.664062 C 5.851562 -2.082031 5.160156 -1.691406 4.464844 -1.691406 C 2.746094 -1.691406 2.828125 -3.6875 3.160156 -5.410156 C 3.578125 -7.488281 4.992188 -9.761719 6.628906 -9.847656 Z M 6.628906 -9.847656 " />
                      </g>
                    </g>
                  </g>
                  <g fill="#fff" fillOpacity="1">
                    <g transform="translate(53.744236, 24.226069)">
                      <g>
                        <path d="M 3.855469 -13.421875 C 4.964844 -13.421875 5.742188 -14.117188 5.851562 -15.117188 C 5.992188 -16.085938 5.378906 -16.78125 4.382812 -16.78125 C 3.355469 -16.808594 2.578125 -16.113281 2.414062 -15.117188 C 2.273438 -14.144531 2.941406 -13.421875 3.855469 -13.421875 Z M 1.746094 0.25 C 4.632812 0.195312 6.710938 -3.050781 7.875 -5.546875 C 8.042969 -5.90625 7.074219 -6.488281 6.90625 -6.128906 C 5.964844 -3.855469 4.132812 -1.691406 2.855469 -1.691406 C 2.273438 -1.664062 2.300781 -2.414062 2.523438 -3.523438 C 2.941406 -5.410156 3.523438 -7.210938 4.605469 -11.066406 C 4.410156 -11.730469 2.136719 -12.066406 1.691406 -11.425781 C 0.722656 -8.070312 0.0546875 -5.824219 -0.554688 -3.21875 C -1.054688 -1.082031 -0.554688 0.304688 1.746094 0.25 Z M 1.746094 0.25 " />
                      </g>
                    </g>
                  </g>
                  <g fill="#fff" fillOpacity="1">
                    <g transform="translate(60.454055, 24.226069)">
                      <g>
                        <path d="M 3.410156 0.25 C 6.183594 0.222656 8.210938 -2.082031 9.707031 -5.546875 C 9.847656 -5.90625 8.902344 -6.460938 8.738281 -6.128906 C 7.570312 -3.496094 5.601562 -1.664062 3.992188 -1.691406 C 2.636719 -1.71875 2.636719 -3.605469 3.21875 -6.15625 C 3.46875 -7.320312 3.882812 -8.792969 4.300781 -10.234375 L 6.90625 -10.234375 C 7.320312 -10.371094 7.65625 -11.675781 7.128906 -12.066406 L 4.796875 -12.066406 C 5.4375 -14.171875 6.074219 -16.140625 6.683594 -17.832031 C 6.65625 -18.636719 4.132812 -18.941406 3.746094 -18.386719 C 3.191406 -16.972656 2.328125 -14.394531 1.609375 -12.066406 L 0.5 -12.066406 C 0.0546875 -11.898438 -0.277344 -10.539062 0.277344 -10.234375 L 1.109375 -10.234375 C -0.167969 -5.378906 -1.441406 0.304688 3.410156 0.25 Z M 3.410156 0.25 " />
                      </g>
                    </g>
                  </g>
                  <g fill="#fff" fillOpacity="1">
                    <g transform="translate(68.99383, 24.226069)">
                      <g>
                        <path d="M 4.078125 -18.859375 C 2.496094 -14.644531 0.25 -6.824219 -1.25 -0.554688 C -1.527344 0.527344 1.804688 0.25 1.941406 -0.304688 C 2.441406 -2.605469 2.828125 -4.132812 3.550781 -6.765625 C 4.519531 -8.210938 5.992188 -9.539062 6.933594 -9.570312 C 7.933594 -9.597656 7.820312 -8.652344 7.597656 -7.820312 C 7.292969 -6.65625 6.433594 -3.9375 6.269531 -2.746094 C 6.019531 -1.027344 6.40625 0.359375 8.707031 0.25 C 11.425781 0.140625 13.394531 -3.078125 14.5625 -5.574219 C 14.726562 -5.90625 13.730469 -6.488281 13.589844 -6.128906 C 12.621094 -3.828125 10.925781 -1.71875 9.847656 -1.691406 C 8.625 -1.609375 9.734375 -4.242188 10.761719 -7.960938 C 11.398438 -10.179688 10.84375 -11.84375 8.597656 -11.816406 C 6.824219 -11.816406 5.101562 -10.152344 4.078125 -8.984375 C 4.992188 -12.230469 6.183594 -16.308594 6.988281 -18.25 C 7.15625 -19 4.632812 -19.414062 4.078125 -18.859375 Z M 4.078125 -18.859375 " />
                      </g>
                    </g>
                  </g>
                  <g fill="#fff" fillOpacity="1">
                    <g transform="translate(82.385739, 24.226069)">
                      <g>
                        <path d="M 6.601562 -11.730469 C 3.46875 -11.203125 0.222656 -8.015625 -0.386719 -4.300781 C -0.886719 -1.191406 0.664062 0.277344 2.691406 0.25 C 4.9375 0.195312 6.15625 -1.527344 7.128906 -3.21875 C 6.796875 -1.164062 7.542969 0.109375 8.875 0.109375 C 11.480469 0.109375 13.644531 -2.550781 15.085938 -5.574219 C 15.253906 -5.90625 14.253906 -6.460938 14.117188 -6.128906 C 13.117188 -3.714844 11.234375 -1.832031 10.207031 -1.804688 C 9.570312 -1.804688 9.402344 -2.441406 9.707031 -3.828125 C 9.957031 -5.1875 10.457031 -6.851562 10.761719 -7.90625 C 10.648438 -8.488281 8.597656 -9.070312 8.097656 -8.429688 C 7.15625 -4.882812 4.382812 -0.664062 3.023438 -1.996094 C 2.386719 -2.636719 2.550781 -4.519531 3.410156 -6.324219 C 4.269531 -8.152344 5.796875 -9.570312 7.40625 -9.789062 C 8.679688 -9.957031 10.234375 -9.570312 11.371094 -8.652344 C 11.816406 -8.429688 12.953125 -9.707031 12.675781 -10.152344 C 11.121094 -11.730469 8.429688 -12.035156 6.601562 -11.730469 Z M 6.601562 -11.730469 " />
                      </g>
                    </g>
                  </g>
                  <g fill="#fff" fillOpacity="1">
                    <g transform="translate(96.332183, 24.226069)">
                      <g>
                        <path d="M 3.410156 0.25 C 6.183594 0.222656 8.210938 -2.082031 9.707031 -5.546875 C 9.847656 -5.90625 8.902344 -6.460938 8.738281 -6.128906 C 7.570312 -3.496094 5.601562 -1.664062 3.992188 -1.691406 C 2.636719 -1.71875 2.636719 -3.605469 3.21875 -6.15625 C 3.46875 -7.320312 3.882812 -8.792969 4.300781 -10.234375 L 6.90625 -10.234375 C 7.320312 -10.371094 7.65625 -11.675781 7.128906 -12.066406 L 4.796875 -12.066406 C 5.4375 -14.171875 6.074219 -16.140625 6.683594 -17.832031 C 6.65625 -18.636719 4.132812 -18.941406 3.746094 -18.386719 C 3.191406 -16.972656 2.328125 -14.394531 1.609375 -12.066406 L 0.5 -12.066406 C 0.0546875 -11.898438 -0.277344 -10.539062 0.277344 -10.234375 L 1.109375 -10.234375 C -0.167969 -5.378906 -1.441406 0.304688 3.410156 0.25 Z M 3.410156 0.25 " />
                      </g>
                    </g>
                  </g>
                  <g fill="#fff" fillOpacity="1">
                    <g transform="translate(104.871958, 24.226069)">
                      <g>
                        <path d="M 5.769531 0.25 C 9.207031 0.195312 10.84375 -2.523438 12.453125 -6.40625 C 13.757812 -6.269531 15.171875 -6.101562 16.003906 -6.046875 C 15.476562 -3.992188 14.976562 -1.96875 14.753906 -0.5 C 15.058594 0.277344 17.472656 0.277344 17.890625 -0.5 C 18.109375 -2.082031 18.664062 -4.1875 19.109375 -6.101562 C 21.191406 -6.269531 23.242188 -6.933594 24.519531 -8.515625 C 24.824219 -8.902344 24.269531 -9.902344 23.769531 -9.402344 C 22.769531 -8.347656 21.132812 -7.875 19.386719 -7.765625 C 19.859375 -9.570312 20.914062 -13.867188 20.941406 -16.558594 C 20.941406 -19.441406 17.527344 -20.082031 15.085938 -17.5 C 13.144531 -15.449219 11.789062 -12.203125 10.261719 -8.570312 C 6.324219 -8.902344 2.605469 -7.210938 1.914062 -3.910156 C 1.441406 -1.441406 2.746094 0.304688 5.769531 0.25 Z M 16.390625 -7.820312 C 15.640625 -7.875 14.285156 -8.042969 13.175781 -8.210938 C 14.285156 -11.148438 15.53125 -14.589844 17.085938 -16.445312 C 17.890625 -17.390625 18.554688 -17.140625 18.386719 -16.085938 C 17.945312 -13.480469 16.972656 -9.902344 16.390625 -7.820312 Z M 5.851562 -1.71875 C 4.769531 -1.71875 4.605469 -2.800781 4.910156 -3.882812 C 5.519531 -5.796875 7.292969 -6.683594 9.484375 -6.628906 C 8.320312 -3.773438 7.433594 -1.746094 5.851562 -1.71875 Z M 5.851562 -1.71875 " />
                      </g>
                    </g>
                  </g>
                  <g fill="#fff" fillOpacity="1">
                    <g transform="translate(127.884972, 24.226069)">
                      <g>
                        <path d="M 3.855469 -13.421875 C 4.964844 -13.421875 5.742188 -14.117188 5.851562 -15.117188 C 5.992188 -16.085938 5.378906 -16.78125 4.382812 -16.78125 C 3.355469 -16.808594 2.578125 -16.113281 2.414062 -15.117188 C 2.273438 -14.144531 2.941406 -13.421875 3.855469 -13.421875 Z M 1.746094 0.25 C 4.632812 0.195312 6.710938 -3.050781 7.875 -5.546875 C 8.042969 -5.90625 7.074219 -6.488281 6.90625 -6.128906 C 5.964844 -3.855469 4.132812 -1.691406 2.855469 -1.691406 C 2.273438 -1.664062 2.300781 -2.414062 2.523438 -3.523438 C 2.941406 -5.410156 3.523438 -7.210938 4.605469 -11.066406 C 4.410156 -11.730469 2.136719 -12.066406 1.691406 -11.425781 C 0.722656 -8.070312 0.0546875 -5.824219 -0.554688 -3.21875 C -1.054688 -1.082031 -0.554688 0.304688 1.746094 0.25 Z M 1.746094 0.25 " />
                      </g>
                    </g>
                  </g>
                </svg>
              </a>
            </div>
            {/* <div id="nav" className="d-flex align-items-center">
              <div className="d-flex align-items-center">
                <div className="offcanvas flower-gradient" tabIndex={-1} id="offcanvasNavigation" ref={dropdownRef}>
                  <div className="d-flex d-lg-none ms-auto p-3">
                    <button
                      className="btn btn-ghost-light btn-sm rounded-pill btn-icon text-white text-nowrap"
                      style={{}}
                      type="default"
                      data-bs-dismiss="offcanvas"
                      onClick={onOpenToggler}
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" width={10} height={10} viewBox="0 0 10 10" fill="none" className="svg-icon">
                        <g clipPath="url(#acef96ee167e529de66b)">
                          <path
                            d="M8.53553 1.46447C8.61367 1.54261 8.65757 1.64859 8.65757 1.75909C8.65757 1.8696 8.61367 1.97558 8.53553 2.05372L5.58926 5L8.53553 7.94628C8.61367 8.02442 8.65757 8.1304 8.65757 8.24091C8.65757 8.35141 8.61367 8.45739 8.53553 8.53553C8.45739 8.61367 8.35141 8.65757 8.24091 8.65757C8.1304 8.65757 8.02442 8.61367 7.94628 8.53553L5 5.58926L2.05372 8.53553C1.97558 8.61367 1.8696 8.65757 1.75909 8.65757C1.64859 8.65757 1.54261 8.61367 1.46447 8.53553C1.38633 8.45739 1.34243 8.35141 1.34243 8.24091C1.34243 8.1304 1.38633 8.02442 1.46447 7.94628L4.41074 5L1.46447 2.05372C1.38633 1.97558 1.34243 1.8696 1.34243 1.75909C1.34243 1.64859 1.38633 1.54261 1.46447 1.46447C1.54261 1.38633 1.64859 1.34243 1.75909 1.34243C1.8696 1.34243 1.97558 1.38633 2.05372 1.46447L5 4.41074L7.94628 1.46447C8.02442 1.38633 8.1304 1.34243 8.24091 1.34243C8.35141 1.34243 8.45739 1.38633 8.53553 1.46447Z"
                            fill="black"
                          />
                        </g>
                        <defs>
                          <clipPath id="acef96ee167e529de66b">
                            <rect width={10} height={10} fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </button>
                  </div>
                  <div className="offcanvas-body pt-0 px-3 pb-3 p-lg-0">
                    {userData ? (
                      <div className="nav flex-column flex-lg-row">
                        <div className="dropdown-divider d-lg-none order-3" />
                        <a className="nav-item d-lg-none order-5" href="/signin">
                          <div className="nav-link d-flex d-lg-none fw-semi-bold">
                            <SignOutContainer t={t} />
                          </div>
                        </a>
                        <div className="dropdown-divider order-5" />
                        <div className="nav-item d-lg-none order-5">
                          <div className="nav-link d-flex d-lg-none fw-semi-bold">{t('header.nav.supportTitle')}</div>
                        </div>
                        <div className="text-indent-2 order-5" onClick={hideDropDown}>
                          <div className="nav-item d-lg-none order-5">
                            <a className="nav-link d-flex d-lg-none" onClick={showSupport} style={{ cursor: 'pointer' }}>
                              <CustomerServiceOutlined />
                              <span style={{ marginLeft: '5px' }}>{t('header.nav.supportContact')}</span>
                            </a>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="nav flex-column flex-lg-row">
                        <div className="dropdown-divider d-lg-none order-3" />
                        <div className="nav-item d-lg-none order-5">
                          <Link className="nav-link d-flex d-lg-none fw-semi-bold" to="/signin">
                            <LoginOutlined />
                            <span style={{ marginLeft: '5px' }}>{t('header.nav.signIn')}</span>
                          </Link>
                        </div>
                        <div className="dropdown-divider order-5" />
                        <div className="nav-item d-lg-none order-5">
                          <Link className="nav-link d-flex d-lg-none fw-semi-bold" to="/signup">
                            <LoginOutlined />
                            <span style={{ marginLeft: '5px' }}>{t('header.nav.signUp')}</span>
                          </Link>
                        </div>
                        <div className="dropdown-divider order-5" />
                        <div className="nav-item d-lg-none order-5">
                          <div className="nav-link d-flex d-lg-none fw-semi-bold">{t('header.nav.supportTitle')}</div>
                        </div>
                        <div className="text-indent-2 order-5">
                          <div className="nav-item d-lg-none order-5">
                            <a className="nav-link d-flex d-lg-none" onClick={showSupport} style={{ cursor: 'pointer' }}>
                              <CustomerServiceOutlined />
                              <span style={{ marginLeft: '5px' }}>{t('header.nav.supportContact')}</span>
                            </a>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                {!userData?.emailVerified && (
                  <Link to="/signup" className="btn btn-outline-light btn-sm rounded-pill text-nowrap" style={{}} type="default">
                    {t('button.getStarted')}
                  </Link>
                )}

                <button className="navbar-toggler navbar-dropdown-account-wrapper ms-2" type="button" onClick={onOpenToggler}>
                  {userData?.emailVerified ? (
                    <>
                      <Avatar
                        size={40}
                        initials={`${userData?.firstName?.charAt(0).toUpperCase() ?? ''} ${
                          userData?.lastName?.charAt(0).toUpperCase() ?? ''
                        }`}
                      />
                      <span className="avatar-name">{`${userData?.firstName ?? ''} ${userData?.lastName ?? ''}`}</span>
                    </>
                  ) : (
                    <span className="avatar flex-shrink-0 avatar-base avatar-circle">
                      <div className="avatar-initials bg-white">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={16}
                          height={16}
                          viewBox="0 0 16 16"
                          fill="none"
                          className="svg-icon rounded-0"
                        >
                          <g clipPath="url(#4f25a6d6acd8dd3fbe3e)">
                            <path
                              d="M8 0C3.58849 0 0 3.58849 0 8C0 12.4115 3.58849 16 8 16C12.4115 16 16 12.4115 16 8C16 3.58849 12.4115 0 8 0ZM8 2.85712C9.57119 2.85712 10.8571 4.14313 10.8571 5.71431C10.8571 7.2855 9.57119 8.57144 8 8.57144C6.42881 8.57144 5.14286 7.2855 5.14286 5.71431C5.14286 4.14313 6.42881 2.85712 8 2.85712V2.85712ZM5.71429 9.71431H10.2857C11.8459 9.71431 13.1199 10.9781 13.1406 12.5335C11.8852 13.9566 10.052 14.8571 8 14.8571C5.94803 14.8571 4.11482 13.9566 2.85937 12.5335C2.88006 10.9781 4.15412 9.71431 5.71429 9.71431V9.71431Z"
                              fill="#495057"
                            />
                          </g>
                          <defs>
                            <clipPath id="4f25a6d6acd8dd3fbe3e">
                              <rect width={16} height={16} fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </div>
                    </span>
                  )}
                </button>
                <div className="dropdown d-none d-lg-flex">
                  <button className="navbar-dropdown-account-wrapper ms-2" id="accountNavbarDropdown" onClick={onOpenTogglerDesktop}>
                    {userData?.emailVerified ? (
                      <>
                        <Avatar
                          size={40}
                          initials={`${userData?.firstName?.charAt(0).toUpperCase() ?? ''} ${
                            userData?.lastName?.charAt(0).toUpperCase() ?? ''
                          }`}
                        />
                        <span className="avatar-name">{`${userData?.firstName ?? ''} ${userData?.lastName ?? ''}`}</span>
                      </>
                    ) : (
                      <span className="avatar flex-shrink-0 avatar-base avatar-circle">
                        <div className="avatar-initials bg-white">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={16}
                            height={16}
                            viewBox="0 0 16 16"
                            fill="none"
                            className="svg-icon rounded-0"
                          >
                            <g clipPath="url(#4f25a6d6acd8dd3fbe3e)">
                              <path
                                d="M8 0C3.58849 0 0 3.58849 0 8C0 12.4115 3.58849 16 8 16C12.4115 16 16 12.4115 16 8C16 3.58849 12.4115 0 8 0ZM8 2.85712C9.57119 2.85712 10.8571 4.14313 10.8571 5.71431C10.8571 7.2855 9.57119 8.57144 8 8.57144C6.42881 8.57144 5.14286 7.2855 5.14286 5.71431C5.14286 4.14313 6.42881 2.85712 8 2.85712V2.85712ZM5.71429 9.71431H10.2857C11.8459 9.71431 13.1199 10.9781 13.1406 12.5335C11.8852 13.9566 10.052 14.8571 8 14.8571C5.94803 14.8571 4.11482 13.9566 2.85937 12.5335C2.88006 10.9781 4.15412 9.71431 5.71429 9.71431V9.71431Z"
                                fill="#495057"
                              />
                            </g>
                            <defs>
                              <clipPath id="4f25a6d6acd8dd3fbe3e">
                                <rect width={16} height={16} fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                        </div>
                      </span>
                    )}
                  </button>
                  {userData ? (
                    <div
                      className="dropdown-menu dropdown-menu-end navbar-dropdown-menu navbar-dropdown-menu-borderless navbar-dropdown-account"
                      aria-labelledby="accountNavbarDropdown"
                      ref={dropdownDesktopRef}
                    >
                      <a className="dropdown-item fw-semi-bold" href="/signin">
                        <SignOutContainer t={t} />
                      </a>
                      <div className="dropdown-divider order-5" />
                      <div className="dropdown-item fw-semi-bold">{t('header.nav.supportTitle')}</div>
                      <div className="text-indent-2 order-5" onClick={hideDropDown}>
                        <a className="dropdown-item" onClick={showSupport} style={{ cursor: 'pointer' }}>
                          <CustomerServiceOutlined />
                          <span style={{ marginLeft: '5px' }}>{t('header.nav.supportContact')}</span>
                        </a>
                      </div>
                    </div>
                  ) : (
                    <div
                      className="dropdown-menu dropdown-menu-end navbar-dropdown-menu navbar-dropdown-menu-borderless navbar-dropdown-account"
                      aria-labelledby="accountNavbarDropdown"
                      ref={dropdownDesktopRef}
                    >
                      <Link className="dropdown-item fw-semi-bold" to="/signin">
                        <LoginOutlined />
                        <span style={{ marginLeft: '5px' }}>{t('header.nav.signIn')}</span>
                      </Link>
                      <div className="dropdown-divider order-5" />
                      <a className="dropdown-item fw-semi-bold" href="/signup">
                        <LoginOutlined />
                        <span style={{ marginLeft: '5px' }}>{t('header.nav.signUp')}</span>
                      </a>
                      <div className="dropdown-divider order-5" />
                      <div className="dropdown-item fw-semi-bold">{t('header.nav.supportTitle')}</div>
                      <div className="text-indent-2 order-5" onClick={hideDropDown}>
                        <a className="dropdown-item" onClick={showSupport} style={{ cursor: 'pointer' }}>
                          <CustomerServiceOutlined />
                          <span style={{ marginLeft: '5px' }}>{t('header.nav.supportContact')}</span>
                        </a>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div> */}
          </div>
        </nav>
      </header>
    </>
  );
};

export default AdminHeader;
