import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n/index';
import { Link } from 'react-router-dom';
import { Row, Col } from 'antd';
import FAQ from '../FAQ';
import BeforeAfter from '../BeforeAfter';
import { Card, CardHeader, Avatar, IconButton, CardMedia, CardContent, Typography, CardActions } from '@mui/material';

import { red } from '@mui/material/colors';

const upgradeResolution = {
  before: 'https://cdn.discordapp.com/attachments/1105800665105711166/1138121247968997566/before.webp',
  after: 'https://cdn.discordapp.com/attachments/1105800665105711166/1138119558364921887/after.webp',
};

const LandingPage = () => {

  const { t } = useTranslation();
  const changeLanguage = lng => {
    i18n.changeLanguage(lng);
  };

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const refParam = urlSearchParams.get('ref');

    if (refParam) {
      localStorage.setItem('refCode', refParam);
      // You can perform further actions with the referral code here
    } else {
      localStorage.setItem('refCode', "");
    }

    const redirect = urlSearchParams.get('r');

    if (redirect) {
      localStorage.setItem('redirect', redirect);
      // You can perform further actions with the referral code here
    } else {
      localStorage.setItem('redirect', "");
    }
  }, []);

  return (
    <>
      <main style={{ background: '#000' }}>
        <img src="/images/night-background.webp" className="absolute inset-0 object-cover object-bottom w-full h-full" />
        <div className="landing-cover-header" data-controller="gradient-animation">
          <Row gutter={12}>
            <Col md={12} xs={24} className="landing-order-second">
              <div className="banner-block-copy-0-2-82 banner-block-copy-d123-0-2-395">
                <div className="title-landing-wrapper">
                  <h1 className="text-root-0-2-141 text-root-d10-0-2-401 banner-block-copyTitle-0-2-83 banner-block-copyTitle-d124-0-2-396">
                    <span>
                      <h1 className="display-title-landing fw-bold text-white">{t('landing.title')}</h1>
                    </span>
                  </h1>
                  <div className="description-root-0-2-148 description-root-d27-0-2-402">
                    <p style={{ color: '#adb5bd' }}>
                      Sử dụng công nghệ AI thiết kế nội thất, ngoại thất, không gian nhà bạn với mọi phong cách mà bạn muốn. Tiết kiệm x10
                      thời gian và tài chính của bạn.
                    </p>
                  </div>
                  <div className="relative mx-auto mt-6 max-w-lg space-y-4">
                    <div className="flex items-center gap-3">
                      <div className="flex -space-x-3 overflow-hidden">
                        <img
                          src="/images/customer-1.webp"
                          alt=""
                          className="inline-block h-10 w-10 rounded-full border-[3px] border-white"
                        />{' '}
                        <img
                          style={{ marginLeft: '-12px' }}
                          src="/images/customer-2.webp"
                          alt=""
                          className="inline-block h-10 w-10 rounded-full border-[3px] border-white"
                        />{' '}
                        <img
                          style={{ marginLeft: '-12px' }}
                          src="/images/customer-3.webp"
                          alt=""
                          className="inline-block h-10 w-10 rounded-full border-[3px] border-white"
                        />{' '}
                        <img
                          style={{ marginLeft: '-12px' }}
                          src="/images/customer-4.webp"
                          alt=""
                          className="inline-block h-10 w-10 rounded-full border-[3px] border-white"
                        />{' '}
                        <img
                          style={{ marginLeft: '-12px' }}
                          src="/images/customer-5.webp"
                          alt=""
                          className="inline-block h-10 w-10 rounded-full border-[3px] border-white"
                        />
                      </div>{' '}
                      <div className="flex" style={{ color: '#facc15' }}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="h-5 w-5">
                          <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                        </svg>{' '}
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="h-5 w-5">
                          <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                        </svg>{' '}
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="h-5 w-5">
                          <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                        </svg>{' '}
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="h-5 w-5">
                          <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                        </svg>{' '}
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="h-5 w-5">
                          <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                        </svg>
                      </div>
                    </div>{' '}
                    <p className="text-sm text-gray-500">Được yêu thích bởi 7479 khách hàng.</p>
                  </div>
                  <div className="banner-block-ctaHolder-0-2-86 banner-block-ctaHolder-d126-0-2-398">
                    <div className="upgrade-btn-wrapper">
                      <a href="https://decorify.pro/vi/?ref=decorify" className="upgrade-button mt-4">
                        {t('button.try')}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col md={12} xs={24} className="landing-order-first">
              <div className="banner-block-mediaWrapper">
                <div className="banner-block-copy-mobile media-root-0-2-161 media-root-d6-0-2-411 banner-block-media-0-2-88 banner-block-media-d128-0-2-400">
                  <div data-testid="image-root" className="base-image-root-0-2-361 base-image-root-d2-0-2-412">
                    <div className="before-after-landing-wrapper">
                      <div className="video-slider">
                        {/* <video
                          className="banner-video"
                          autoPlay
                          loop
                          style={{
                            backgroundImage:
                              'url("image/")',
                          }}
                          muted
                          playsInline
                          data-wf-ignore="true"
                          dataobjectfit="cover"
                        >
                          <source
                            src="https://cdn.discordapp.com/attachments/1105800665105711166/1128614735609344080/intro.mp4"
                            data-wf-ignore="true"
                          />
                        </video> */}
                        <img src="images/updating.webp"className="banner-video"/>
                      </div>
                      <p className="mt-4 text-sm text-gray-500 text-align-center">Chúng tôi đã cập nhật phiên bản mới tại: <a href="https://decorify.pro/vi/?ref=decorify">decorify.pro</a></p>
                      <p className="text-sm text-gray-500 text-align-center">Mọi dữ liệu cũ đã được chúng tôi di chuyển tới <a href="https://decorify.pro/vi/?ref=decorify">decorify.pro</a></p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <div className="flex items-center justify-center text-center relative">
            <span className="text-gray-500">as seen on</span>
            <img className="tiktok-seen-on" loading="lazy" src="images/tiktok.webp" />
            <img className="instagram-seen-on" loading="lazy" src="images/instagram.webp" />
          </div>
          {/* <canvas id="canvas-gradient" data-transition-in /> */}
          {/* <div id="canvas-overlay" /> */}
        </div>
      </main>
      {/* <main>
        <section
          id="5section2"
          className="relative overflow-hidden bg-white section-top-90 section-bottom-180"
          data-layouts="text_card,featured_cards"
        >
          <div className=" container space-y-8 md:space-y-[85px]">
            <div className="w-full">
              <h2 className="mx-auto relative z-[10]  t-50 text-neutral-black mb-5 md:mb-6 font-bold text-center mx-auto md:text-center">
                Khám phá các tính năng
              </h2>
              <div
                className="mx-auto font-body relative z-10  t-20-l text-neutral-dark-grey/80  text-center mx-auto md:text-center"
                style={{ maxWidth: '722px' }}
              >
                <p>
                  Với mục tiêu giúp bạn Tiết kiệm Thời gian và Tài chính. Biến mọi không gian mơ ước của bạn thành hiện thực bởi trí tuệ tạo. Bạn có thể đưa ý tưởng của mình, AI sẽ hiểu và giúp bạn thay đổi thiết kế theo
                  ý muốn.
                </p>
              </div>
            </div>
            <div className="grid gap-10 sm:grid-cols-2 sm:gap-4 lg:gap-10">
              <div className="group flex flex-col justify-between relative lg:aspect-[580/740] rounded-lg overflow-hidden gap-2 md:gap-0 bg-light-blue">
                <div className="px-8 pt-8 lg:px-12 lg:pt-12">
                  <h3 className="t-32 font-bold text-neutral-black">Chỉ định thiết kế</h3>
                  <div className="t-18-l font-inter mt-6 text-neutral-dark-grey">
                    <p>
                      Tính năng giúp AI xác định nơi bạn cần thay đổi, cho phép bạn tự sáng tạo và thay đổi với mọi phong cách trên thế
                      giới.
                    </p>
                  </div>
                </div>
                <div className=" relative flex aspect-[580/580] flex-1 pb-[50px] md:max-h-[580px] md:max-w-[580px] md:pb-0">
                  <div className="banner-block-mediaWrapper">
                    <div className="banner-block-copy-mobile media-root-0-2-161 media-root-d6-0-2-411 banner-block-media-0-2-88 video-wrapper">
                      <div data-testid="image-root" className="base-image-root-0-2-361 base-image-root-d2-0-2-412">
                        <div className="before-after-landing-wrapper">
                          <div className="video-slider">
                            <video
                              className="banner-video "
                              autoPlay
                              loop
                              style={{
                                backgroundImage:
                                  'url("https://cdn.discordapp.com/attachments/1105800665105711166/1135477041077178368/virtual-staging.webp")',
                              }}
                              muted
                              playsInline
                              data-wf-ignore="true"
                              dataobjectfit="cover"
                            >
                              <source
                                src="https://cdn.discordapp.com/attachments/1105800665105711166/1128633851531509881/virtual-staging.mp4"
                                data-wf-ignore="true"
                              />
                            </video>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="absolute bottom-8 left-6 self-start lg:bottom-12 lg:left-6">
                  <div className="overflow-hidden rounded-lg">
                    <a
                      href="/signup"
                      className="inline-flex shrink-0 items-center justify-center text-center relative focus:outline-none font-medium transition-all group transition-all transition-all overflow-hidden bg-neutral-black text-white hover:bg-dark-blue t-17 tracking-[0.01em] px-5 py-[15px]"
                    >
                      <div className="relative z-30">Bắt đầu ngay</div>
                    </a>
                  </div>
                </div>
              </div>
              <div className="group flex flex-col justify-between relative lg:aspect-[580/740] rounded-lg overflow-hidden gap-2 md:gap-0 bg-light-purple">
                <div className="px-8 pt-8 lg:px-12 lg:pt-12">
                  <h3 className="t-32 font-bold text-neutral-black">Thay đổi toàn bộ thiết kế</h3>
                  <div className="t-18-l font-inter mt-6 text-neutral-dark-grey">
                    <p>
                      AI tự động thay đổi không gian của bạn với phong cách được chọn, phù hợp với phòng trống hoặc bạn cần thêm ý tưởng để
                      thiết kế.
                    </p>
                  </div>
                </div>
                <div className=" relative flex aspect-[580/580] flex-1 pb-[50px] md:max-h-[580px] md:max-w-[580px] md:pb-0">
                  <div className="banner-block-mediaWrapper">
                    <div className="banner-block-copy-mobile media-root-0-2-161 media-root-d6-0-2-411 banner-block-media-0-2-88 video-wrapper">
                      <div data-testid="image-root" className="base-image-root-0-2-361 base-image-root-d2-0-2-412">
                        <div className="before-after-landing-wrapper">
                          <div className="video-slider">
                            <video
                              className="banner-video "
                              autoPlay
                              loop
                              style={{
                                backgroundImage:
                                  'url("https://cdn.discordapp.com/attachments/1105800665105711166/1135477593970327562/Beachcottage-hotelroom.webp")',
                              }}
                              muted
                              playsInline
                              data-wf-ignore="true"
                              dataobjectfit="cover"
                            >
                              <source
                                src="https://cdn.discordapp.com/attachments/1105800665105711166/1128920036074070097/Beachcottage-hotelroom.mp4"
                                data-wf-ignore="true"
                              />
                            </video>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="absolute bottom-8 left-6 self-start lg:bottom-12 lg:left-6">
                  <div className="overflow-hidden rounded-lg">
                    <a
                      href="/signup"
                      className=" inline-flex shrink-0 items-center justify-center text-center relative focus:outline-none font-medium transition-all group transition-all transition-all overflow-hidden bg-neutral-black text-white hover:bg-dark-purple t-17 tracking-[0.01em] px-5 py-[15px]"
                    >
                      <div className="relative z-30">Bắt đầu ngay</div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          id="5section2"
          className="relative overflow-hidden bg-black section-top-90 section-bottom-90"
          data-layouts="text_card,featured_cards"
        >
          <div className="container space-y-8">
            <div className="w-full">
              <h2
                className="mx-auto relative z-[10] text-white t-50 text-neutral-black mb-5 md:mb-6 font-bold text-center mx-auto md:text-center"
                style={{ maxWidth: '722px' }}
              >
                Làm nét ảnh, thiết kế
              </h2>
              <div
                className="mx-auto font-body text-neutral-light-grey relative z-10 t-20-l text-center mx-auto md:text-center"
                style={{ maxWidth: '722px' }}
              >
                <p>
                  Tính năng giúp xoá mờ, cải thiện màu sắc và chất lượng của thiết kế hoặc bất động sản trước khi đăng bài trên các nền tảng mạng xã hội,
                  bất động sản.
                </p>
              </div>
            </div>
            <div className="flex flex-col justify-between">
              <BeforeAfter images={upgradeResolution} />
            </div>
            <div className="flex flex-col align-items-center">
              <div className="getstarted-btn-wrapper">
                <Link to="/signup" className="upgrade-button">
                  Sử dụng tính năng
                </Link>
              </div>
            </div>
          </div>
        </section>
        <section
          id="5section1"
          className="relative overflow-hidden bg-white section-top-none section-top-90 section-bottom-90"
          data-layouts="simple_cards"
        >
          <div className="container space-y-8 md:space-y-[85px]">
            <div className="w-full">
              <h2 className="mx-auto relative z-[10]  t-50 text-neutral-black mb-5 md:mb-6 font-bold text-center mx-auto md:text-center">
                Thiết kế nổi bật từ khách hàng
              </h2>
              <div
                className="mx-auto font-body relative z-10  t-20-l text-neutral-dark-grey/80  text-center mx-auto md:text-center"
                style={{ maxWidth: '722px' }}
              >
                <p>Nội thất AI đã xin phép khách hàng đăng những thiết kế nổi bật của họ giúp mọi người cùng tham khảo.</p>
              </div>
            </div>
            <div className="rnftg-h-full rnftg-w-full">
              <div className="rnftg-h-full dark:rnftg-bg-gray-900">
                <div className="rnftg-flex rnftg-flex-col pd-2">
                  <div className="rnftg-grid rnftg-gap-6 rnftg-grid-flow-row rnftg-grid-cols-1 md:rnftg-grid-cols-2 lg:rnftg-grid-cols-3 xl:rnftg-grid-cols-3">
                    <article className="rnftg-item rnftg-rounded-2xl dark:rnftg-bg-gray-800 rnftg-shadow-lg hover:rnftg-shadow-xl rnftg-transition rnftg-duration-300">
                      <div className="rnftg-item__img-wrapper">
                        <div className="result">
                          <video
                            className="banner-video"
                            autoPlay
                            loop
                            style={{
                              backgroundImage:
                                'url("https://cdn.discordapp.com/attachments/1105800665105711166/1135478927020793856/Contemporary-neoclassic.webp")',
                            }}
                            muted
                            playsInline
                            data-wf-ignore="true"
                            dataobjectfit="cover"
                          >
                            <source
                              src="https://cdn.discordapp.com/attachments/1105800665105711166/1128908395513139302/Contemporary-neoclassic.mp4"
                              data-wf-ignore="true"
                            />
                          </video>
                          <div className="prompt-label">Thiết Kế Nội Thất Phòng Khách Tân Cổ Điển Đương Đại</div>
                        </div>
                      </div>
                    </article>
                    <article className="rnftg-item rnftg-rounded-2xl dark:rnftg-bg-gray-800 rnftg-shadow-lg hover:rnftg-shadow-xl rnftg-transition rnftg-duration-300">
                      <div className="rnftg-item__img-wrapper">
                        <div className="result">
                          <video
                            className="banner-video "
                            autoPlay
                            loop
                            style={{
                              backgroundImage:
                                'url("https://cdn.discordapp.com/attachments/1105800665105711166/1135479511857758248/Colorful-modern-bedroom.webp")',
                            }}
                            muted
                            playsInline
                            data-wf-ignore="true"
                            dataobjectfit="cover"
                          >
                            <source
                              src="https://cdn.discordapp.com/attachments/1105800665105711166/1128908281797156864/Colorful-modern-bedroom.mp4"
                              data-wf-ignore="true"
                            />
                          </video>
                          <div className="prompt-label">Thiết kế nội thất phòng ngủ đầy màu sắc</div>
                        </div>
                      </div>
                    </article>
                    <article className="rnftg-item rnftg-rounded-2xl dark:rnftg-bg-gray-800 rnftg-shadow-lg hover:rnftg-shadow-xl rnftg-transition rnftg-duration-300">
                      <div className="rnftg-item__img-wrapper">
                        <div className="result">
                          <video
                            className="banner-video "
                            autoPlay
                            loop
                            style={{
                              backgroundImage:
                                'url("https://cdn.discordapp.com/attachments/1105800665105711166/1135480095608414238/European-dinning.webp")',
                            }}
                            muted
                            playsInline
                            data-wf-ignore="true"
                            dataobjectfit="cover"
                          >
                            <source
                              src="https://cdn.discordapp.com/attachments/1105800665105711166/1128896433756577802/European-dinning.mp4"
                              data-wf-ignore="true"
                            />
                          </video>
                          <div className="prompt-label">Thiết Kế Nội Thất Phòng Ăn Châu Âu</div>
                        </div>
                      </div>
                    </article>
                    <article className="rnftg-item rnftg-rounded-2xl dark:rnftg-bg-gray-800 rnftg-shadow-lg hover:rnftg-shadow-xl rnftg-transition rnftg-duration-300">
                      <div className="rnftg-item__img-wrapper">
                        <div className="result">
                          <video
                            className="banner-video "
                            autoPlay
                            loop
                            style={{
                              backgroundImage:
                                'url("https://cdn.discordapp.com/attachments/1105800665105711166/1135480745096401016/exterior-modern.webp")',
                            }}
                            muted
                            playsInline
                            data-wf-ignore="true"
                            dataobjectfit="cover"
                          >
                            <source
                              src="https://cdn.discordapp.com/attachments/1105800665105711166/1128907002186309743/exterior-modern.mp4"
                              data-wf-ignore="true"
                            />
                          </video>
                          <div className="prompt-label">Thiết Kế Ngoại Thất Phong Cách Hiện Đại</div>
                        </div>
                      </div>
                    </article>
                    <article className="rnftg-item rnftg-rounded-2xl dark:rnftg-bg-gray-800 rnftg-shadow-lg hover:rnftg-shadow-xl rnftg-transition rnftg-duration-300">
                      <div className="rnftg-item__img-wrapper">
                        <div className="result">
                          <video
                            className="banner-video "
                            autoPlay
                            loop
                            style={{
                              backgroundImage:
                                'url("https://cdn.discordapp.com/attachments/1105800665105711166/1135477593970327562/Beachcottage-hotelroom.webp")',
                            }}
                            muted
                            playsInline
                            data-wf-ignore="true"
                            dataobjectfit="cover"
                          >
                            <source
                              src="https://cdn.discordapp.com/attachments/1105800665105711166/1128920036074070097/Beachcottage-hotelroom.mp4"
                              data-wf-ignore="true"
                            />
                          </video>
                          <div className="prompt-label">Thiết kế nội thất phòng khách sạn Beach Cottage</div>
                        </div>
                      </div>
                    </article>
                    <article className="rnftg-item rnftg-rounded-2xl dark:rnftg-bg-gray-800 rnftg-shadow-lg hover:rnftg-shadow-xl rnftg-transition rnftg-duration-300">
                      <div className="rnftg-item__img-wrapper">
                        <div className="result">
                          <video
                            className="banner-video "
                            autoPlay
                            loop
                            style={{
                              backgroundImage:
                                'url("https://cdn.discordapp.com/attachments/1105800665105711166/1135481452021174322/wedding.webp")',
                            }}
                            muted
                            playsInline
                            data-wf-ignore="true"
                            dataobjectfit="cover"
                          >
                            <source
                              src="https://cdn.discordapp.com/attachments/1105800665105711166/1128926758448660480/wedding.mp4"
                              data-wf-ignore="true"
                            />
                          </video>
                          <div className="prompt-label">Thiết Kế Phòng Cưới</div>
                        </div>
                      </div>
                    </article>
                    <article className="rnftg-item rnftg-rounded-2xl dark:rnftg-bg-gray-800 rnftg-shadow-lg hover:rnftg-shadow-xl rnftg-transition rnftg-duration-300">
                      <div className="rnftg-item__img-wrapper">
                        <div className="result">
                          <img
                            className="banner-video "
                            src='https://cdn.discordapp.com/attachments/1105800665105711166/1146666925884178432/noithatai-249874451.webp'
                          />
                          <div className="prompt-label">Thiết Kế Tiệm Nail Hồng Nóng Bỏng</div>
                        </div>
                      </div>
                    </article>
                    <article className="rnftg-item rnftg-rounded-2xl dark:rnftg-bg-gray-800 rnftg-shadow-lg hover:rnftg-shadow-xl rnftg-transition rnftg-duration-300">
                      <div className="rnftg-item__img-wrapper">
                        <div className="result">
                          <img
                            className="banner-video "
                            src='https://cdn.discordapp.com/attachments/1105800665105711166/1146666925523488838/noithatai-151137565.webp'
                          />
                          <div className="prompt-label">Thiết Kế Tiệm Nail Hồng Nhẹ</div>
                        </div>
                      </div>
                    </article>
                    <article className="rnftg-item rnftg-rounded-2xl dark:rnftg-bg-gray-800 rnftg-shadow-lg hover:rnftg-shadow-xl rnftg-transition rnftg-duration-300">
                      <div className="rnftg-item__img-wrapper">
                        <div className="result">
                          <img
                            className="banner-video "
                            src='https://cdn.discordapp.com/attachments/1105800665105711166/1146666926232317962/noithatai-817557023.webp'
                          />
                          <div className="prompt-label">Thiết Kế Tiệm Nail Hiện Đại</div>
                        </div>
                      </div>
                    </article>
                    <article className="rnftg-item rnftg-rounded-2xl dark:rnftg-bg-gray-800 rnftg-shadow-lg hover:rnftg-shadow-xl rnftg-transition rnftg-duration-300">
                      <div className="rnftg-item__img-wrapper">
                        <div className="result">
                          <img
                            className="banner-video "
                            src='https://cdn.discordapp.com/attachments/1105800665105711166/1146655374418661406/noithatai-386897638.webp'
                          />
                          <div className="prompt-label">Thiết Kế Sảnh Khách Sạn Phong Cách Sắc Màu</div>
                        </div>
                      </div>
                    </article>
                    <article className="rnftg-item rnftg-rounded-2xl dark:rnftg-bg-gray-800 rnftg-shadow-lg hover:rnftg-shadow-xl rnftg-transition rnftg-duration-300">
                      <div className="rnftg-item__img-wrapper">
                        <div className="result">
                          <img
                            className="banner-video "
                            src='https://cdn.discordapp.com/attachments/1105800665105711166/1146656850843017276/noithatai-523490229.webp'
                          />
                          <div className="prompt-label">Thiết Kế Sảnh Khách Sạn Phong Cách Tân Cổ Điển</div>
                        </div>
                      </div>
                    </article>
                    <article className="rnftg-item rnftg-rounded-2xl dark:rnftg-bg-gray-800 rnftg-shadow-lg hover:rnftg-shadow-xl rnftg-transition rnftg-duration-300">
                      <div className="rnftg-item__img-wrapper">
                        <div className="result">
                          <img
                            className="banner-video "
                            src='https://cdn.discordapp.com/attachments/1105800665105711166/1146662090472431717/noithatai-734033259.webp'
                          />
                          <div className="prompt-label">Thiết Kế Phòng Ăn Hiện Đại</div>
                        </div>
                      </div>
                    </article>
                    <article className="rnftg-item rnftg-rounded-2xl dark:rnftg-bg-gray-800 rnftg-shadow-lg hover:rnftg-shadow-xl rnftg-transition rnftg-duration-300">
                      <div className="rnftg-item__img-wrapper">
                        <div className="result">
                          <img
                            className="banner-video "
                            src='https://cdn.discordapp.com/attachments/1105800665105711166/1146662088568213535/noithatai-392451777.webp'
                          />
                          <div className="prompt-label">Thiết Kế Phòng Làm Việc Chuyên Nghiệp</div>
                        </div>
                      </div>
                    </article>
                    <article className="rnftg-item rnftg-rounded-2xl dark:rnftg-bg-gray-800 rnftg-shadow-lg hover:rnftg-shadow-xl rnftg-transition rnftg-duration-300">
                      <div className="rnftg-item__img-wrapper">
                        <div className="result">
                          <img
                            className="banner-video "
                            src='https://cdn.discordapp.com/attachments/1105800665105711166/1146662089021202462/noithatai-459740649.webp'
                          />
                          <div className="prompt-label">Thiết Kế Phòng Ngủ Trang Trí Trung Thu</div>
                        </div>
                      </div>
                    </article>
                    <article className="rnftg-item rnftg-rounded-2xl dark:rnftg-bg-gray-800 rnftg-shadow-lg hover:rnftg-shadow-xl rnftg-transition rnftg-duration-300">
                      <div className="rnftg-item__img-wrapper">
                        <div className="result">
                          <img
                            className="banner-video "
                            src='https://cdn.discordapp.com/attachments/1105800665105711166/1146662088161382520/noithatai-345823113.webp'
                          />
                          <div className="prompt-label">Thiết Kế Phòng Ngủ Tân Nghệ Thuật</div>
                        </div>
                      </div>
                    </article>
                    <article className="rnftg-item rnftg-rounded-2xl dark:rnftg-bg-gray-800 rnftg-shadow-lg hover:rnftg-shadow-xl rnftg-transition rnftg-duration-300">
                      <div className="rnftg-item__img-wrapper">
                        <div className="result">
                          <img
                            className="banner-video "
                            src='https://cdn.discordapp.com/attachments/1105800665105711166/1146676711258341446/out-2.webp'
                          />
                          <div className="prompt-label">Thiết Kế Cửa Hàng Thời Trang Chuyên Nghiệp</div>
                        </div>
                      </div>
                    </article>
                    <article className="rnftg-item rnftg-rounded-2xl dark:rnftg-bg-gray-800 rnftg-shadow-lg hover:rnftg-shadow-xl rnftg-transition rnftg-duration-300">
                      <div className="rnftg-item__img-wrapper">
                        <div className="result">
                          <img
                            className="banner-video "
                            src='https://cdn.discordapp.com/attachments/1105800665105711166/1146676710675337236/noithatai-248816652.webp'
                          />
                          <div className="prompt-label">Thiết Kế Cửa Hàng Thời Trang Tối Giản</div>
                        </div>
                      </div>
                    </article>
                    <article className="rnftg-item rnftg-rounded-2xl dark:rnftg-bg-gray-800 rnftg-shadow-lg hover:rnftg-shadow-xl rnftg-transition rnftg-duration-300">
                      <div className="rnftg-item__img-wrapper">
                        <div className="result">
                          <img
                            className="banner-video "
                            src='https://cdn.discordapp.com/attachments/1105800665105711166/1146676710293647390/noithatai-162565191.webp'
                          />
                          <div className="prompt-label">Thiết Kế Cửa Hàng Đồ Bảo Hộ</div>
                        </div>
                      </div>
                    </article>
                    <article className="rnftg-item rnftg-rounded-2xl dark:rnftg-bg-gray-800 rnftg-shadow-lg hover:rnftg-shadow-xl rnftg-transition rnftg-duration-300">
                      <div className="rnftg-item__img-wrapper">
                        <div className="result">
                          <img
                            className="banner-video "
                            src='https://cdn.discordapp.com/attachments/1105800665105711166/1146674901596508200/noithatai-973856607.webp'
                          />
                          <div className="prompt-label">Thiết Kế Nhà Việt Xưa từ thực tế</div>
                        </div>
                      </div>
                    </article>
                    <article className="rnftg-item rnftg-rounded-2xl dark:rnftg-bg-gray-800 rnftg-shadow-lg hover:rnftg-shadow-xl rnftg-transition rnftg-duration-300">
                      <div className="rnftg-item__img-wrapper">
                        <div className="result">
                          <img
                            className="banner-video "
                            src='https://cdn.discordapp.com/attachments/1105800665105711166/1146674900728299550/noithatai-179496130.webp'
                          />
                          <div className="prompt-label">Thiết Kế Nhà Phong Cách Nhật Bản từ thực tế</div>
                        </div>
                      </div>
                    </article>
                    <article className="rnftg-item rnftg-rounded-2xl dark:rnftg-bg-gray-800 rnftg-shadow-lg hover:rnftg-shadow-xl rnftg-transition rnftg-duration-300">
                      <div className="rnftg-item__img-wrapper">
                        <div className="result">
                          <img
                            className="banner-video "
                            src='https://cdn.discordapp.com/attachments/1105800665105711166/1146674901135130684/noithatai-529271032.webp'
                          />
                          <div className="prompt-label">Thiết Kế Nhà Phong Cách Trung Hoa từ thực tế</div>
                        </div>
                      </div>
                    </article>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="relative bg-neutral-black section-top-180 section-bottom-90">
            <div className="container">
              <FAQ />
            </div>
          </div>
        </section>
      </main> */}
    </>
  );
};

export default LandingPage;
