import React from 'react';
import PropTypes from 'prop-types';
import {
  LogoutOutlined,
} from '@ant-design/icons'

const SignOut = ({ handleSignOut, t }) => {

  return (
    <div onClick={handleSignOut}>
      <LogoutOutlined style={{ display: "inline-grid" }} /><span style={{ marginLeft: "5px" }}>{t('header.nav.signOut')}</span>
    </div>
  )
};

SignOut.propTypes = {
  handleSignOut: PropTypes.func.isRequired,
};

export default SignOut;
