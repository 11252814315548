import React from 'react';
import { Button } from 'antd';

const ImageResult = ({ job, onCompareImage, onDownloadImage, onFindAgency, onEditImage, isDownloading, onUpImageResolution }) => {

  return (
    <article className="rnftg-item rnftg-rounded-2xl dark:rnftg-bg-gray-800 rnftg-shadow-lg hover:rnftg-shadow-xl rnftg-transition rnftg-duration-300">
      <div className="rnftg-item__img-wrapper">
        <div className="result">
          <img
            className="rnftg-w-full rnftg-h-full rnftg-object-cover rnftg-cursor-pointer rnftg-rounded-t-2xl result-wrapper"
            loading="lazy"
            src={job.upscaleUrl}
          />
          <div className="prompt-label">{job.prompt}</div>
          <div className="action-1">
            <Button
              className="image_action download_image"
              onClick={() => onCompareImage({ before: job.originalUrl, after: job.upscaleUrl })}
              type="submit"
            >
              <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 240 240" fillRule="nonzero">
                <g fill="#ffffff" fillRule="nonzero" stroke="none" strokeWidth={1} strokeLinecap="butt" strokeLinejoin="miter" strokeMiterlimit={10} strokeDashoffset={0} fontFamily="none" fontWeight="none" fontSize="none" textAnchor="none" style={{ mixBlendMode: 'normal' }}>
                  <g transform="scale(5.12,5.12)">
                    <path d="M21,3c-9.39844,0 -17,7.60156 -17,17c0,9.39844 7.60156,17 17,17c3.35547,0 6.46094,-0.98437 9.09375,-2.65625l12.28125,12.28125l4.25,-4.25l-12.125,-12.09375c2.17969,-2.85937 3.5,-6.40234 3.5,-10.28125c0,-9.39844 -7.60156,-17 -17,-17zM21,7c7.19922,0 13,5.80078 13,13c0,7.19922 -5.80078,13 -13,13c-7.19922,0 -13,-5.80078 -13,-13c0,-7.19922 5.80078,-13 13,-13z" />
                  </g>
                </g>
              </svg>
            </Button>
            <span>So sánh hình gốc</span>
          </div>
          <div className="action-2">
            <Button
              className="image_action download_image"
              onClick={() => onDownloadImage(job.upscaleUrl)}
              type="submit"
              loading={isDownloading}
            >
              <svg fill="#000000" viewBox="-5 -5 20 20" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMinYMin">
                <path d="M8 6.641l1.121-1.12a1 1 0 0 1 1.415 1.413L7.707 9.763a.997.997 0 0 1-1.414 0L3.464 6.934A1 1 0 1 1 4.88 5.52L6 6.641V1a1 1 0 1 1 2 0v5.641zM1 12h12a1 1 0 0 1 0 2H1a1 1 0 0 1 0-2z"/>
              </svg>
            </Button>
            <span>Tải xuống</span>
          </div>
          <div className="action-3">
            <Button
              onClick={() => onEditImage(job.upscaleUrl)}
              className="image_action open_shop"
              type="button"
            >
              <svg viewBox="0 0 20 20" fill="#fff" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.6 5.613C7.91 5.466 6.98 4.874 6.484 3.7c-.179-.423-.304-.917-.384-1.5 0-.1-.1-.2-.2-.2s-.2.1-.2.2c-.08.583-.205 1.077-.384 1.5C4.821 4.874 3.891 5.466 2.2 5.613c-.1 0-.2.1-.2.2s.1.2.2.2c2.1.4 3.2 1.187 3.5 3.387 0 .1.1.2.2.2s.2-.1.2-.2c.3-2.2 1.4-2.987 3.5-3.387.1 0 .2-.1.2-.2s-.1-.2-.2-.2ZM19.469 11.865c-4-.8-5.726-2.73-6.526-6.629a.493.493 0 0 0-.474-.371.493.493 0 0 0-.475.376c-.009.006.007-.015 0 0-.8 4-2.625 5.824-6.525 6.624a.5.5 0 0 0 0 1c4 .8 5.717 2.687 6.517 6.587a.493.493 0 0 0 .483.413.493.493 0 0 0 .477-.387c-.005.01.006-.008 0 0 .8-4 2.623-5.813 6.523-6.613a.5.5 0 0 0 0-1ZM21.465 5.8c0-.084-.061-.14-.144-.156l-.056-.013c-1.168-.305-1.876-1.024-2.073-2.108a.153.153 0 0 0-.153-.153v.004c-.084 0-.14.062-.156.144l-.013.056c-.305 1.168-1.024 1.876-2.108 2.073a.153.153 0 0 0-.153.153h.004c0 .084.062.14.145.156l.055.013c1.168.305 1.876 1.024 2.073 2.108 0 .084.069.153.153.153v-.004c.084 0 .14-.062.156-.145l.014-.055c.304-1.168 1.023-1.876 2.107-2.073a.15.15 0 0 0 .15-.153ZM7.919 18.715c-1-.3-1.582-.782-1.782-1.782a.218.218 0 1 0-.436 0c-.3 1-.782 1.582-1.782 1.782a.218.218 0 0 0 0 .436c1 .3 1.582.782 1.782 1.782a.218.218 0 0 0 .436 0c.3-1 .782-1.582 1.782-1.782a.218.218 0 0 0 0-.436Z" fill="#fff"/>
              </svg>
            </Button>
            <span>{`Chỉ định thiết kế (1 lượt)`}</span>
          </div>
          <div className="action-4">
            <Button
              onClick={(e) => onUpImageResolution(e, job.upscaleUrl)}
              className="image_action open_shop"
              type="button"
            >
              <svg width="16px" height="16px" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="bi bi-badge-4k">
                <path d="M4.807 5.001C4.021 6.298 3.203 7.6 2.5 8.917v.971h2.905V11h1.112V9.888h.733V8.93h-.733V5.001h-1.71zm-1.23 3.93v-.032a46.781 46.781 0 0 1 1.766-3.001h.062V8.93H3.577zm9.831-3.93h-1.306L9.835 7.687h-.057V5H8.59v6h1.187V9.075l.615-.699L12.072 11H13.5l-2.232-3.415 2.14-2.584z"/>
                <path d="M14 3a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h12zM2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H2z"/>
              </svg>
            </Button>
            <span>{`Làm nét thiết kế (1 lượt)`}</span>
          </div>
          <div className="action-5">
            <Button
              onClick={() => onFindAgency(job.upscaleUrl)}
              className="image_action open_shop"
              type="button"
            >
            <svg fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path d="M21,2H3A1,1,0,0,0,2,3V21a1,1,0,0,0,1,1h7a1,1,0,0,0,1-1V11H21a1,1,0,0,0,1-1V3A1,1,0,0,0,21,2ZM20,9H19V7a1,1,0,0,0-2,0V9H15V7a1,1,0,0,0-2,0V9H11V7A1,1,0,0,0,9,7V9H7a1,1,0,0,0,0,2H9v2H7a1,1,0,0,0,0,2H9v2H7a1,1,0,0,0,0,2H9v1H4V4H20Z"/>
            </svg>
            </Button>
            <span>{`Tư vấn và thi công`}</span>
          </div>
          <div className="blur-image" />
        </div>
      </div>
    </article>
  );
};

export default ImageResult;
