import React from 'react';
import { Routes, Route } from 'react-router-dom';
import * as routes from '../constants/routes';

import SignUpContainer from '../containers/SignUpContainer';
import SignInContainer from '../containers/SignInContainer';
import HomeContainer from '../containers/HomeContainer';
import NotFound from '../components/NotFound';
import ForgetPasswordContainer from '../containers/ForgetPasswordContainer';
// import AnyRoute from './AnyRoute';
import SignOutContainer from '../containers/SignOutContainer';
import AuthRoute from './AuthRoute';
import AnyRoute from './AnyRoute';
import PrivateRoute from './PrivateRoute'
import LandingPageContainer from '../containers/LandingPageContainer';
import TermsContainer from '../containers/TermsContainer';
import PrivacyContainer from '../containers/PrivacyContainer';
import AppContainer from '../containers/AppContainer';
// import AgencyContainer from '../containers/AgencyContainer';
// import AgencyRoute from './AgencyRoute';

const AppRoutes = () => {
  return (
      <Routes>
        <Route
          path="/"
          element={<AnyRoute/>}
        >
          <Route
            path={routes.LANDING}
            element={<LandingPageContainer/>}
          >
            <Route path="*" element={<NotFound />} />
          </Route>
          {/* <Route
            path={routes.TERMS_CONDITIONS}
            element={<TermsContainer/>}
          >
            <Route path="*" element={<NotFound />} />
          </Route>
          <Route
            path={routes.PRIVACY}
            element={<PrivacyContainer/>}
          >
            <Route path="*" element={<NotFound />} />
          </Route>
        </Route> */}
        {/* <Route
          path="/"
          element={<AgencyRoute/>}
        >
          <Route
            path={routes.AGENCIES}
            element={<AgencyContainer/>}
          >
            <Route path="*" element={<NotFound />} />
          </Route>
        </Route> */}
        {/* <Route
          path="/"
          element={<PrivateRoute/>}
        >
          <Route
            path={routes.HOME}
            element={<HomeContainer />}
          >
            <Route path="*" element={<NotFound />} />
          </Route>
          <Route
            path={routes.APP}
            element={<AppContainer />}
          >
            <Route path="*" element={<NotFound />} />
          </Route>
        </Route>
        <Route
          path="/"
          element={<AuthRoute/>}
        >
          <Route
            path={routes.SIGN_IN}
            element={<SignInContainer />}
          >
            <Route path="*" element={<NotFound />} />
          </Route>
          <Route
            path={routes.SIGN_UP}
            element={<SignUpContainer />}
          >
            <Route path="*" element={<NotFound />} />
          </Route>
          <Route
            path={routes.SIGN_OUT}
            element={<SignOutContainer />}
          >
            <Route path="*" element={<NotFound />} />
          </Route>
          <Route
            path={routes.FORGET_PASSWORD}
            element={<ForgetPasswordContainer />}
          >
            <Route path="*" element={<NotFound />} />
          </Route> */}
        </Route>
      </Routes>
  );
};

export default AppRoutes;
