import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { setUser } from '../../actions';

import SignIn from '../../components/SignIn';
import Spinner from '../../components/Spinner';
import withBackend from '../../backend/withBackend';
import { message } from 'antd';

const ERROR_CODE_ACCOUNT_EXISTS =
  'auth/account-exists-with-different-credential';

const ERROR_MSG_ACCOUNT_EXISTS = `
  An account with an E-Mail address to
  this social account already exists. Try to login from
  this account instead and associate your social accounts on
  your personal account page.
`;

const LOCATION = process.env.REACT_APP_LOCATION;

const RANDOM_CODE = Math.floor(Math.random() * 900000) + 100000;

let initialUserData = {
  uid: null,
  role:"USER",
  email:"",
  joinedDate: new Date().getTime(),
  lastLoginDate:null,
  firstName:"",
  lastName:"",
  phone: null,
  phoneCode:84,
  phoneShort:"VN",
  profilePicUrl:null,
  companyName: null,
  country:null,
  birthDate:null,
  gender:null,
  address:null,
  city:null,
  status:"incomplete",
  isActivated: true,
  hasSubscription:false,
  emailVerified: false,
  proTrial: process.env.REACT_APP_PRO_TRIAL,
  basicTrial: process.env.REACT_APP_BASIC_TRIAL,
  upscaleTrial: process.env.REACT_APP_UPSCALE_TRIAL,
  pro: false,
  basic: true,
  monthlyBasicCode: `MB ${RANDOM_CODE}`,
  yearlyBasicCode: `YB ${RANDOM_CODE}`,
  monthlyProCode: `MP ${RANDOM_CODE}`,
  yearlyProCode: `YP ${RANDOM_CODE}`,
  proCredits: 0,
  basicCredits: 0,
  refCode: ''
}

class SignInContainer extends Component {
  state = {
    loading: false,
    email: '',
    password: '',
    error: null,
    refCode: '',
    redirect: ''
  };

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = e => {
    e.preventDefault();

    const { engine, setUser } = this.props;
    const { email, password, redirect } = this.state;

    if (redirect === "tiktok") {
      alert("Vì lí do Bảo Mật Người Dùng của Google, bạn vui lòng sử dụng trình duyệt Chrome hoặc Safari để đăng ký và sử dụng. Truy cập noithatai.com hoặc www.noithatai.com");
      return;
    }

    this.setState({ loading: true });

    engine
      .signIn(email, password)
      .then(response => {
        const emailVerified = response.user.emailVerified;
        const uid = response.user.uid;
        if (!emailVerified) {
          engine.sendEmailVerificationLink();

          if ((LOCATION === "en")) {
            message.success("We just sent an email to activate your account. Please check it!");
          } else {
            message.success("Chúng tôi đã gửi 1 email để kích hoạt tài khoản của bạn, vui lòng kiểm tra email!");
          }
        } else {
          engine.updateProfile(uid, {emailVerified: true})
          .then((result) => {
          })
          .catch(error => console.log(error));

          engine.getUser(uid)
          .then(querySnapshot => setUser(querySnapshot.data()));
        }
      })
      .then(() => {this.setState({ loading: false })})
      .catch(error => {
        console.log(error.code);
        if (error.code === "auth/user-not-found") {
          error.message = "Tài khoản này không được tìm thấy. Vui lòng nhập chính xác tài khoản của bạn!"
        }

        if (error.code === "auth/wrong-password") {
          error.message = "Sai mật khẩu! Nếu bạn không nhớ mật khẩu, vui lòng sử dụng Quên mật khẩu."
          this.setState({ error })
        }
        this.setState({ error, loading: false })
      });
  };

  handleSubmitWithGoogle = e => {
    e.preventDefault();

    const { redirect } = this.state;

    if (redirect === "tiktok") {
      alert("Vì lí do Bảo Mật Người Dùng của Google, bạn vui lòng sử dụng trình duyệt Chrome hoặc Safari để đăng ký và sử dụng. Truy cập noithatai.com hoặc www.noithatai.com");
      return;
    }

    const { engine, setUser } = this.props;
    engine
      .signInWithGoogle()
      .then(response => {
        engine.getUser(response.user.uid)
        .then (querySnapshot => {
          // console.log(engine);
          if (querySnapshot.data()) {
            engine.getUser(response.user.uid)
            .then(querySnapshot => setUser(querySnapshot.data()))
            .catch(error => {
              if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
                error.message = ERROR_MSG_ACCOUNT_EXISTS;
              }
              this.setState({ error });
            });
          } else {
            const { refCode } = this.state;

            initialUserData.uid = response.user.uid;
            initialUserData.email = response.user.email;
            initialUserData.firstName = response.user.displayName;
            initialUserData.emailVerified = true;
            initialUserData.refCode = refCode;

            engine.addUser(response.user.uid, initialUserData)
            .then(() => engine.getUser(engine.auth.currentUser.uid))
            .then(querySnapshot => setUser(querySnapshot.data()))
            .catch(error => {
              if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
                error.message = ERROR_MSG_ACCOUNT_EXISTS;
              }
              this.setState({ error });
            });
          }
        })
      })
      .catch(error => {
        if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
          error.message = ERROR_MSG_ACCOUNT_EXISTS;
        }

        if (error.code === "auth/popup-closed-by-user") {
          error.message = "Popup closed";
        }

        if (error.code === "auth/cancelled-popup-request") {
          error.message = "Cancelled popup request";
        }
        
        this.setState({ error });
      });
  };

  componentDidMount() {
    // Retrieve the stored referralCode from local storage
    const storedReferralCode = localStorage.getItem('refCode');

    if (storedReferralCode) {
      this.setState({ refCode: storedReferralCode });
      // You can perform further actions with the referral code here
    }

    const redirect = localStorage.getItem('redirect');

    if (redirect) {
      this.setState({ redirect: redirect });
      // You can perform further actions with the referral code here
    }
  }

  render() {
    const { email, password, error, loading } = this.state;

    return (
      <>
        <SignIn
          email={email}
          password={password}
          handleChange={this.handleChange}
          handleSubmit={this.handleSubmit}
          handleSubmitWithGoogle= {this.handleSubmitWithGoogle}
          error={error}
        />
        {loading && <Spinner />}
      </>
    );
  }
}

export default compose(
  connect(null, { setUser }),
  withBackend
)(SignInContainer);
