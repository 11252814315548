import React, { useEffect, useState, useRef } from 'react';
import { Button, message } from 'antd';
import axios from 'axios';
import ComposeSection from '../ComposeSection';
import Pricing from '../Pricing';
import Spinner from '../Spinner';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n/index';
import AllShowCase from '../AllShowCase';
import Themes from '../Themes';
import confetti from 'canvas-confetti';
import { MenuItem, FormControl, Select } from '@mui/material';
import { Link } from 'react-router-dom';
import NewAvatarHeader from '../NewAvatarHeader';
import EditImage from '../EditImage';
import MyDesign from '../MyDesign';
import FindAgency from '../FindAgency\u001C';
import Referal from '../Referal';

const APP_URL = process.env.REACT_APP_APP_URL;
const APP_URL_V2 = process.env.REACT_APP_APP_URL_V2;
const modes = {
  pro: 'Pro',
  basic: 'Basic',
};
const LOCATION = process.env.REACT_APP_LOCATION;
const MAX_WIDTH = 768;

const NewHome = ({ firebase, setUser }) => {
  const [userData, setUserData] = useState({});
  const [themesList, setThemesList] = useState([]);
  const [roomsList, setRoomsList] = useState([]);
  const [jobsList, setJobsList] = useState([]);
  const [isCreating, setIsCreating] = useState(false);
  const [mode, setMode] = useState(modes.pro); //Set default is PRO
  const [isShowPricing, setIsShowPricing] = useState(false);
  const [isSpinning, setIsSpinning] = useState(false);

  const [lastJobCreatedAt, setLastJobCreatedAt] = useState('');
  const fileInputRef = useRef(null);
  const [imageSrc, setImageSrc] = useState('');
  const [file, setFile] = useState('');
  const [fileInfo, setFileInfo] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [currentTheme, setCurrentTheme] = useState(null);
  const [currentRoom, setCurrentRoom] = useState('');
  const [width, setWidthInfo] = useState(800);
  const [height, setHeightInfo] = useState(800);
  const [currentJob, setCurrentJob] = useState(null);
  const [showGenFill, setShowGenFill] = useState(false);
  const [showInterior, setShowInterior] = useState(true);
  const [showGenFillEditor, setShowGenFillEditor] = useState(false);
  const [showUpgradeResolution, setShowUpgradeResolution] = useState(false);
  const [showMyDesign, setShowMyDesign] = useState(false);
  const [showAgency, setShowFindAgency] = useState(false);
  const [imageUrlEditor, setImageUrlEditor] = useState('');
  const [isActiveMobileMenu, setIsActiveMobileMenu] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [showReferal, setShowReferal] = useState(false);

  const { t } = useTranslation();
  const changeLanguage = lng => {
    i18n.changeLanguage(lng);
  };

  useEffect(() => {
    setIsSpinning(true);
    const unsubscribe = firebase.auth.onAuthStateChanged(user => {
      if (user) {
        // User is authenticated, fetch the user data
        firebase
          .getUser(user.uid)
          .then(querySnapshot => {
            if (!querySnapshot.empty) {
              setUserData(querySnapshot.data());

              firebase
                .getThemes()
                .then(result => {
                  const themesArr = result.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data(),
                  }));

                  themesArr.sort((a, b) => a.priority - b.priority);

                  const convertedData = themesArr.map(item => ({
                    value: item.code,
                    label: LOCATION === 'en' ? item.englishName : item.vietnameseName,
                    url: item.url,
                    englishName: item.englishName,
                  }));

                  setThemesList(convertedData);
                })
                .catch(error => {
                  console.log(error);
                });

              firebase
                .getRooms()
                .then(result => {
                  const roomsArr = result.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data(),
                  }));

                  roomsArr.sort((a, b) => a.priority - b.priority);

                  const convertedData = roomsArr.map(item => ({
                    value: item.code,
                    label: LOCATION === 'en' ? item.englishName : item.vietnameseName,
                    englishName: item.englishName,
                  }));

                  setRoomsList(convertedData);
                })
                .catch(error => {
                  console.log(error);
                });

              setTimeout(() => {
                setIsSpinning(false);
              }, 1000);
            }
          })
          .catch(error => {
            console.log(error);
            setIsSpinning(false);
          });
      } else {
        // User is not authenticated, clear the user data
        setUserData(null);
        setUser(null);
        setIsSpinning(false);
      }
    });

    if (window.innerWidth <= 768) {
      setIsMobile(true);
    }

    // Clean up the subscription when the component unmounts
    return () => unsubscribe();
  }, []);

  function checkCredits() {
    if (mode === modes.pro && ((userData.pro && userData.proCredits < 1) || !userData.pro)) {
      message.warning(t('message.warning.runOutProCredits'));
      setTimeout(() => {
        setIsCreating(false);
        onShowPricing(true);
        setShowGenFillEditor(false);
      }, 1500);
      return true;
    }

    if (mode === modes.basic && ((userData.basic && userData.basicCredits < 1) || !userData.basic)) {
      message.warning(t('message.warning.runOutBasicCredits'));
      setTimeout(() => {
        setIsCreating(false);
        onShowPricing(true);
        setShowGenFillEditor(false);
      }, 1500);
      return true;
    }
    return false;
  }

  function onCreateImage() {
    if (checkCredits()) return;

    setIsCreating(true);

    if (!fileInfo) {
      message.error(t('message.error.uploadPhoto'));
      setIsCreating(false);
      return;
    }

    if (!currentRoom) {
      message.error(t('message.error.chooseRoom'));
      setIsCreating(false);
      return;
    }

    if (!currentTheme) {
      message.error(t('message.error.chooseTheme'));
      setIsCreating(false);
      return;
    }

    const fileSize = file.size;
    const maxSizeBasic = 10 * 1024 * 1024;
    const maxSizePro = 10 * 1024 * 1024;

    if (mode === modes.pro && fileSize > maxSizePro) {
      message.error(t('message.error.fileOverPro'));
      setIsCreating(false);
      return;
    }
    if (mode === modes.basic && fileSize > maxSizeBasic) {
      message.error(t('message.error.fileOverPro'));
      setIsCreating(false);
      return;
    }

    const job = {
      images: {
        before: file,
        after: file,
      },
    };

    setCurrentJob(job);

    message.success(t('message.success.redering'));

    firebase
      .uploadFile(file)
      .then(async downloadURL => {
        const imageUrl = downloadURL;
        // Perform any further actions with the downloadURL

        if (!imageUrl) return;
        const headers = {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${firebase.auth.currentUser.accessToken}`,
        };

        const room = roomsList.find(room => room.value === currentRoom);
        const roomEnglishName = room ? room.englishName : null;

        const requestDataEng = {
          imageUrl: imageUrl,
          theme: currentTheme.englishName,
          room: roomEnglishName,
          packageName: mode,
          width: width,
          height: height,
        };

        const result = await axios.post(`${APP_URL}/render`, requestDataEng, { headers });
        const jobId = result.data.id;

        while (true) {
          const result = await axios.get(`${APP_URL_V2}/job/${jobId}`, {}, { headers });

          if (result.data.status === 'finished') {
            // Result is finished, you can access it using result.data.output or perform further actions
            let finishedImages = result.data.images;
            finishedImages.forEach( (finishedImage, index) => {
              const prompt =
              LOCATION === 'en'
                ? `Interior Design of ${requestDataEng.theme} ${requestDataEng.room}`
                : `Thiết kế của ${room.label} ${currentTheme.label}`;

            const newImages = {
              before: imageUrl,
              after: finishedImage,
            };

            const newJob = {
              images: newImages,
              prompt: prompt,
            };
            setCurrentJob(newJob);

            const jobData = {
              status: 'finished',
              mode: mode,
              uid: firebase.auth.currentUser.uid,
              prompt: prompt,
              upscaleUrl: finishedImage,
              originalUrl: imageUrl,
              maskUrl: '',
              createdAt: new Date().toISOString(),
              jobId: jobId,
            };

            firebase
              .addJob(jobData)
              .then(result => {})
              .catch(err => {
                message.error(err.message);
              });

            firebase
              .getUser(firebase.auth.currentUser.uid)
              .then(querySnapshot => {
                setUser(querySnapshot.data());
                setUserData(querySnapshot.data());
              })
              .catch(err => {
                message.error(err.message);
              });
            });

            message.success(t('message.success.rederingDone'));
            setIsCreating(false);
            confetti({
              origin: { y: 0.5 },
              spread: 400,
              particleCount: 100,
              origin: { y: 0.4 },
            });
            
            return;
          } else if (result.data.status === 'failed') {
            // Job failed, handle the failure case
            message.error(t('message.error.busy'));
            setIsCreating(false);
            return;
          }
          // If the job is not yet finished, wait for some time before making the next request
          await new Promise(resolve => setTimeout(resolve, 2000));
        }
      })
      .catch(error => {
        message.error(t('message.error.wrongFile'));
        setIsCreating(false);
        // Handle the error
      });
  }

  function onShowGenFillImage() {
    if (checkCredits()) return;

    setIsUploading(true);

    if (!fileInfo) {
      message.error(t('message.error.uploadPhoto'));
      setIsUploading(false);
      return;
    }

    const fileSize = file.size;
    const maxSizeBasic = 10 * 1024 * 1024;
    const maxSizePro = 10 * 1024 * 1024;

    if (mode === modes.pro && fileSize > maxSizePro) {
      message.error(t('message.error.fileOverPro'));
      setIsUploading(false);
      return;
    }
    if (mode === modes.basic && fileSize > maxSizeBasic) {
      message.error(t('message.error.fileOverPro'));
      setIsUploading(false);
      return;
    }

    firebase
      .uploadFile(file)
      .then(async downloadURL => {
        const imageUrl = downloadURL;
        if (!imageUrl) return;

        setImageUrlEditor(imageUrl);
        setShowGenFillEditor(true);
        setIsUploading(false);
      })
      .catch(error => {
        message.error(t('message.error.wrongFile'));
        setShowGenFillEditor(false);
        setIsUploading(false);
        // Handle the error
      });
  }

  const onCloseImageEdit = () => {
    setShowGenFillEditor(false);
  };

  function onUpgradeResolution() {
    if (checkCredits()) return;

    setIsCreating(true);

    if (!fileInfo) {
      message.error(t('message.error.uploadPhoto'));
      setIsCreating(false);
      return;
    }

    const fileSize = file.size;
    const maxSizeBasic = 10 * 1024 * 1024;
    const maxSizePro = 10 * 1024 * 1024;

    if (mode === modes.pro && fileSize > maxSizePro) {
      message.error(t('message.error.fileOverPro'));
      setIsCreating(false);
      return;
    }
    if (mode === modes.basic && fileSize > maxSizeBasic) {
      message.error(t('message.error.fileOverPro'));
      setIsCreating(false);
      return;
    }

    const job = {
      images: {
        before: file,
        after: file,
      },
    };

    setCurrentJob(job);

    message.success("AI đang làm nét thiết kế");

    firebase
      .uploadFile(file)
      .then(async downloadURL => {
        const imageUrl = downloadURL;

        if (!imageUrl) return;
        const headers = {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${firebase.auth.currentUser.accessToken}`,
        };

        const requestDataEng = {
          imageUrl: imageUrl,
          packageName: mode,
        };

        const result = await axios.post(`${APP_URL}/up-resolution`, requestDataEng, { headers });
        const jobId = result.data.id;

        while (true) {
          const result = await axios.get(`${APP_URL}/job/${jobId}`, {}, { headers });

          if (result.data.status === 'finished') {
            // Result is finished, you can access it using result.data.output or perform further actions
            let finishedImage = result.data.image;
            const prompt =
              LOCATION === 'en'
                ? `Upgrade resolution`
                : `Làm nét thiết kế`;

            const newImages = {
              before: imageUrl,
              after: finishedImage,
            };

            const newJob = {
              images: newImages,
              prompt: prompt,
            };
            setCurrentJob(newJob);

            const jobData = {
              status: 'finished',
              mode: mode,
              uid: firebase.auth.currentUser.uid,
              prompt: prompt,
              upscaleUrl: finishedImage,
              originalUrl: imageUrl,
              maskUrl: '',
              createdAt: new Date().toISOString(),
              jobId: jobId,
            };

            message.success(t('message.success.rederingDone'));
            setIsCreating(false);
            confetti({
              origin: { y: 0.5 },
              spread: 400,
              particleCount: 100,
              origin: { y: 0.4 },
            });

            firebase
              .addJob(jobData)
              .then(result => {})
              .catch(err => {
                message.error(err.message);
              });

            firebase
              .getUser(firebase.auth.currentUser.uid)
              .then(querySnapshot => {
                setUser(querySnapshot.data());
                setUserData(querySnapshot.data());
              })
              .catch(err => {
                message.error(err.message);
              });
            return;
          } else if (result.data.status === 'failed') {
            // Job failed, handle the failure case
            message.error(t('message.error.busy'));
            setIsCreating(false);
            return;
          }
          // If the job is not yet finished, wait for some time before making the next request
          await new Promise(resolve => setTimeout(resolve, 2000));
        }
      })
      .catch(error => {
        message.error(t('message.error.wrongFile'));
        setIsCreating(false);
        // Handle the error
      });
  }

  function onGenFill(newJob) {
    setCurrentJob(newJob);
  }

  function onChangeImageSrc(imageUrl) {
    setImageUrlEditor(imageUrl);
  }

  function handleModeChangeParent(mode) {
    setMode(mode);
  }

  function handleChangeRooms(event) {
    setCurrentRoom(event.target.value);
  }

  function onShowPricing(value) {
    setTimeout(() => {
      setIsShowPricing(value);
    }, 100);
  }

  function onShowReferal() {
    setShowReferal(true);
    setShowUpgradeResolution(false);
    setShowGenFill(false);
    setShowInterior(false);
    setShowMyDesign(false);
    setShowFindAgency(false);

    const activeLink = document.querySelector('.nav-link.active');
    activeLink.classList.remove('active');
    setIsActiveMobileMenu(false);
  }

  function onSetJobsList(jobs) {
    setJobsList([]);
    setJobsList(jobs);
  }

  function onFetchUser() {
    firebase
      .getUser(firebase.auth.currentUser.uid)
      .then(querySnapshot => {
        if (!querySnapshot.empty) {
          setUserData(querySnapshot.data());
          setUser(querySnapshot.data());
        }
      })
      .catch(error => {
        console.log(error);
      });
  }

  function onSetJob(job) {
    setJobsList([]);
    const mergedJobsList = [...[job], ...jobsList];
    setJobsList(mergedJobsList);
  }

  function onSetJobsList(jobs) {
    setLastJobCreatedAt(jobs[jobs.length - 1].createdAt);
    setJobsList([]);
    setJobsList(jobs);
  }

  function onFetchUser() {
    firebase
      .getUser(firebase.auth.currentUser.uid)
      .then(querySnapshot => {
        if (!querySnapshot.empty) {
          setUserData(querySnapshot.data());
        }
      })
      .catch(error => {
        console.log(error);
      });
  }
  const handleFileUpload = event => {
    const file = event.target.files[0];
    setFile(file);
    const reader = new FileReader();

    reader.onload = function () {
      const image = new Image();

      image.onload = function () {
        const canvas = document.createElement('canvas');
        const scaleSize = MAX_WIDTH / image.width;
        canvas.width = MAX_WIDTH;
        canvas.height = image.height * scaleSize;
        setWidthInfo(canvas.width);
        setHeightInfo(canvas.height);

        const ctx = canvas.getContext('2d');

        ctx.drawImage(image, 0, 0, canvas.width, canvas.height);

        if (file.name === '' || file.name === undefined) {
          const randomNumber = Math.floor(Math.random() * 1000000000); // Generate a random number between 0 and 999999999
          file.name = `noithatai-up-${randomNumber}.jpg`;
        }

        canvas.toBlob(blob => {
          const canvasImage = new File([blob], file.name, {
            type: file.type,
            lastModified: file.lastModified,
          });
          setFileInfo(canvasImage); // Set fileInfo as the canvas image with additional properties
          setFile(canvasImage);

          const reader2 = new FileReader();
          reader2.onloadend = function () {
            const srcEncoded = reader2.result;
            setImageSrc(srcEncoded);
          };

          canvas.remove(); // Remove the canvas element
          reader2.readAsDataURL(blob);
        }, 'image/jpeg');
      };

      image.src = reader.result;
    };
    reader.readAsDataURL(file);
  };

  const handleOpenFileUpload = () => {
    fileInputRef.current.click();
  };

  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();

    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      const file = e.dataTransfer.files[0];
      setFile(file);
      const reader = new FileReader();

      reader.onload = function () {
        const image = new Image();

        image.onload = function () {
          const canvas = document.createElement('canvas');
          const scaleSize = MAX_WIDTH / image.width;
          canvas.width = MAX_WIDTH;
          canvas.height = image.height * scaleSize;

          setWidthInfo(canvas.width);
          setHeightInfo(canvas.height);

          const ctx = canvas.getContext('2d');

          ctx.drawImage(image, 0, 0, canvas.width, canvas.height);

          if (file.name === '' || file.name === undefined) {
            const randomNumber = Math.floor(Math.random() * 1000000000); // Generate a random number between 0 and 999999999
            file.name = `noithatai-up-${randomNumber}.jpg`;
          }

          canvas.toBlob(blob => {
            const canvasImage = new File([blob], file.name, {
              type: file.type,
              lastModified: file.lastModified,
            });
            setFileInfo(canvasImage); // Set fileInfo as the canvas image with additional properties
            setFile(canvasImage);

            const reader2 = new FileReader();
            reader2.onloadend = function () {
              const srcEncoded = reader2.result;
              setImageSrc(srcEncoded);
            };

            canvas.remove(); // Remove the canvas element
            reader2.readAsDataURL(blob);
          }, 'image/jpeg');
        };

        image.src = reader.result;
      };
      reader.readAsDataURL(file);
    }
  };

  // handle drag events
  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      // setDragActive(true);
      console.log('handleDrag');
    } else if (e.type === 'dragleave') {
      // setDragActive(false);
      console.log('handleDrag');
    }
  };

  const handleFileUploadFromLink = async (event, imageUrl) => {

    onActiveUpgradeResolution(event);
    const response = await fetch(imageUrl);
    const file = await response.blob();

    setFile(file);
    const reader = new FileReader();

    reader.onload = function () {
      const image = new Image();

      image.onload = function () {
        const canvas = document.createElement('canvas');
        const scaleSize = MAX_WIDTH / image.width;
        canvas.width = MAX_WIDTH;
        canvas.height = image.height * scaleSize;
        setWidthInfo(canvas.width);
        setHeightInfo(canvas.height);

        const ctx = canvas.getContext('2d');

        ctx.drawImage(image, 0, 0, canvas.width, canvas.height);

        if (file.name === '' || file.name === undefined) {
          const randomNumber = Math.floor(Math.random() * 1000000000); // Generate a random number between 0 and 999999999
          file.name = `noithatai-up-${randomNumber}.jpg`;
        }

        canvas.toBlob(blob => {
          const canvasImage = new File([blob], file.name, {
            type: file.type,
            lastModified: file.lastModified,
          });
          setFileInfo(canvasImage); // Set fileInfo as the canvas image with additional properties
          setFile(canvasImage);

          const reader2 = new FileReader();
          reader2.onloadend = function () {
            const srcEncoded = reader2.result;
            setImageSrc(srcEncoded);
          };

          canvas.remove(); // Remove the canvas element
          reader2.readAsDataURL(blob);
        }, 'image/jpeg');
      };

      image.src = reader.result;
    };
    reader.readAsDataURL(file);
  };

  const removeUploadedImage = function (e) {
    e.preventDefault();
    setImageSrc('');
  };

  function onSetThemes(theme) {
    setCurrentTheme(theme);
  }

  function onActiveGenFill(event) {
    setShowGenFill(true);
    setShowInterior(false);
    setShowMyDesign(false);
    setShowUpgradeResolution(false);
    setShowFindAgency(false);
    setShowReferal(false);

    const activeLink = document.querySelector('.nav-link.active');
    if (activeLink) {
      activeLink.classList.remove('active');
    }
    event.currentTarget.classList.add('active');
    setIsActiveMobileMenu(false);
  }

  function onActiveUpgradeResolution(event) {
    setShowUpgradeResolution(true);
    setShowGenFill(false);
    setShowInterior(false);
    setShowMyDesign(false);
    setShowFindAgency(false);
    setShowReferal(false);

    const activeLink = document.querySelector('.nav-link.active');
    if (activeLink) {
      activeLink.classList.remove('active');
    }
    event.currentTarget.classList.add('active');
    setIsActiveMobileMenu(false);
  }

  function onActiveMyPhoto(event) {
    setShowMyDesign(true);
    setShowGenFill(false);
    setShowInterior(false);
    setShowFindAgency(false);
    setShowUpgradeResolution(false);
    setShowReferal(false);

    const activeLink = document.querySelector('.nav-link.active');
    if (activeLink) {
      activeLink.classList.remove('active');
    }
    event.currentTarget.classList.add('active');
    setIsActiveMobileMenu(false);
  }

  function onActiveFindAgency(event) {
    setShowFindAgency(true);
    setShowMyDesign(false);
    setShowGenFill(false);
    setShowUpgradeResolution(false);
    setShowInterior(false);
    setShowReferal(false);

    const activeLink = document.querySelector('.nav-link.active');
    if (activeLink) {
      activeLink.classList.remove('active');
    }
    event.currentTarget.classList.add('active');
    setIsActiveMobileMenu(false);
  }

  function onActiveInterior(event) {
    setShowInterior(true);
    setShowGenFill(false);
    setShowMyDesign(false);
    setShowUpgradeResolution(false);
    setShowFindAgency(false);
    setShowReferal(false);

    const activeLink = document.querySelector('.nav-link.active');
    if (activeLink) {
      activeLink.classList.remove('active');
    }
    event.currentTarget.classList.add('active');
    setIsActiveMobileMenu(false);
  }

  const openMobileMenu = () => {
    setIsActiveMobileMenu(!isActiveMobileMenu);
  };

  return (
    <>
      {userData && (
        <>
          <div className="left-area">
            <div className="logo">
              <Link id="brand" className="navbar-brand" to="/app">
                <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="130" zoomAndPan="magnify" viewBox="0 0 150 32.999998" height="44" version="1.0">
                  <defs>
                    <g />
                  </defs>
                  <g fill="#fff" fillOpacity="1">
                    <g transform="translate(15.39844, 24.226069)">
                      <g>
                        <path d="M 20.773438 -19.277344 C 17.890625 -19.304688 15.335938 -16.28125 13.894531 -13.894531 C 14.285156 -15.253906 14.867188 -17.140625 15.253906 -18.5 C 15.171875 -19.25 13.089844 -19.582031 12.5625 -18.75 C 9.320312 -14.808594 3.382812 -16.058594 2.523438 -12.148438 C 2.109375 -10.261719 3.46875 -8.375 6.046875 -8.460938 C 6.824219 -8.460938 7.042969 -9.902344 6.574219 -10.011719 C 5.546875 -10.261719 5.324219 -10.957031 5.546875 -11.925781 C 5.992188 -13.949219 9.402344 -13.699219 11.648438 -16.140625 C 10.039062 -10.871094 8.402344 -4.742188 6.960938 -0.472656 C 6.960938 0.472656 9.707031 0.5 10.039062 -0.304688 C 10.621094 -1.664062 11.425781 -4.910156 12.648438 -8.875 C 14.226562 -12.785156 17.527344 -17.140625 19.691406 -17.140625 C 21.46875 -17.167969 20.605469 -14.144531 20.023438 -12.230469 C 18.027344 -5.769531 15.949219 1.164062 20.441406 2.328125 C 21.660156 2.578125 22.742188 0.664062 22.132812 0.167969 C 19.414062 -1.25 21.605469 -6.378906 22.992188 -11.566406 C 23.574219 -13.699219 25.542969 -19.25 20.773438 -19.277344 Z M 20.773438 -19.277344 " />
                      </g>
                    </g>
                  </g>
                  <g fill="#fff" fillOpacity="1">
                    <g transform="translate(40.04733, 24.226069)">
                      <g>
                        <path d="M 6.988281 -11.816406 C 3.714844 -11.757812 0.75 -9.261719 -0.0273438 -5.4375 C -0.859375 -1.21875 1.332031 0.277344 4.023438 0.277344 C 5.851562 0.277344 7.40625 -0.417969 8.652344 -1.636719 C 10.679688 -1.21875 13.035156 -1.636719 14.839844 -5.574219 C 15.003906 -5.933594 14.035156 -6.460938 13.867188 -6.128906 C 12.675781 -3.328125 11.066406 -2.664062 9.707031 -2.800781 C 12.066406 -6.683594 11.925781 -11.898438 6.988281 -11.816406 Z M 6.628906 -9.847656 C 8.707031 -9.957031 8.402344 -6.878906 7.320312 -4.328125 C 6.546875 -4.492188 5.824219 -3.46875 6.351562 -2.664062 C 5.851562 -2.082031 5.160156 -1.691406 4.464844 -1.691406 C 2.746094 -1.691406 2.828125 -3.6875 3.160156 -5.410156 C 3.578125 -7.488281 4.992188 -9.761719 6.628906 -9.847656 Z M 6.628906 -9.847656 " />
                      </g>
                    </g>
                  </g>
                  <g fill="#fff" fillOpacity="1">
                    <g transform="translate(53.744236, 24.226069)">
                      <g>
                        <path d="M 3.855469 -13.421875 C 4.964844 -13.421875 5.742188 -14.117188 5.851562 -15.117188 C 5.992188 -16.085938 5.378906 -16.78125 4.382812 -16.78125 C 3.355469 -16.808594 2.578125 -16.113281 2.414062 -15.117188 C 2.273438 -14.144531 2.941406 -13.421875 3.855469 -13.421875 Z M 1.746094 0.25 C 4.632812 0.195312 6.710938 -3.050781 7.875 -5.546875 C 8.042969 -5.90625 7.074219 -6.488281 6.90625 -6.128906 C 5.964844 -3.855469 4.132812 -1.691406 2.855469 -1.691406 C 2.273438 -1.664062 2.300781 -2.414062 2.523438 -3.523438 C 2.941406 -5.410156 3.523438 -7.210938 4.605469 -11.066406 C 4.410156 -11.730469 2.136719 -12.066406 1.691406 -11.425781 C 0.722656 -8.070312 0.0546875 -5.824219 -0.554688 -3.21875 C -1.054688 -1.082031 -0.554688 0.304688 1.746094 0.25 Z M 1.746094 0.25 " />
                      </g>
                    </g>
                  </g>
                  <g fill="#fff" fillOpacity="1">
                    <g transform="translate(60.454055, 24.226069)">
                      <g>
                        <path d="M 3.410156 0.25 C 6.183594 0.222656 8.210938 -2.082031 9.707031 -5.546875 C 9.847656 -5.90625 8.902344 -6.460938 8.738281 -6.128906 C 7.570312 -3.496094 5.601562 -1.664062 3.992188 -1.691406 C 2.636719 -1.71875 2.636719 -3.605469 3.21875 -6.15625 C 3.46875 -7.320312 3.882812 -8.792969 4.300781 -10.234375 L 6.90625 -10.234375 C 7.320312 -10.371094 7.65625 -11.675781 7.128906 -12.066406 L 4.796875 -12.066406 C 5.4375 -14.171875 6.074219 -16.140625 6.683594 -17.832031 C 6.65625 -18.636719 4.132812 -18.941406 3.746094 -18.386719 C 3.191406 -16.972656 2.328125 -14.394531 1.609375 -12.066406 L 0.5 -12.066406 C 0.0546875 -11.898438 -0.277344 -10.539062 0.277344 -10.234375 L 1.109375 -10.234375 C -0.167969 -5.378906 -1.441406 0.304688 3.410156 0.25 Z M 3.410156 0.25 " />
                      </g>
                    </g>
                  </g>
                  <g fill="#fff" fillOpacity="1">
                    <g transform="translate(68.99383, 24.226069)">
                      <g>
                        <path d="M 4.078125 -18.859375 C 2.496094 -14.644531 0.25 -6.824219 -1.25 -0.554688 C -1.527344 0.527344 1.804688 0.25 1.941406 -0.304688 C 2.441406 -2.605469 2.828125 -4.132812 3.550781 -6.765625 C 4.519531 -8.210938 5.992188 -9.539062 6.933594 -9.570312 C 7.933594 -9.597656 7.820312 -8.652344 7.597656 -7.820312 C 7.292969 -6.65625 6.433594 -3.9375 6.269531 -2.746094 C 6.019531 -1.027344 6.40625 0.359375 8.707031 0.25 C 11.425781 0.140625 13.394531 -3.078125 14.5625 -5.574219 C 14.726562 -5.90625 13.730469 -6.488281 13.589844 -6.128906 C 12.621094 -3.828125 10.925781 -1.71875 9.847656 -1.691406 C 8.625 -1.609375 9.734375 -4.242188 10.761719 -7.960938 C 11.398438 -10.179688 10.84375 -11.84375 8.597656 -11.816406 C 6.824219 -11.816406 5.101562 -10.152344 4.078125 -8.984375 C 4.992188 -12.230469 6.183594 -16.308594 6.988281 -18.25 C 7.15625 -19 4.632812 -19.414062 4.078125 -18.859375 Z M 4.078125 -18.859375 " />
                      </g>
                    </g>
                  </g>
                  <g fill="#fff" fillOpacity="1">
                    <g transform="translate(82.385739, 24.226069)">
                      <g>
                        <path d="M 6.601562 -11.730469 C 3.46875 -11.203125 0.222656 -8.015625 -0.386719 -4.300781 C -0.886719 -1.191406 0.664062 0.277344 2.691406 0.25 C 4.9375 0.195312 6.15625 -1.527344 7.128906 -3.21875 C 6.796875 -1.164062 7.542969 0.109375 8.875 0.109375 C 11.480469 0.109375 13.644531 -2.550781 15.085938 -5.574219 C 15.253906 -5.90625 14.253906 -6.460938 14.117188 -6.128906 C 13.117188 -3.714844 11.234375 -1.832031 10.207031 -1.804688 C 9.570312 -1.804688 9.402344 -2.441406 9.707031 -3.828125 C 9.957031 -5.1875 10.457031 -6.851562 10.761719 -7.90625 C 10.648438 -8.488281 8.597656 -9.070312 8.097656 -8.429688 C 7.15625 -4.882812 4.382812 -0.664062 3.023438 -1.996094 C 2.386719 -2.636719 2.550781 -4.519531 3.410156 -6.324219 C 4.269531 -8.152344 5.796875 -9.570312 7.40625 -9.789062 C 8.679688 -9.957031 10.234375 -9.570312 11.371094 -8.652344 C 11.816406 -8.429688 12.953125 -9.707031 12.675781 -10.152344 C 11.121094 -11.730469 8.429688 -12.035156 6.601562 -11.730469 Z M 6.601562 -11.730469 " />
                      </g>
                    </g>
                  </g>
                  <g fill="#fff" fillOpacity="1">
                    <g transform="translate(96.332183, 24.226069)">
                      <g>
                        <path d="M 3.410156 0.25 C 6.183594 0.222656 8.210938 -2.082031 9.707031 -5.546875 C 9.847656 -5.90625 8.902344 -6.460938 8.738281 -6.128906 C 7.570312 -3.496094 5.601562 -1.664062 3.992188 -1.691406 C 2.636719 -1.71875 2.636719 -3.605469 3.21875 -6.15625 C 3.46875 -7.320312 3.882812 -8.792969 4.300781 -10.234375 L 6.90625 -10.234375 C 7.320312 -10.371094 7.65625 -11.675781 7.128906 -12.066406 L 4.796875 -12.066406 C 5.4375 -14.171875 6.074219 -16.140625 6.683594 -17.832031 C 6.65625 -18.636719 4.132812 -18.941406 3.746094 -18.386719 C 3.191406 -16.972656 2.328125 -14.394531 1.609375 -12.066406 L 0.5 -12.066406 C 0.0546875 -11.898438 -0.277344 -10.539062 0.277344 -10.234375 L 1.109375 -10.234375 C -0.167969 -5.378906 -1.441406 0.304688 3.410156 0.25 Z M 3.410156 0.25 " />
                      </g>
                    </g>
                  </g>
                  <g fill="#fff" fillOpacity="1">
                    <g transform="translate(104.871958, 24.226069)">
                      <g>
                        <path d="M 5.769531 0.25 C 9.207031 0.195312 10.84375 -2.523438 12.453125 -6.40625 C 13.757812 -6.269531 15.171875 -6.101562 16.003906 -6.046875 C 15.476562 -3.992188 14.976562 -1.96875 14.753906 -0.5 C 15.058594 0.277344 17.472656 0.277344 17.890625 -0.5 C 18.109375 -2.082031 18.664062 -4.1875 19.109375 -6.101562 C 21.191406 -6.269531 23.242188 -6.933594 24.519531 -8.515625 C 24.824219 -8.902344 24.269531 -9.902344 23.769531 -9.402344 C 22.769531 -8.347656 21.132812 -7.875 19.386719 -7.765625 C 19.859375 -9.570312 20.914062 -13.867188 20.941406 -16.558594 C 20.941406 -19.441406 17.527344 -20.082031 15.085938 -17.5 C 13.144531 -15.449219 11.789062 -12.203125 10.261719 -8.570312 C 6.324219 -8.902344 2.605469 -7.210938 1.914062 -3.910156 C 1.441406 -1.441406 2.746094 0.304688 5.769531 0.25 Z M 16.390625 -7.820312 C 15.640625 -7.875 14.285156 -8.042969 13.175781 -8.210938 C 14.285156 -11.148438 15.53125 -14.589844 17.085938 -16.445312 C 17.890625 -17.390625 18.554688 -17.140625 18.386719 -16.085938 C 17.945312 -13.480469 16.972656 -9.902344 16.390625 -7.820312 Z M 5.851562 -1.71875 C 4.769531 -1.71875 4.605469 -2.800781 4.910156 -3.882812 C 5.519531 -5.796875 7.292969 -6.683594 9.484375 -6.628906 C 8.320312 -3.773438 7.433594 -1.746094 5.851562 -1.71875 Z M 5.851562 -1.71875 " />
                      </g>
                    </g>
                  </g>
                  <g fill="#fff" fillOpacity="1">
                    <g transform="translate(127.884972, 24.226069)">
                      <g>
                        <path d="M 3.855469 -13.421875 C 4.964844 -13.421875 5.742188 -14.117188 5.851562 -15.117188 C 5.992188 -16.085938 5.378906 -16.78125 4.382812 -16.78125 C 3.355469 -16.808594 2.578125 -16.113281 2.414062 -15.117188 C 2.273438 -14.144531 2.941406 -13.421875 3.855469 -13.421875 Z M 1.746094 0.25 C 4.632812 0.195312 6.710938 -3.050781 7.875 -5.546875 C 8.042969 -5.90625 7.074219 -6.488281 6.90625 -6.128906 C 5.964844 -3.855469 4.132812 -1.691406 2.855469 -1.691406 C 2.273438 -1.664062 2.300781 -2.414062 2.523438 -3.523438 C 2.941406 -5.410156 3.523438 -7.210938 4.605469 -11.066406 C 4.410156 -11.730469 2.136719 -12.066406 1.691406 -11.425781 C 0.722656 -8.070312 0.0546875 -5.824219 -0.554688 -3.21875 C -1.054688 -1.082031 -0.554688 0.304688 1.746094 0.25 Z M 1.746094 0.25 " />
                      </g>
                    </g>
                  </g>
                </svg>
              </Link>
              {isMobile && (
                <nav className="hamburger-menu" role="navigation">
                  <div id="menuToggle">
                    <input type="checkbox" checked={isActiveMobileMenu} onChange={() => openMobileMenu()} />
                    <span></span>
                    <span></span>
                    <span></span>
                    <div id="menu"></div>
                  </div>
                </nav>
              )}
            </div>
            <div className="sub-area">
              <ComposeSection
                t={t}
                userData={userData}
                handleModeChangeParent={handleModeChangeParent}
                onShowPricing={onShowPricing}
                defaultMode={mode}
                onShowReferal={onShowReferal}
              />
            </div>
            {!isMobile ? (
              <>
                <div className="nav-menu">
                  <Link to="/app" className="nav-link active" onClick={onActiveInterior}>
                    <svg viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                      <path
                        fillRule="evenodd" clipRule="evenodd"
                        d="M12.6139 1.21065C12.2528 0.929784 11.7472 0.929784 11.3861 1.21065L2.38606 8.21065C2.14247 8.4001 2 8.69141 2 9V20C2 21.1046 2.89543 22 4 22H20C21.1046 22 22 21.1046 22 20V9C22 8.69141 21.8575 8.4001 21.6139 8.21065L12.6139 1.21065ZM16 20H20V9.48908L12 3.26686L4 9.48908V20H8V12C8 11.4477 8.44772 11 9 11H15C15.5523 11 16 11.4477 16 12V20ZM10 20V13H14V20H10Z"
                        fill="currentColor"
                      />
                    </svg>
                    <span>Tự động thiết kế</span>
                  </Link>
                  <Link to="/app" className="nav-link" onClick={onActiveGenFill}>
                    <svg viewBox="0 0 22 22" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M9.6 5.613C7.91 5.466 6.98 4.874 6.484 3.7c-.179-.423-.304-.917-.384-1.5 0-.1-.1-.2-.2-.2s-.2.1-.2.2c-.08.583-.205 1.077-.384 1.5C4.821 4.874 3.891 5.466 2.2 5.613c-.1 0-.2.1-.2.2s.1.2.2.2c2.1.4 3.2 1.187 3.5 3.387 0 .1.1.2.2.2s.2-.1.2-.2c.3-2.2 1.4-2.987 3.5-3.387.1 0 .2-.1.2-.2s-.1-.2-.2-.2ZM19.469 11.865c-4-.8-5.726-2.73-6.526-6.629a.493.493 0 0 0-.474-.371.493.493 0 0 0-.475.376c-.009.006.007-.015 0 0-.8 4-2.625 5.824-6.525 6.624a.5.5 0 0 0 0 1c4 .8 5.717 2.687 6.517 6.587a.493.493 0 0 0 .483.413.493.493 0 0 0 .477-.387c-.005.01.006-.008 0 0 .8-4 2.623-5.813 6.523-6.613a.5.5 0 0 0 0-1ZM21.465 5.8c0-.084-.061-.14-.144-.156l-.056-.013c-1.168-.305-1.876-1.024-2.073-2.108a.153.153 0 0 0-.153-.153v.004c-.084 0-.14.062-.156.144l-.013.056c-.305 1.168-1.024 1.876-2.108 2.073a.153.153 0 0 0-.153.153h.004c0 .084.062.14.145.156l.055.013c1.168.305 1.876 1.024 2.073 2.108 0 .084.069.153.153.153v-.004c.084 0 .14-.062.156-.145l.014-.055c.304-1.168 1.023-1.876 2.107-2.073a.15.15 0 0 0 .15-.153ZM7.919 18.715c-1-.3-1.582-.782-1.782-1.782a.218.218 0 1 0-.436 0c-.3 1-.782 1.582-1.782 1.782a.218.218 0 0 0 0 .436c1 .3 1.582.782 1.782 1.782a.218.218 0 0 0 .436 0c.3-1 .782-1.582 1.782-1.782a.218.218 0 0 0 0-.436Z"
                        fill="currentColor"
                      />
                    </svg>
                    <span>Chỉ định thiết kế</span>
                  </Link>
                  <Link to="/app" className="nav-link" onClick={onActiveUpgradeResolution}>
                    <svg width="16px" height="16px" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="bi bi-badge-4k">
                      <path d="M4.807 5.001C4.021 6.298 3.203 7.6 2.5 8.917v.971h2.905V11h1.112V9.888h.733V8.93h-.733V5.001h-1.71zm-1.23 3.93v-.032a46.781 46.781 0 0 1 1.766-3.001h.062V8.93H3.577zm9.831-3.93h-1.306L9.835 7.687h-.057V5H8.59v6h1.187V9.075l.615-.699L12.072 11H13.5l-2.232-3.415 2.14-2.584z"/>
                      <path d="M14 3a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h12zM2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H2z"/>
                    </svg>
                    <span>Làm nét thiết kế</span>
                  </Link>
                  <Link to="/app" className="nav-link" onClick={onActiveFindAgency}>
                    <svg fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path d="M21,2H3A1,1,0,0,0,2,3V21a1,1,0,0,0,1,1h7a1,1,0,0,0,1-1V11H21a1,1,0,0,0,1-1V3A1,1,0,0,0,21,2ZM20,9H19V7a1,1,0,0,0-2,0V9H15V7a1,1,0,0,0-2,0V9H11V7A1,1,0,0,0,9,7V9H7a1,1,0,0,0,0,2H9v2H7a1,1,0,0,0,0,2H9v2H7a1,1,0,0,0,0,2H9v1H4V4H20Z"/>
                    </svg>
                    <span>Tư vấn và thi công</span>
                  </Link>
                  <Link to="/app" className="nav-link" onClick={onActiveMyPhoto}>
                    <svg fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path d="M3,11h7a1,1,0,0,0,1-1V3a1,1,0,0,0-1-1H3A1,1,0,0,0,2,3v7A1,1,0,0,0,3,11ZM4,4H9V9H4ZM22,3a1,1,0,0,0-1-1H14a1,1,0,0,0-1,1v7a1,1,0,0,0,1,1h7a1,1,0,0,0,1-1ZM20,9H15V4h5ZM2,21a1,1,0,0,0,1,1h7a1,1,0,0,0,1-1V14a1,1,0,0,0-1-1H3a1,1,0,0,0-1,1Zm2-6H9v5H4Zm18,6V14a1,1,0,0,0-1-1H14a1,1,0,0,0-1,1v7a1,1,0,0,0,1,1h7A1,1,0,0,0,22,21Zm-2-1H15V15h5Z" />
                    </svg>
                    <span>{t('header.nav.myFlowers')}</span>
                  </Link>
                </div>
                <div className="left-down-menu">
                  <div className="dropdown">
                    <NewAvatarHeader userData={userData} t={t} />
                  </div>
                </div>
              </>
            ) : (
              <div className={`nav-menu-mobile ${isActiveMobileMenu ? 'active' : ''}`}>
                <div className="nav-menu">
                  <Link to="/app" className="nav-link active" onClick={onActiveInterior}>
                    <svg viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                      <path
                        fillRule="evenodd" clipRule="evenodd"
                        d="M12.6139 1.21065C12.2528 0.929784 11.7472 0.929784 11.3861 1.21065L2.38606 8.21065C2.14247 8.4001 2 8.69141 2 9V20C2 21.1046 2.89543 22 4 22H20C21.1046 22 22 21.1046 22 20V9C22 8.69141 21.8575 8.4001 21.6139 8.21065L12.6139 1.21065ZM16 20H20V9.48908L12 3.26686L4 9.48908V20H8V12C8 11.4477 8.44772 11 9 11H15C15.5523 11 16 11.4477 16 12V20ZM10 20V13H14V20H10Z"
                        fill="currentColor"
                      />
                    </svg>
                    <span>Tự động thiết kế</span>
                  </Link>
                  <Link to="/app" className="nav-link" onClick={onActiveGenFill}>
                    <svg viewBox="0 0 22 22" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M9.6 5.613C7.91 5.466 6.98 4.874 6.484 3.7c-.179-.423-.304-.917-.384-1.5 0-.1-.1-.2-.2-.2s-.2.1-.2.2c-.08.583-.205 1.077-.384 1.5C4.821 4.874 3.891 5.466 2.2 5.613c-.1 0-.2.1-.2.2s.1.2.2.2c2.1.4 3.2 1.187 3.5 3.387 0 .1.1.2.2.2s.2-.1.2-.2c.3-2.2 1.4-2.987 3.5-3.387.1 0 .2-.1.2-.2s-.1-.2-.2-.2ZM19.469 11.865c-4-.8-5.726-2.73-6.526-6.629a.493.493 0 0 0-.474-.371.493.493 0 0 0-.475.376c-.009.006.007-.015 0 0-.8 4-2.625 5.824-6.525 6.624a.5.5 0 0 0 0 1c4 .8 5.717 2.687 6.517 6.587a.493.493 0 0 0 .483.413.493.493 0 0 0 .477-.387c-.005.01.006-.008 0 0 .8-4 2.623-5.813 6.523-6.613a.5.5 0 0 0 0-1ZM21.465 5.8c0-.084-.061-.14-.144-.156l-.056-.013c-1.168-.305-1.876-1.024-2.073-2.108a.153.153 0 0 0-.153-.153v.004c-.084 0-.14.062-.156.144l-.013.056c-.305 1.168-1.024 1.876-2.108 2.073a.153.153 0 0 0-.153.153h.004c0 .084.062.14.145.156l.055.013c1.168.305 1.876 1.024 2.073 2.108 0 .084.069.153.153.153v-.004c.084 0 .14-.062.156-.145l.014-.055c.304-1.168 1.023-1.876 2.107-2.073a.15.15 0 0 0 .15-.153ZM7.919 18.715c-1-.3-1.582-.782-1.782-1.782a.218.218 0 1 0-.436 0c-.3 1-.782 1.582-1.782 1.782a.218.218 0 0 0 0 .436c1 .3 1.582.782 1.782 1.782a.218.218 0 0 0 .436 0c.3-1 .782-1.582 1.782-1.782a.218.218 0 0 0 0-.436Z"
                        fill="currentColor"
                      />
                    </svg>
                    <span>Chỉ định thiết kế</span>
                  </Link>
                  <Link to="/app" className="nav-link" onClick={onActiveUpgradeResolution}>
                    <svg width="16px" height="16px" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="bi bi-badge-4k">
                      <path d="M4.807 5.001C4.021 6.298 3.203 7.6 2.5 8.917v.971h2.905V11h1.112V9.888h.733V8.93h-.733V5.001h-1.71zm-1.23 3.93v-.032a46.781 46.781 0 0 1 1.766-3.001h.062V8.93H3.577zm9.831-3.93h-1.306L9.835 7.687h-.057V5H8.59v6h1.187V9.075l.615-.699L12.072 11H13.5l-2.232-3.415 2.14-2.584z"/>
                      <path d="M14 3a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h12zM2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H2z"/>
                    </svg>
                    <span>Làm nét thiết kế</span>
                  </Link>
                  <Link to="/app" className="nav-link" onClick={onActiveFindAgency}>
                    <svg fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path d="M21,2H3A1,1,0,0,0,2,3V21a1,1,0,0,0,1,1h7a1,1,0,0,0,1-1V11H21a1,1,0,0,0,1-1V3A1,1,0,0,0,21,2ZM20,9H19V7a1,1,0,0,0-2,0V9H15V7a1,1,0,0,0-2,0V9H11V7A1,1,0,0,0,9,7V9H7a1,1,0,0,0,0,2H9v2H7a1,1,0,0,0,0,2H9v2H7a1,1,0,0,0,0,2H9v1H4V4H20Z"/>
                    </svg>
                    <span>Tư vấn và thi công</span>
                  </Link>
                  <Link to="/app" className="nav-link" onClick={onActiveMyPhoto}>
                    <svg fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path d="M3,11h7a1,1,0,0,0,1-1V3a1,1,0,0,0-1-1H3A1,1,0,0,0,2,3v7A1,1,0,0,0,3,11ZM4,4H9V9H4ZM22,3a1,1,0,0,0-1-1H14a1,1,0,0,0-1,1v7a1,1,0,0,0,1,1h7a1,1,0,0,0,1-1ZM20,9H15V4h5ZM2,21a1,1,0,0,0,1,1h7a1,1,0,0,0,1-1V14a1,1,0,0,0-1-1H3a1,1,0,0,0-1,1Zm2-6H9v5H4Zm18,6V14a1,1,0,0,0-1-1H14a1,1,0,0,0-1,1v7a1,1,0,0,0,1,1h7A1,1,0,0,0,22,21Zm-2-1H15V15h5Z" />
                    </svg>
                    <span>{t('header.nav.myFlowers')}</span>
                  </Link>
                </div>
                <div className="left-down-menu">
                  <div className="dropdown">
                    <NewAvatarHeader userData={userData} t={t} />
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="right-area">
            {showInterior && (
              <div className="editor-area">
                <div className="form-area">
                  <div className="generate-form">
                    <h3>{t('home.uploadTitle')}</h3>
                    <p style={{ color: '#8c8c8c', fontSize: '14px', marginTop: "0"}}>
                      Để có kết quả tốt nhất, hãy đảm bảo tải lên ảnh rõ nét ở góc thẳng 90°
                    </p>
                    <div className="d-flex flex-column">
                      <div className="upload-title">
                        {imageSrc && (
                          <a onClick={removeUploadedImage} style={{ marginTop: '-25px' }}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="14"
                              height="14"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="feather feather-trash text-[#9ca3af] hover:text-white transition"
                            >
                              <path d="M3 6L5 6 21 6"></path>
                              <path d="M19 6v14a2 2 0 01-2 2H7a2 2 0 01-2-2V6m3 0V4a2 2 0 012-2h4a2 2 0 012 2v2"></path>
                            </svg>
                          </a>
                        )}
                      </div>
                      <div className="page-header mb-0 page-header-reset mb-2" />
                      {!imageSrc && (
                        <div
                          className="uploader-wrapper"
                          onClick={handleOpenFileUpload}
                          onDragOver={handleDrag}
                          onDragEnter={handleDrag}
                          onDragLeave={handleDrag}
                          onDrop={handleDrop}
                        >
                          <div className="uploader-title">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="icon"
                              role="presentation"
                            >
                              <path d="M21 15v4a2 2 0 01-2 2H5a2 2 0 01-2-2v-4" />
                              <polyline points="17 8 12 3 7 8" />
                              <line x1="12" y1="3" x2="12" y2="15" />
                            </svg>
                            <span style={{ marginLeft: '4px' }}>{t('home.uploadPlaceHolder')}</span>
                          </div>
                          <input
                            type="file"
                            accept="image/*"
                            onChange={handleFileUpload}
                            style={{ display: 'none' }}
                            ref={fileInputRef}
                          />
                        </div>
                      )}
                      {imageSrc && (
                        <div className="uploaded-wrapper">
                          <div>
                            <img src={imageSrc} alt="Uploaded Image" className="uploaded-image" />
                          </div>
                        </div>
                      )}
                      <div className="page-header mb-0 page-header-reset mb-4" />
                      <label>{t('home.roomTitle')}</label>
                      <div className="page-header mb-0 page-header-reset mb-2" />
                      <FormControl sx={{ width: '100%' }}>
                        <Select value={currentRoom} onChange={handleChangeRooms} autoWidth style={{ fontSize: '14px' }}>
                          {roomsList.map((c, i) => {
                            return (
                              <MenuItem key={i} value={c.value}>
                                {c.label}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </div>
                    <div className="page-header mb-0 page-header-reset mb-4" />
                    <label>{t('home.themeTitle')}</label>
                    <Themes
                      onSetThemes={onSetThemes}
                      t={t}
                      themesList={themesList}
                      userData={userData}
                      setUser={setUser}
                      firebase={firebase}
                      onShowPricing={onShowPricing}
                      onSetJobsList={onSetJobsList}
                      onSetJob={onSetJob}
                    />
                  </div>
                  <div className="create-imagine-button generate-btn">
                    <Button onClick={onCreateImage} type="submit" className="generate-button w-button" loading={isCreating}>
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="#fff" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M9.6 5.613C7.91 5.466 6.98 4.874 6.484 3.7c-.179-.423-.304-.917-.384-1.5 0-.1-.1-.2-.2-.2s-.2.1-.2.2c-.08.583-.205 1.077-.384 1.5C4.821 4.874 3.891 5.466 2.2 5.613c-.1 0-.2.1-.2.2s.1.2.2.2c2.1.4 3.2 1.187 3.5 3.387 0 .1.1.2.2.2s.2-.1.2-.2c.3-2.2 1.4-2.987 3.5-3.387.1 0 .2-.1.2-.2s-.1-.2-.2-.2ZM19.469 11.865c-4-.8-5.726-2.73-6.526-6.629a.493.493 0 0 0-.474-.371.493.493 0 0 0-.475.376c-.009.006.007-.015 0 0-.8 4-2.625 5.824-6.525 6.624a.5.5 0 0 0 0 1c4 .8 5.717 2.687 6.517 6.587a.493.493 0 0 0 .483.413.493.493 0 0 0 .477-.387c-.005.01.006-.008 0 0 .8-4 2.623-5.813 6.523-6.613a.5.5 0 0 0 0-1ZM21.465 5.8c0-.084-.061-.14-.144-.156l-.056-.013c-1.168-.305-1.876-1.024-2.073-2.108a.153.153 0 0 0-.153-.153v.004c-.084 0-.14.062-.156.144l-.013.056c-.305 1.168-1.024 1.876-2.108 2.073a.153.153 0 0 0-.153.153h.004c0 .084.062.14.145.156l.055.013c1.168.305 1.876 1.024 2.073 2.108 0 .084.069.153.153.153v-.004c.084 0 .14-.062.156-.145l.014-.055c.304-1.168 1.023-1.876 2.107-2.073a.15.15 0 0 0 .15-.153ZM7.919 18.715c-1-.3-1.582-.782-1.782-1.782a.218.218 0 1 0-.436 0c-.3 1-.782 1.582-1.782 1.782a.218.218 0 0 0 0 .436c1 .3 1.582.782 1.782 1.782a.218.218 0 0 0 .436 0c.3-1 .782-1.582 1.782-1.782a.218.218 0 0 0 0-.436Z"
                          fill="#fff"
                        />
                      </svg>
                      <span style={{ marginLeft: '2px' }}>{t('button.generate')}</span>
                    </Button>
                  </div>
                </div>
                <AllShowCase
                  userData={userData}
                  checkCredits={checkCredits}
                  firebase={firebase}
                  currentJob={currentJob}
                  t={t}
                  jobsList={jobsList}
                  isCreating={isCreating}
                  onGenFill={onGenFill}
                  onFetchUser={onFetchUser}
                  onUpImageResolution={handleFileUploadFromLink}
                />
              </div>
            )}
            {showGenFill && (
              <div className="editor-area">
                <div className="form-area">
                  <div className="generate-form">
                    <h3>{t('home.uploadTitle')}</h3>
                    <p style={{ color: '#8c8c8c', fontSize: '14px', marginTop: "0"}}>
                    Để có kết quả tốt nhất, hãy đảm bảo tải lên ảnh rõ nét ở góc thẳng 90°
                    </p>
                    <div className="d-flex flex-column">
                      <div className="upload-title">
                        {imageSrc && (
                          <a onClick={removeUploadedImage} style={{ marginTop: '-25px' }}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="14"
                              height="14"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="feather feather-trash text-[#9ca3af] hover:text-white transition"
                            >
                              <path d="M3 6L5 6 21 6"></path>
                              <path d="M19 6v14a2 2 0 01-2 2H7a2 2 0 01-2-2V6m3 0V4a2 2 0 012-2h4a2 2 0 012 2v2"></path>
                            </svg>
                          </a>
                        )}
                      </div>
                      <div className="page-header mb-0 page-header-reset mb-2" />
                      {!imageSrc && (
                        <div
                          className="uploader-wrapper"
                          onClick={handleOpenFileUpload}
                          onDragOver={handleDrag}
                          onDragEnter={handleDrag}
                          onDragLeave={handleDrag}
                          onDrop={handleDrop}
                        >
                          <div className="uploader-title">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="icon"
                              role="presentation"
                            >
                              <path d="M21 15v4a2 2 0 01-2 2H5a2 2 0 01-2-2v-4" />
                              <polyline points="17 8 12 3 7 8" />
                              <line x1="12" y1="3" x2="12" y2="15" />
                            </svg>
                            <span style={{ marginLeft: '4px' }}>{t('home.uploadPlaceHolder')}</span>
                          </div>
                          <input
                            type="file"
                            accept="image/*"
                            onChange={handleFileUpload}
                            style={{ display: 'none' }}
                            ref={fileInputRef}
                          />
                        </div>
                      )}
                      {imageSrc && (
                        <>
                          <div className="uploaded-wrapper">
                            <div>
                              <img src={imageSrc} alt="Uploaded Image" className="uploaded-image" />
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                    <div className="page-header mb-0 page-header-reset mb-4" />
                  </div>
                  <div className="create-imagine-button generate-btn">
                    <Button onClick={onShowGenFillImage} type="submit" className="generate-button w-button" loading={isUploading}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="icon icon-tabler icon-tabler-lasso"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        strokeWidth="2"
                        stroke="white"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M4.028 13.252c-.657 -.972 -1.028 -2.078 -1.028 -3.252c0 -3.866 4.03 -7 9 -7s9 3.134 9 7s-4.03 7 -9 7c-1.913 0 -3.686 -.464 -5.144 -1.255" />
                        <circle cx="5" cy="15" r="2" />
                        <path d="M5 17c0 1.42 .316 2.805 1 4" />
                      </svg>
                      <span style={{ marginLeft: '4px' }}>Khoanh vùng thay đổi</span>
                    </Button>
                  </div>
                </div>
                <AllShowCase
                  userData={userData}
                  checkCredits={checkCredits}
                  firebase={firebase}
                  currentJob={currentJob}
                  t={t}
                  jobsList={jobsList}
                  isCreating={isCreating}
                  onGenFill={onGenFill}
                  onFetchUser={onFetchUser}
                  onUpImageResolution={handleFileUploadFromLink}
                />
              </div>
            )}
            {showUpgradeResolution && (
              <div className="editor-area">
                <div className="form-area">
                  <div className="generate-form">
                    <h3>{t('home.uploadTitle')}</h3>
                    <p style={{ color: '#8c8c8c', fontSize: '14px', marginTop: "0"}}>
                      Nâng cấp độ phân giải cao giúp thiết kế của bạn rõ nét và chất lượng hơn
                    </p>
                    <div className="d-flex flex-column">
                      <div className="upload-title">
                        {imageSrc && (
                          <a onClick={removeUploadedImage} style={{ marginTop: '-25px' }}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="14"
                              height="14"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="feather feather-trash text-[#9ca3af] hover:text-white transition"
                            >
                              <path d="M3 6L5 6 21 6"></path>
                              <path d="M19 6v14a2 2 0 01-2 2H7a2 2 0 01-2-2V6m3 0V4a2 2 0 012-2h4a2 2 0 012 2v2"></path>
                            </svg>
                          </a>
                        )}
                      </div>
                      <div className="page-header mb-0 page-header-reset mb-2" />
                      {!imageSrc && (
                        <div
                          className="uploader-wrapper"
                          onClick={handleOpenFileUpload}
                          onDragOver={handleDrag}
                          onDragEnter={handleDrag}
                          onDragLeave={handleDrag}
                          onDrop={handleDrop}
                        >
                          <div className="uploader-title">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="icon"
                              role="presentation"
                            >
                              <path d="M21 15v4a2 2 0 01-2 2H5a2 2 0 01-2-2v-4" />
                              <polyline points="17 8 12 3 7 8" />
                              <line x1="12" y1="3" x2="12" y2="15" />
                            </svg>
                            <span style={{ marginLeft: '4px' }}>{t('home.uploadPlaceHolder')}</span>
                          </div>
                          <input
                            type="file"
                            accept="image/*"
                            onChange={handleFileUpload}
                            style={{ display: 'none' }}
                            ref={fileInputRef}
                          />
                        </div>
                      )}
                      {imageSrc && (
                        <>
                          <div className="uploaded-wrapper">
                            <div>
                              <img src={imageSrc} alt="Uploaded Image" className="uploaded-image" />
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                    <div className="page-header mb-0 page-header-reset mb-4" />
                  </div>
                  <div className="create-imagine-button generate-btn">
                    <Button onClick={onUpgradeResolution} type="submit" className="generate-button w-button" loading={isUploading}>
                      {/* <svg width="16px" height="16px" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="bi bi-badge-4k">
                        <path d="M4.807 5.001C4.021 6.298 3.203 7.6 2.5 8.917v.971h2.905V11h1.112V9.888h.733V8.93h-.733V5.001h-1.71zm-1.23 3.93v-.032a46.781 46.781 0 0 1 1.766-3.001h.062V8.93H3.577zm9.831-3.93h-1.306L9.835 7.687h-.057V5H8.59v6h1.187V9.075l.615-.699L12.072 11H13.5l-2.232-3.415 2.14-2.584z"/>
                        <path d="M14 3a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h12zM2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H2z"/>
                      </svg> */}
                      <span style={{ marginLeft: '4px' }}>Bắt đầu nâng cấp</span>
                    </Button>
                  </div>
                </div>
                <AllShowCase
                  userData={userData}
                  checkCredits={checkCredits}
                  firebase={firebase}
                  currentJob={currentJob}
                  t={t}
                  jobsList={jobsList}
                  isCreating={isCreating}
                  onGenFill={onGenFill}
                  onFetchUser={onFetchUser}
                  onUpImageResolution={handleFileUploadFromLink}
                />
              </div>
            )}
            {showMyDesign && (
              <MyDesign
                userData={userData}
                checkCredits={checkCredits}
                firebase={firebase}
                t={t}
                jobsList={jobsList}
                isCreating={isCreating}
                onGenFill={onGenFill}
                onFetchUser={onFetchUser}
                onUpImageResolution={handleFileUploadFromLink}
              />
            )}
            {showAgency && (
              <FindAgency
                firebase={firebase}
                t={t}
                jobsList={jobsList}
                isCreating={isCreating}
                onGenFill={onGenFill}
                onFetchUser={onFetchUser}
                userData={userData}
              />
            )}
            {showReferal && (
              <Referal
                firebase={firebase}
                t={t}
                jobsList={jobsList}
                isCreating={isCreating}
                onGenFill={onGenFill}
                onFetchUser={onFetchUser}
                userData={userData}
              />
            )}
          </div>
        </>
      )}
      {isShowPricing && <Pricing t={t} userData={userData} firebase={firebase} onShowPricing={onShowPricing} onFetchUser={onFetchUser} />}
      {isSpinning && <Spinner />}
      {showGenFillEditor && (
        <EditImage
          checkCredits={checkCredits}
          imageUrl={imageUrlEditor}
          onClose={onCloseImageEdit}
          firebase={firebase}
          t={t}
          onGenFill={onGenFill}
          onChangeImageSrc={onChangeImageSrc}
          onFetchUser={onFetchUser}
        />
      )}
    </>
  );
};

export default NewHome;
