import React, { useEffect, useRef, useState } from 'react';
import { message } from 'antd';
import FAQ from '../FAQ';
import axios from 'axios';
import confetti from 'canvas-confetti';
import {
  TextField,
} from '@mui/material';

const APP_URL = process.env.REACT_APP_APP_URL;
const CHECKOUT_30CREDITS = '99,000';
const CHECKOUT_100CREDITS = '299,000';
const CHECKOUT_200CREDITS = '499,000';

const PAYMENT_METHOD = {
  VIETCOMBANK: 'VCB',
  MOMO: 'MOMO',
};

const Pricing = ({ userData, firebase, onShowPricing, onFetchUser, t }) => {
  const popupRef = useRef(null);

  const [showPopup, setShowPopup] = useState(true);
  const [showSub, setShowSub] = useState(true);
  const [isExpanded, setIsExpanded] = useState([true, false, false]); // Initial states for each component
  const [showPaymentSelection, setShowPaymentSelection] = useState(false);
  const [price, setPrice] = useState('0');
  const [credits, setCredits] = useState(0);
  const [showPurchasingScreen, setShowPurchasingScreen] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [formError, setFormError] = useState({
    phoneNumber: false,
  });

  useEffect(() => {
    // Add event listener to close the popup when clicked outside
    const handleClickOutside = event => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        onShowPricing(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    let interval;
    firebase
      .getTransactions(firebase.auth.currentUser.uid)
      .then(result => {
        const transactions = result.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));

        if (transactions.length > 0) {
          if (!transactions[0].isActivated) {
            setPrice(transactions[0].price)
            setShowSub(false);
            setShowPaymentSelection(false);
            setShowPurchasingScreen(true);
            hearts();
            let counter = 0;
            interval = setInterval(() => {
              counter++; // Increment counter

              // Do something every 10 seconds
              firebase
                .getTransactions(firebase.auth.currentUser.uid)
                .then(result => {
                  const transactions = result.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data(),
                  }));

                  if (transactions.length > 0 && transactions[0].isActivated) {
                    confetti({
                      origin: { y: 0.5 },
                      spread: 400,
                      particleCount: 100,
                      origin: { y: 0.4 },
                    });
                    onShowPricing(false);
                    onFetchUser();
                    message.success(t('message.success.activated'));
                    clearInterval(interval);
                  }
                })
                .catch(err => {
                  message.error(err.message);
                });

              if (counter === 6) {
                clearInterval(interval); // Clear the interval after 10 iterations
              }
            }, 10000); // 10000 milliseconds = 10 seconds
          } else {
            setShowSub(true);
          }
        } else {
          setShowSub(true);
        }
      })
      .catch(err => {
        message.error(err.message);
      });

    return () => {
      document.removeEventListener('click', handleClickOutside);
      // clearInterval(interval);
      // console.log('Interval cleared');
    };
  }, [popupRef]);

  const handleToggleClick = index => {
    const newExpandedState = isExpanded.map((_, i) => i === index);
    setIsExpanded(newExpandedState); // Update the state array with the new state
  };

  function onSub(event, CHECKOUT_ITEM) {
    event.stopPropagation();
    switch (CHECKOUT_ITEM) {
      case CHECKOUT_30CREDITS:
        setPrice(CHECKOUT_30CREDITS);
        setCredits(30);
        break;
      case CHECKOUT_100CREDITS:
        setPrice(CHECKOUT_100CREDITS);
        setCredits(100);
        break;
      case CHECKOUT_200CREDITS:
        setPrice(CHECKOUT_200CREDITS);
        setCredits(200);
        break;
      default:
        break;
    }

    setShowSub(false);
    setShowPaymentSelection(true);
  }

  function showSupport() {
    window.HubSpotConversations.widget.open();
  }

  function onConfirm(event, paymentName) {
    event.stopPropagation();

    // if (phoneNumber === "" || phoneNumber === null || phoneNumber === undefined) {
    //   alert("Vui lòng nhập số điện thoại giúp chúng tôi liên hệ trong quá trình thanh toán khi cần thiết");
    //   return;
    // }
    // showConfirm();
    setShowSub(false);
    setShowPaymentSelection(false);
    setShowPurchasingScreen(true);
    // setTimeout(() => {
    //   setShowPurchasingScreen(true);
    // }, 2000);

    const requestData = {
      data: {
        uid: firebase.auth.currentUser.uid,
        email: firebase.auth.currentUser.email,
        credits: credits,
        paymentName: paymentName,
        price: price,
        content: userData.monthlyBasicCode,
        // phoneNumber: phoneNumber
      },
    };

    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${firebase.auth.currentUser.accessToken}`,
    };

    axios
      .post(`${APP_URL}/payment`, requestData, { headers })
      .then(response => {
        let counter = 0;
        const interval = setInterval(() => {
          counter++; // Increment counter

          firebase
            .getTransactions(firebase.auth.currentUser.uid)
            .then(result => {
              const transactions = result.docs.map(doc => ({
                id: doc.id,
                ...doc.data(),
              }));

              if (transactions.length > 0 && transactions[0].isActivated) {
                confetti({
                  origin: { y: 0.5 },
                  spread: 400,
                  particleCount: 100,
                  origin: { y: 0.4 },
                });
                onShowPricing(false);
                onFetchUser();
                message.success(t('message.success.activated'));
                clearInterval(interval);
              }
            })
            .catch(err => {
              message.error(err.message);
            });

          if (counter === 30) {
            clearInterval(interval); // Clear the interval after 10 iterations
          }
        }, 10000);
      })
      .catch(error => {
        message.error(error);
      });
  }

  function hearts() {
    const elements = document.querySelectorAll('.thanks-mess .hearts');
    elements.forEach(element => {
      const heartcount = (element.offsetWidth / 50) * 2;
      for (let i = 0; i <= heartcount; i++) {
        const size = Math.floor(Math.random() * (12 - 6 + 1)) + 6;
        const top = Math.floor(Math.random() * (80 - 20 + 1)) + 20;
        const left = Math.floor(Math.random() * (95 - 0 + 1)) + 0;
        const delay = Math.floor(Math.random() * (30 - 0 + 1)) + 0;
        const particle = document.createElement('span');
        particle.classList.add('particle');
        particle.style.top = `${top}%`;
        particle.style.left = `${left}%`;
        particle.style.width = `${size}px`;
        particle.style.height = `${size}px`;
        particle.style.animationDelay = `${delay / 10}s`;
        element.appendChild(particle);
      }
    });
  }

  function renderLove() {
    hearts();
  }

  function handleChangePhoneNumber(event) {
    setFormError(prevData => ({
      ...prevData,
      phoneNumber: false,
    }));
    setPhoneNumber(event.target.value);
  }

  return (
    <>
      {showPopup && (
        <div className="pricing-showbox">
          <section id="pricing-container" ref={popupRef}>
            {showSub && (
              <>
                <div>
                  <div id="pricing-switch">
                    {/* <span className="switch-label switch-label-monthly" style={!isYearly ? { opacity: '1' } : { opacity: '0.4' }} >{t('pricing.monthlySubscription')}</span>
                      <label className="switch">
                        <input type="checkbox" defaultChecked={isYearly} onChange={changeSub} />
                        <span className="slider" />
                      </label>
                      <span className="switch-label switch-label-yearly active" style={isYearly ? { opacity: '1' } : { opacity: '0.4' }} >{t('pricing.yearlySubscription')}<span className="save-money">{t('pricing.saveDesk')}</span></span>
                      <div className="save-money--mobile" style={isYearly ? { opacity: '1' } : { opacity: '0.4' }}>{t('pricing.saveMobile')}</div> */}
                  </div>
                  <div id="pricing-cards">
                    {/* <div className="price-card">
                      <div className="price-card--header">
                        <h4>Thử nghiệm</h4>
                      </div>
                      <div className="price-card--price">
                        <div className="price-card--price-text">
                          <div className="price-card--price-number toggle-price-content odometer">
                            99k
                          </div>
                        </div>
                        <div className="price-card--price-conditions">
                          <div className="price-card--onboarding-fee toggle-price-content">Dùng thử Miễn phí đã tạm dừng</div>
                          <div className="price-card--onboarding-fee toggle-price-content">vì lí do của chi phí vận hành AI</div>
                          <div className="price-card--onboarding-fee toggle-price-content"> rất mong quý khách thông cảm!</div>

                        </div>
                      </div>
                      <div className="price-card--cta" onClick={e => onSub(e, CHECKOUT_30CREDITS)}>
                        <button className="price-card--cta--button upgrade-button">Thử gói này</button>
                      </div>
                      <div className="price-card--features">
                        <ul className="price-card--features--list">
                          <li className="price-card--features--item">
                            <span className="green-check">
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M17.0964 7.39004L9.9364 14.3L8.0364 12.27C7.6864 11.94 7.1364 11.92 6.7364 12.2C6.3464 12.49 6.2364 13 6.4764 13.41L8.7264 17.07C8.9464 17.41 9.3264 17.62 9.7564 17.62C10.1664 17.62 10.5564 17.41 10.7764 17.07C11.1364 16.6 18.0064 8.41004 18.0064 8.41004C18.9064 7.49004 17.8164 6.68004 17.0964 7.38004V7.39004Z"
                                  fill="#339557"
                                />
                              </svg>
                            </span>
                            30 lượt thiết kế thử nghiệm
                          </li>
                          <li className="price-card--features--item">
                            <span className="green-check">
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M17.0964 7.39004L9.9364 14.3L8.0364 12.27C7.6864 11.94 7.1364 11.92 6.7364 12.2C6.3464 12.49 6.2364 13 6.4764 13.41L8.7264 17.07C8.9464 17.41 9.3264 17.62 9.7564 17.62C10.1664 17.62 10.5564 17.41 10.7764 17.07C11.1364 16.6 18.0064 8.41004 18.0064 8.41004C18.9064 7.49004 17.8164 6.68004 17.0964 7.38004V7.39004Z"
                                  fill="#339557"
                                />
                              </svg>
                            </span>
                            Gồm tất cả trong gói Cơ bản
                          </li>
                        </ul>
                      </div>
                    </div> */}
                    <div className="price-card price-card--hero">
                      <div className="price-card--hero-text">{t('pricing.popular')}</div>
                      <div className="price-card--header">
                        <h4>Cơ bản</h4>
                      </div>
                      <div className="price-card--price">
                        <div className="price-card--price-text">
                          <div className="price-card--price-number toggle-price-content odometer">
                            {/* <span className="price-card--price-title-after">{t('pricing.currency')}</span> */}
                            299k
                            {/* {pro.toLocaleString('en-US', { minimumFractionDigits: 3 })} */}
                            {/* <span className="price-card--price-number-after">{t('pricing.perMonth')}</span> */}
                          </div>
                        </div>
                        <div className="price-card--price-conditions">
                          <div className="price-card--onboarding-fee toggle-price-content">400 thiết kế sử dụng trọn đời</div>
                        </div>
                      </div>
                      <div className="price-card--cta" onClick={e => onSub(e, CHECKOUT_100CREDITS)}>
                        <button className="price-card--cta--button upgrade-button">{t('button.subscribe')}</button>
                      </div>
                      <div className="price-card--features">
                        <ul className="price-card--features--list">
                          <li className="price-card--features--item">
                            <span className="green-check">
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M17.0964 7.39004L9.9364 14.3L8.0364 12.27C7.6864 11.94 7.1364 11.92 6.7364 12.2C6.3464 12.49 6.2364 13 6.4764 13.41L8.7264 17.07C8.9464 17.41 9.3264 17.62 9.7564 17.62C10.1664 17.62 10.5564 17.41 10.7764 17.07C11.1364 16.6 18.0064 8.41004 18.0064 8.41004C18.9064 7.49004 17.8164 6.68004 17.0964 7.38004V7.39004Z"
                                  fill="#339557"
                                />
                              </svg>
                            </span>
                            100 lượt thiết kế với mọi phong cách
                          </li>
                          <li className="price-card--features--item">
                            <span className="green-check">
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M17.0964 7.39004L9.9364 14.3L8.0364 12.27C7.6864 11.94 7.1364 11.92 6.7364 12.2C6.3464 12.49 6.2364 13 6.4764 13.41L8.7264 17.07C8.9464 17.41 9.3264 17.62 9.7564 17.62C10.1664 17.62 10.5564 17.41 10.7764 17.07C11.1364 16.6 18.0064 8.41004 18.0064 8.41004C18.9064 7.49004 17.8164 6.68004 17.0964 7.38004V7.39004Z"
                                  fill="#339557"
                                />
                              </svg>
                            </span>
                            Thay đổi thiết kế theo chỉ định
                          </li>
                          <li className="price-card--features--item">
                            <span className="green-check">
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M17.0964 7.39004L9.9364 14.3L8.0364 12.27C7.6864 11.94 7.1364 11.92 6.7364 12.2C6.3464 12.49 6.2364 13 6.4764 13.41L8.7264 17.07C8.9464 17.41 9.3264 17.62 9.7564 17.62C10.1664 17.62 10.5564 17.41 10.7764 17.07C11.1364 16.6 18.0064 8.41004 18.0064 8.41004C18.9064 7.49004 17.8164 6.68004 17.0964 7.38004V7.39004Z"
                                  fill="#339557"
                                />
                              </svg>
                            </span>
                            Tư vấn và thi công thiết kế
                          </li>
                          <li className="price-card--features--item">
                            <span className="green-check">
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M17.0964 7.39004L9.9364 14.3L8.0364 12.27C7.6864 11.94 7.1364 11.92 6.7364 12.2C6.3464 12.49 6.2364 13 6.4764 13.41L8.7264 17.07C8.9464 17.41 9.3264 17.62 9.7564 17.62C10.1664 17.62 10.5564 17.41 10.7764 17.07C11.1364 16.6 18.0064 8.41004 18.0064 8.41004C18.9064 7.49004 17.8164 6.68004 17.0964 7.38004V7.39004Z"
                                  fill="#339557"
                                />
                              </svg>
                            </span>
                            Nâng cấp độ phân giải cao
                          </li>
                          <li className="price-card--features--item">
                            <span className="green-check">
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M17.0964 7.39004L9.9364 14.3L8.0364 12.27C7.6864 11.94 7.1364 11.92 6.7364 12.2C6.3464 12.49 6.2364 13 6.4764 13.41L8.7264 17.07C8.9464 17.41 9.3264 17.62 9.7564 17.62C10.1664 17.62 10.5564 17.41 10.7764 17.07C11.1364 16.6 18.0064 8.41004 18.0064 8.41004C18.9064 7.49004 17.8164 6.68004 17.0964 7.38004V7.39004Z"
                                  fill="#339557"
                                />
                              </svg>
                            </span>
                            Tải xuống mọi thiết kế
                          </li>
                          <li className="price-card--features--item">
                            <span className="green-check">
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M17.0964 7.39004L9.9364 14.3L8.0364 12.27C7.6864 11.94 7.1364 11.92 6.7364 12.2C6.3464 12.49 6.2364 13 6.4764 13.41L8.7264 17.07C8.9464 17.41 9.3264 17.62 9.7564 17.62C10.1664 17.62 10.5564 17.41 10.7764 17.07C11.1364 16.6 18.0064 8.41004 18.0064 8.41004C18.9064 7.49004 17.8164 6.68004 17.0964 7.38004V7.39004Z"
                                  fill="#339557"
                                />
                              </svg>
                            </span>
                            Sử dụng hình ảnh thương mại
                          </li>
                          <li className="price-card--features--item">
                            <span className="green-check">
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M17.0964 7.39004L9.9364 14.3L8.0364 12.27C7.6864 11.94 7.1364 11.92 6.7364 12.2C6.3464 12.49 6.2364 13 6.4764 13.41L8.7264 17.07C8.9464 17.41 9.3264 17.62 9.7564 17.62C10.1664 17.62 10.5564 17.41 10.7764 17.07C11.1364 16.6 18.0064 8.41004 18.0064 8.41004C18.9064 7.49004 17.8164 6.68004 17.0964 7.38004V7.39004Z"
                                  fill="#339557"
                                />
                              </svg>
                            </span>
                            Truy cập mọi tính năng mới
                          </li>
                          <li className="price-card--features--item">
                            <span className="green-check">
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M17.0964 7.39004L9.9364 14.3L8.0364 12.27C7.6864 11.94 7.1364 11.92 6.7364 12.2C6.3464 12.49 6.2364 13 6.4764 13.41L8.7264 17.07C8.9464 17.41 9.3264 17.62 9.7564 17.62C10.1664 17.62 10.5564 17.41 10.7764 17.07C11.1364 16.6 18.0064 8.41004 18.0064 8.41004C18.9064 7.49004 17.8164 6.68004 17.0964 7.38004V7.39004Z"
                                  fill="#339557"
                                />
                              </svg>
                            </span>
                            Tham gia giới thiệu nhận thưởng
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="price-card">
                      <div className="price-card--header">
                        <h4>Chuyên nghiệp</h4>
                      </div>
                      <div className="price-card--price">
                        <div className="price-card--price-text">
                          <div className="price-card--price-number toggle-price-content odometer">
                            {/* <span className="price-card--price-title-after">{t('pricing.currency')}</span> */}
                            499k
                            {/* {basic.toLocaleString('en-US', { minimumFractionDigits: 3 })} */}
                            {/* <span className="price-card--price-number-after">{t('pricing.perMonth')}</span> */}
                          </div>
                        </div>
                        <div className="price-card--price-conditions">
                        <div className="price-card--onboarding-fee toggle-price-content">800 thiết kế sử dụng trọn đời</div>
                        </div>
                      </div>
                      <div className="price-card--cta" onClick={e => onSub(e, CHECKOUT_200CREDITS)}>
                        <button className="price-card--cta--button upgrade-button">{t('button.subscribe')}</button>
                      </div>
                      <div className="price-card--features">
                        <ul className="price-card--features--list">
                        <li className="price-card--features--item">
                            <span className="green-check">
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M17.0964 7.39004L9.9364 14.3L8.0364 12.27C7.6864 11.94 7.1364 11.92 6.7364 12.2C6.3464 12.49 6.2364 13 6.4764 13.41L8.7264 17.07C8.9464 17.41 9.3264 17.62 9.7564 17.62C10.1664 17.62 10.5564 17.41 10.7764 17.07C11.1364 16.6 18.0064 8.41004 18.0064 8.41004C18.9064 7.49004 17.8164 6.68004 17.0964 7.38004V7.39004Z"
                                  fill="#339557"
                                />
                              </svg>
                            </span>
                            200 lượt thiết kế với mọi phong cách
                          </li>
                          <li className="price-card--features--item">
                            <span className="green-check">
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M17.0964 7.39004L9.9364 14.3L8.0364 12.27C7.6864 11.94 7.1364 11.92 6.7364 12.2C6.3464 12.49 6.2364 13 6.4764 13.41L8.7264 17.07C8.9464 17.41 9.3264 17.62 9.7564 17.62C10.1664 17.62 10.5564 17.41 10.7764 17.07C11.1364 16.6 18.0064 8.41004 18.0064 8.41004C18.9064 7.49004 17.8164 6.68004 17.0964 7.38004V7.39004Z"
                                  fill="#339557"
                                />
                              </svg>
                            </span>
                            Gồm tất cả trong gói Cơ bản
                          </li>
                          <li className="price-card--features--item">
                            <span className="green-check">
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M17.0964 7.39004L9.9364 14.3L8.0364 12.27C7.6864 11.94 7.1364 11.92 6.7364 12.2C6.3464 12.49 6.2364 13 6.4764 13.41L8.7264 17.07C8.9464 17.41 9.3264 17.62 9.7564 17.62C10.1664 17.62 10.5564 17.41 10.7764 17.07C11.1364 16.6 18.0064 8.41004 18.0064 8.41004C18.9064 7.49004 17.8164 6.68004 17.0964 7.38004V7.39004Z"
                                  fill="#339557"
                                />
                              </svg>
                            </span>
                            Tiết kiệm chi phí hơn
                          </li>
                          <li className="price-card--features--item">
                            <span className="green-check">
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M17.0964 7.39004L9.9364 14.3L8.0364 12.27C7.6864 11.94 7.1364 11.92 6.7364 12.2C6.3464 12.49 6.2364 13 6.4764 13.41L8.7264 17.07C8.9464 17.41 9.3264 17.62 9.7564 17.62C10.1664 17.62 10.5564 17.41 10.7764 17.07C11.1364 16.6 18.0064 8.41004 18.0064 8.41004C18.9064 7.49004 17.8164 6.68004 17.0964 7.38004V7.39004Z"
                                  fill="#339557"
                                />
                              </svg>
                            </span>
                            Phù hợp với Doanh nghiệp, Đại lý
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="mt-8">
                  <h2 className="mx-auto relative z-[10] t-50 text-white mb-5 md:mb-6 font-bold text-center mx-auto md:text-center">
                    Thiết kế nổi bật
                  </h2>
                  <div className="rnftg-h-full rnftg-w-full">
                    <div className="rnftg-h-full dark:rnftg-bg-gray-900">
                      <div className="rnftg-flex rnftg-flex-col pd-2">
                        <div className="rnftg-grid rnftg-gap-6 rnftg-grid-flow-row rnftg-grid-cols-1 md:rnftg-grid-cols-2 lg:rnftg-grid-cols-3 xl:rnftg-grid-cols-3">
                          <article className="rnftg-item rnftg-rounded-2xl dark:rnftg-bg-gray-800 rnftg-shadow-lg hover:rnftg-shadow-xl rnftg-transition rnftg-duration-300">
                            <div className="rnftg-item__img-wrapper">
                              <div className="result">
                                <video
                                  className="banner-video"
                                  autoPlay
                                  loop
                                  style={{
                                    backgroundImage:
                                      'url("https://cdn.discordapp.com/attachments/1105800665105711166/1135478927020793856/Contemporary-neoclassic.webp")',
                                  }}
                                  muted
                                  playsInline
                                  data-wf-ignore="true"
                                  dataobjectfit="cover"
                                >
                                  <source
                                    src="https://cdn.discordapp.com/attachments/1105800665105711166/1128908395513139302/Contemporary-neoclassic.mp4"
                                    data-wf-ignore="true"
                                  />
                                </video>
                                <div className="prompt-label">Thiết Kế Nội Thất Phòng Khách Tân Cổ Điển Đương Đại</div>
                              </div>
                            </div>
                          </article>
                          <article className="rnftg-item rnftg-rounded-2xl dark:rnftg-bg-gray-800 rnftg-shadow-lg hover:rnftg-shadow-xl rnftg-transition rnftg-duration-300">
                            <div className="rnftg-item__img-wrapper">
                              <div className="result">
                                <video
                                  className="banner-video "
                                  autoPlay
                                  loop
                                  style={{
                                    backgroundImage:
                                      'url("https://cdn.discordapp.com/attachments/1105800665105711166/1135479511857758248/Colorful-modern-bedroom.webp")',
                                  }}
                                  muted
                                  playsInline
                                  data-wf-ignore="true"
                                  dataobjectfit="cover"
                                >
                                  <source
                                    src="https://cdn.discordapp.com/attachments/1105800665105711166/1128908281797156864/Colorful-modern-bedroom.mp4"
                                    data-wf-ignore="true"
                                  />
                                </video>
                                <div className="prompt-label">Thiết kế nội thất phòng ngủ đầy màu sắc</div>
                              </div>
                            </div>
                          </article>
                          <article className="rnftg-item rnftg-rounded-2xl dark:rnftg-bg-gray-800 rnftg-shadow-lg hover:rnftg-shadow-xl rnftg-transition rnftg-duration-300">
                            <div className="rnftg-item__img-wrapper">
                              <div className="result">
                                <video
                                  className="banner-video "
                                  autoPlay
                                  loop
                                  style={{
                                    backgroundImage:
                                      'url("https://cdn.discordapp.com/attachments/1105800665105711166/1135480095608414238/European-dinning.webp")',
                                  }}
                                  muted
                                  playsInline
                                  data-wf-ignore="true"
                                  dataobjectfit="cover"
                                >
                                  <source
                                    src="https://cdn.discordapp.com/attachments/1105800665105711166/1128896433756577802/European-dinning.mp4"
                                    data-wf-ignore="true"
                                  />
                                </video>
                                <div className="prompt-label">Thiết Kế Nội Thất Phòng Ăn Châu Âu</div>
                              </div>
                            </div>
                          </article>
                          <article className="rnftg-item rnftg-rounded-2xl dark:rnftg-bg-gray-800 rnftg-shadow-lg hover:rnftg-shadow-xl rnftg-transition rnftg-duration-300">
                            <div className="rnftg-item__img-wrapper">
                              <div className="result">
                                <video
                                  className="banner-video "
                                  autoPlay
                                  loop
                                  style={{
                                    backgroundImage:
                                      'url("https://cdn.discordapp.com/attachments/1105800665105711166/1135480745096401016/exterior-modern.webp")',
                                  }}
                                  muted
                                  playsInline
                                  data-wf-ignore="true"
                                  dataobjectfit="cover"
                                >
                                  <source
                                    src="https://cdn.discordapp.com/attachments/1105800665105711166/1128907002186309743/exterior-modern.mp4"
                                    data-wf-ignore="true"
                                  />
                                </video>
                                <div className="prompt-label">Thiết Kế Ngoại Thất Phong Cách Hiện Đại</div>
                              </div>
                            </div>
                          </article>
                          <article className="rnftg-item rnftg-rounded-2xl dark:rnftg-bg-gray-800 rnftg-shadow-lg hover:rnftg-shadow-xl rnftg-transition rnftg-duration-300">
                            <div className="rnftg-item__img-wrapper">
                              <div className="result">
                                <video
                                  className="banner-video "
                                  autoPlay
                                  loop
                                  style={{
                                    backgroundImage:
                                      'url("https://cdn.discordapp.com/attachments/1105800665105711166/1135477593970327562/Beachcottage-hotelroom.webp")',
                                  }}
                                  muted
                                  playsInline
                                  data-wf-ignore="true"
                                  dataobjectfit="cover"
                                >
                                  <source
                                    src="https://cdn.discordapp.com/attachments/1105800665105711166/1128920036074070097/Beachcottage-hotelroom.mp4"
                                    data-wf-ignore="true"
                                  />
                                </video>
                                <div className="prompt-label">Thiết kế nội thất phòng khách sạn Beach Cottage</div>
                              </div>
                            </div>
                          </article>
                          <article className="rnftg-item rnftg-rounded-2xl dark:rnftg-bg-gray-800 rnftg-shadow-lg hover:rnftg-shadow-xl rnftg-transition rnftg-duration-300">
                            <div className="rnftg-item__img-wrapper">
                              <div className="result">
                                <video
                                  className="banner-video "
                                  autoPlay
                                  loop
                                  style={{
                                    backgroundImage:
                                      'url("https://cdn.discordapp.com/attachments/1105800665105711166/1135481452021174322/wedding.webp")',
                                  }}
                                  muted
                                  playsInline
                                  data-wf-ignore="true"
                                  dataobjectfit="cover"
                                >
                                  <source
                                    src="https://cdn.discordapp.com/attachments/1105800665105711166/1128926758448660480/wedding.mp4"
                                    data-wf-ignore="true"
                                  />
                                </video>
                                <div className="prompt-label">Thiết Kế Phòng Cưới</div>
                              </div>
                            </div>
                          </article>
                          <article className="rnftg-item rnftg-rounded-2xl dark:rnftg-bg-gray-800 rnftg-shadow-lg hover:rnftg-shadow-xl rnftg-transition rnftg-duration-300">
                            <div className="rnftg-item__img-wrapper">
                              <div className="result">
                                <img
                                  className="banner-video "
                                  src='https://cdn.discordapp.com/attachments/1105800665105711166/1146666925884178432/noithatai-249874451.webp'
                                />
                                <div className="prompt-label">Thiết Kế Tiệm Nail Hồng Nóng Bỏng</div>
                              </div>
                            </div>
                          </article>
                          <article className="rnftg-item rnftg-rounded-2xl dark:rnftg-bg-gray-800 rnftg-shadow-lg hover:rnftg-shadow-xl rnftg-transition rnftg-duration-300">
                            <div className="rnftg-item__img-wrapper">
                              <div className="result">
                                <img
                                  className="banner-video "
                                  src='https://cdn.discordapp.com/attachments/1105800665105711166/1146666925523488838/noithatai-151137565.webp'
                                />
                                <div className="prompt-label">Thiết Kế Tiệm Nail Hồng Nhẹ</div>
                              </div>
                            </div>
                          </article>
                          <article className="rnftg-item rnftg-rounded-2xl dark:rnftg-bg-gray-800 rnftg-shadow-lg hover:rnftg-shadow-xl rnftg-transition rnftg-duration-300">
                            <div className="rnftg-item__img-wrapper">
                              <div className="result">
                                <img
                                  className="banner-video "
                                  src='https://cdn.discordapp.com/attachments/1105800665105711166/1146666926232317962/noithatai-817557023.webp'
                                />
                                <div className="prompt-label">Thiết Kế Tiệm Nail Hiện Đại</div>
                              </div>
                            </div>
                          </article>
                          <article className="rnftg-item rnftg-rounded-2xl dark:rnftg-bg-gray-800 rnftg-shadow-lg hover:rnftg-shadow-xl rnftg-transition rnftg-duration-300">
                            <div className="rnftg-item__img-wrapper">
                              <div className="result">
                                <img
                                  className="banner-video "
                                  src='https://cdn.discordapp.com/attachments/1105800665105711166/1146655374418661406/noithatai-386897638.webp'
                                />
                                <div className="prompt-label">Thiết Kế Sảnh Khách Sạn Phong Cách Sắc Màu</div>
                              </div>
                            </div>
                          </article>
                          <article className="rnftg-item rnftg-rounded-2xl dark:rnftg-bg-gray-800 rnftg-shadow-lg hover:rnftg-shadow-xl rnftg-transition rnftg-duration-300">
                            <div className="rnftg-item__img-wrapper">
                              <div className="result">
                                <img
                                  className="banner-video "
                                  src='https://cdn.discordapp.com/attachments/1105800665105711166/1146656850843017276/noithatai-523490229.webp'
                                />
                                <div className="prompt-label">Thiết Kế Sảnh Khách Sạn Phong Cách Tân Cổ Điển</div>
                              </div>
                            </div>
                          </article>
                          <article className="rnftg-item rnftg-rounded-2xl dark:rnftg-bg-gray-800 rnftg-shadow-lg hover:rnftg-shadow-xl rnftg-transition rnftg-duration-300">
                            <div className="rnftg-item__img-wrapper">
                              <div className="result">
                                <img
                                  className="banner-video "
                                  src='https://cdn.discordapp.com/attachments/1105800665105711166/1146662090472431717/noithatai-734033259.webp'
                                />
                                <div className="prompt-label">Thiết Kế Phòng Ăn Hiện Đại</div>
                              </div>
                            </div>
                          </article>
                          <article className="rnftg-item rnftg-rounded-2xl dark:rnftg-bg-gray-800 rnftg-shadow-lg hover:rnftg-shadow-xl rnftg-transition rnftg-duration-300">
                            <div className="rnftg-item__img-wrapper">
                              <div className="result">
                                <img
                                  className="banner-video "
                                  src='https://cdn.discordapp.com/attachments/1105800665105711166/1146662088568213535/noithatai-392451777.webp'
                                />
                                <div className="prompt-label">Thiết Kế Phòng Làm Việc Chuyên Nghiệp</div>
                              </div>
                            </div>
                          </article>
                          <article className="rnftg-item rnftg-rounded-2xl dark:rnftg-bg-gray-800 rnftg-shadow-lg hover:rnftg-shadow-xl rnftg-transition rnftg-duration-300">
                            <div className="rnftg-item__img-wrapper">
                              <div className="result">
                                <img
                                  className="banner-video "
                                  src='https://cdn.discordapp.com/attachments/1105800665105711166/1146662089021202462/noithatai-459740649.webp'
                                />
                                <div className="prompt-label">Thiết Kế Phòng Ngủ Trang Trí Trung Thu</div>
                              </div>
                            </div>
                          </article>
                          <article className="rnftg-item rnftg-rounded-2xl dark:rnftg-bg-gray-800 rnftg-shadow-lg hover:rnftg-shadow-xl rnftg-transition rnftg-duration-300">
                            <div className="rnftg-item__img-wrapper">
                              <div className="result">
                                <img
                                  className="banner-video "
                                  src='https://cdn.discordapp.com/attachments/1105800665105711166/1146662088161382520/noithatai-345823113.webp'
                                />
                                <div className="prompt-label">Thiết Kế Phòng Ngủ Tân Nghệ Thuật</div>
                              </div>
                            </div>
                          </article>
                          <article className="rnftg-item rnftg-rounded-2xl dark:rnftg-bg-gray-800 rnftg-shadow-lg hover:rnftg-shadow-xl rnftg-transition rnftg-duration-300">
                            <div className="rnftg-item__img-wrapper">
                              <div className="result">
                                <img
                                  className="banner-video "
                                  src='https://cdn.discordapp.com/attachments/1105800665105711166/1146676711258341446/out-2.webp'
                                />
                                <div className="prompt-label">Thiết Kế Cửa Hàng Thời Trang Chuyên Nghiệp</div>
                              </div>
                            </div>
                          </article>
                          <article className="rnftg-item rnftg-rounded-2xl dark:rnftg-bg-gray-800 rnftg-shadow-lg hover:rnftg-shadow-xl rnftg-transition rnftg-duration-300">
                            <div className="rnftg-item__img-wrapper">
                              <div className="result">
                                <img
                                  className="banner-video "
                                  src='https://cdn.discordapp.com/attachments/1105800665105711166/1146676710675337236/noithatai-248816652.webp'
                                />
                                <div className="prompt-label">Thiết Kế Cửa Hàng Thời Trang Tối Giản</div>
                              </div>
                            </div>
                          </article>
                          <article className="rnftg-item rnftg-rounded-2xl dark:rnftg-bg-gray-800 rnftg-shadow-lg hover:rnftg-shadow-xl rnftg-transition rnftg-duration-300">
                            <div className="rnftg-item__img-wrapper">
                              <div className="result">
                                <img
                                  className="banner-video "
                                  src='https://cdn.discordapp.com/attachments/1105800665105711166/1146676710293647390/noithatai-162565191.webp'
                                />
                                <div className="prompt-label">Thiết Kế Cửa Hàng Đồ Bảo Hộ</div>
                              </div>
                            </div>
                          </article>
                          <article className="rnftg-item rnftg-rounded-2xl dark:rnftg-bg-gray-800 rnftg-shadow-lg hover:rnftg-shadow-xl rnftg-transition rnftg-duration-300">
                            <div className="rnftg-item__img-wrapper">
                              <div className="result">
                                <img
                                  className="banner-video "
                                  src='https://cdn.discordapp.com/attachments/1105800665105711166/1146674901596508200/noithatai-973856607.webp'
                                />
                                <div className="prompt-label">Thiết Kế Nhà Việt Xưa từ thực tế</div>
                              </div>
                            </div>
                          </article>
                          <article className="rnftg-item rnftg-rounded-2xl dark:rnftg-bg-gray-800 rnftg-shadow-lg hover:rnftg-shadow-xl rnftg-transition rnftg-duration-300">
                            <div className="rnftg-item__img-wrapper">
                              <div className="result">
                                <img
                                  className="banner-video "
                                  src='https://cdn.discordapp.com/attachments/1105800665105711166/1146674900728299550/noithatai-179496130.webp'
                                />
                                <div className="prompt-label">Thiết Kế Nhà Phong Cách Nhật Bản từ thực tế</div>
                              </div>
                            </div>
                          </article>
                          <article className="rnftg-item rnftg-rounded-2xl dark:rnftg-bg-gray-800 rnftg-shadow-lg hover:rnftg-shadow-xl rnftg-transition rnftg-duration-300">
                            <div className="rnftg-item__img-wrapper">
                              <div className="result">
                                <img
                                  className="banner-video "
                                  src='https://cdn.discordapp.com/attachments/1105800665105711166/1146674901135130684/noithatai-529271032.webp'
                                />
                                <div className="prompt-label">Thiết Kế Nhà Phong Cách Trung Hoa từ thực tế</div>
                              </div>
                            </div>
                          </article>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
                <div className="mt-8">
                  <FAQ />
                </div>
              </>
            )}
            {showPaymentSelection && (
              <div className="banks-container">
                <div className="price-card">
                  <div className="price-card--header">
                    <h4>Chọn phương thức thanh toán</h4>
                    <span style={{color: "#e2e2e2", fontSize: "11px"}}>Không phải đăng nhập bất kì đâu rất an toàn</span>
                  </div>
                  <div className="modal-content" role="document">
                    <div className="modal-body">
                      <div id="accordion-controlled-example" role="tablist" className="panel-group">
                        <div style={{ cursor: 'pointer' }} className="panel panel-default" onClick={() => handleToggleClick(0)}>
                          <div className="panel-heading">
                            <div className="panel-title">
                              <a>
                                <div className="bank-option">
                                  <img
                                    src="images/bank.webp"
                                    className="option-icon img-rounded"
                                  />
                                  <div className="option-title">
                                    <p>
                                      <span>Chuyển khoản qua ngân hàng</span>
                                      {/* <span className="cl-red">(Khuyến nghị )</span> */}
                                    </p>
                                    <p>
                                      <span>Hỗ trợ từ 8:30 đến 22:00 hàng ngày</span>
                                    </p>
                                  </div>
                                </div>
                              </a>
                            </div>
                          </div>
                          <div className={`panel-collapse collapse ${isExpanded[0] ? 'in' : ''}`}>
                            <div className="option-content panel-body">
                              <div>
                                <p>Để hoàn tất, bạn vui lòng chuyển khoản theo nội dung sau: </p>
                                <div style={{marginLeft: "15px"}}>
                                <p>
                                  Vietcombank (VCB): <span className="high-light-color">0441000810601</span>
                                </p>
                                <p>
                                  Chủ tài khoản: <span className="high-light-color">Phùng Thanh An</span>
                                </p>
                                <p>
                                  Số tiền: <span className="high-light-color">{price} đ</span>
                                </p>
                                <p>
                                  Nội dung chuyển khoản: <span className="high-light-color">{userData.monthlyBasicCode}</span>
                                </p>
                                </div>
                                {/* <hr />
                                <p>Liên hệ của bạn giúp chúng tôi hỗ trợ thanh toán khi cần thiết:</p>
                                <div className="price-card--cta">
                                  <input className="input-phone" phone-input ng-model="phoneVal" type='number' value={phoneNumber} onChange={handleChangePhoneNumber} placeholder='Nhập số điện thoại'/>
                                </div> */}
                                <hr />
                                <p>Sau khi chuyển khoản xong, vui lòng chọn xác nhận đã thanh toán ở đây.</p>
                                <div className="price-card--cta" onClick={e => onConfirm(e, PAYMENT_METHOD.VIETCOMBANK)}>
                                  <button className="price-card--cta--button upgrade-button" style={{ marginTop: '5px' }}>
                                    Tôi xác nhận đã thanh toán
                                  </button>
                                </div>
                              </div>
                              <hr />
                              <div>
                                <div className="sub-info">
                                  <p>
                                    <span style={{ color: 'red' }}>*</span>
                                    <span> Nếu bạn cần sự hỗ trợ:</span>{' '}
                                    <a onClick={showSupport} style={{ cursor: 'pointer', fontWeight: '700' }}>
                                      <span style={{color: "#4df5a6"}}>CHAT NGAY</span>
                                    </a>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div style={{ cursor: 'pointer' }} className="panel panel-default" onClick={() => handleToggleClick(1)}>
                          <div className="panel-heading">
                            <div className="panel-title">
                              <a>
                                <div className="bank-option">
                                  <img
                                    src="images/qr_code.webp"
                                    className="option-icon img-rounded"
                                    style={{ background: 'white' }}
                                  />
                                  <div className="option-title">
                                    <p>
                                      <span>Thanh toán qua QR Code</span>
                                    </p>
                                    <p>{/* <span>Phí 3% giá trị nạp</span> */}</p>
                                  </div>
                                </div>
                              </a>
                            </div>
                          </div>
                          <div className={`panel-collapse collapse ${isExpanded[1] ? 'in' : ''}`}>
                            <div className="option-content panel-body">
                              <div>
                                <p>1. Đăng nhập ứng dụng hỗ trợ thanh toán QR Code</p>
                                <p>2. Quét mã thanh toán/ tải mã về sau đó quét mã</p>
                                <p>3. Kiểm tra thông tin đơn hàng</p>
                                <div style={{marginLeft: "15px"}}>
                                  <p>
                                    Vietcombank (VCB): <span className="high-light-color">0441000810601</span>
                                  </p>
                                  <p>
                                    Chủ tài khoản: <span className="high-light-color">Phùng Thanh An</span>
                                  </p>
                                  <p>
                                    Số tiền: <span className="high-light-color">{price} đ</span>
                                  </p>
                                </div>
                                <p>
                                  4. Nhập nội dung chuyển khoản: <span className="high-light-color">{userData.monthlyBasicCode}</span>
                                </p>
                                <hr />
                                <div className="price-card--cta">
                                  <img src={`images/vcb_${price}.webp`} width={150} />
                                </div>
                                {/* <hr />
                                <p>Liên hệ của bạn giúp chúng tôi hỗ trợ thanh toán khi cần thiết:</p>
                                <div className="price-card--cta">
                                  <input className="input-phone" phone-input ng-model="phoneVal" type='number' value={phoneNumber} onChange={handleChangePhoneNumber} placeholder='Nhập số điện thoại'/>
                                </div> */}
                                <hr />
                                <p>Sau khi thanh toán xong, vui lòng chọn xác nhận đã thanh toán ở đây.</p>
                                <div className="price-card--cta" onClick={e => onConfirm(e, PAYMENT_METHOD.VIETCOMBANK)}>
                                  <button className="price-card--cta--button upgrade-button" style={{ marginTop: '5px' }}>
                                    Tôi xác nhận đã thanh toán
                                  </button>
                                </div>
                              </div>
                              <hr />
                              <div>
                                <div className="sub-info">
                                  <p>
                                    <span style={{ color: 'red' }}>*</span>
                                    <span> Nếu bạn cần sự hỗ trợ:</span>{' '}
                                    <a onClick={showSupport} style={{ cursor: 'pointer', fontWeight: '700' }}>
                                      <span style={{color: "#4df5a6"}}>CHAT NGAY</span>
                                    </a>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div style={{ cursor: 'pointer' }} className="panel panel-default" onClick={() => handleToggleClick(2)}>
                          <div className="panel-heading">
                            <div className="panel-title">
                              <a>
                                <div className="bank-option">
                                  <img
                                    src="images/momo.webp"
                                    alt="PD"
                                    className="option-icon img-rounded"
                                  />
                                  <div className="option-title">
                                    <p>
                                      <span>Thanh toán qua ví điện tử Momo</span>
                                    </p>
                                    <p>{/* <span>Phí 4% giá trị nạp</span> */}</p>
                                  </div>
                                </div>
                              </a>
                            </div>
                          </div>
                          <div className={`panel-collapse collapse ${isExpanded[2] ? 'in' : ''}`}>
                            <div className="option-content panel-body">
                              <div>
                                <p>1. Đăng nhập ứng dụng MOMO</p>
                                <p>2. Quét mã thanh toán</p>
                                <p>3. Kiểm tra thông tin đơn hàng</p>
                                <div style={{marginLeft: "15px"}}>
                                  <p>
                                    MOMO: <span className="high-light-color">0973898494</span>
                                  </p>
                                  <p>
                                    Chủ tài khoản: <span className="high-light-color">Phùng Thanh An</span>
                                  </p>
                                  <p>
                                    Số tiền: <span className="high-light-color">{price} đ</span>
                                  </p>
                                </div>
                                <p>
                                  4. Nhập nội dung chuyển khoản: <span className="high-light-color">{userData.monthlyBasicCode}</span>
                                </p>
                                <hr />
                                <div className="price-card--cta">
                                  <img src={`images/momo_${price}.webp`} width={150} />
                                </div>
                                {/* <hr />
                                <p>Hoặc chuyển tiền với thông tin đơn hàng ở trên tới MOMO: <span className="high-light-color">0973898494</span></p> */}
                                {/* <hr />
                                <p>Liên hệ của bạn giúp chúng tôi hỗ trợ thanh toán khi cần thiết:</p> */}
                                {/* <div className="price-card--cta">
                                  <input className="input-phone" phone-input ng-model="phoneVal" type='number' value={phoneNumber} onChange={handleChangePhoneNumber} placeholder='Nhập số điện thoại'/>
                                </div> */}
                                <hr />
                                <p>Sau khi thanh toán xong, vui lòng chọn xác nhận đã thanh toán ở đây.</p>
                                <div className="price-card--cta" onClick={e => onConfirm(e, PAYMENT_METHOD.MOMO)}>
                                  <button className="price-card--cta--button upgrade-button" style={{ marginTop: '5px' }}>
                                    Tôi xác nhận đã thanh toán
                                  </button>
                                </div>
                              </div>
                              <hr />
                              <div>
                                <div className="sub-info">
                                  <p>
                                    <span style={{ color: 'red' }}>*</span>
                                    <span> Nếu bạn cần sự hỗ trợ:</span>{' '}
                                    <a onClick={showSupport} style={{ cursor: 'pointer', fontWeight: '700' }}>
                                      <span style={{color: "#4df5a6"}}>CHAT NGAY</span>
                                    </a>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {showPurchasingScreen && (
              <div>
                <div id="pricing-cards">
                  <div id="container-purchasing">
                    <div id="success-box">
                      <div className="face">
                        <div className="eye" />
                        <div className="eye right" />
                        <div className="mouth happy" />
                      </div>
                      <div className="shadow scale" />
                      <div className="message">
                        <h1 className="alert">
                          <span onLoad={renderLove} className="thanks-mess hearts">
                            {' '}
                            {t('bill.afterPayment.thankyouMess')}{' '}
                          </span>
                        </h1>
                        <p>{t('bill.afterPayment.checking')}</p>
                        {/* <p>If you got problems.</p> */}
                        <hr/>
                        <p>Thông tin giao dịch</p>
                        <div style={{marginLeft: "15px"}}>
                        <p>
                          Vietcombank (VCB): <span className="high-light-color">0441000810601</span>
                        </p>
                        <p>
                          Chủ tài khoản: <span className="high-light-color">Phùng Thanh An</span>
                        </p>
                        <p>
                          Số tiền: <span className="high-light-color">{price} đ</span>
                        </p>
                        <p>
                          Nội dung chuyển khoản: <span className="high-light-color">{userData.monthlyBasicCode}</span>
                        </p>
                        </div>
                        <hr />
                        <p style={{ paddingBottom: '60px' }}>
                          <span style={{ color: 'red' }}>*</span>
                          <span> Nếu bạn cần sự hỗ trợ:</span>{' '}
                          <a onClick={showSupport} style={{ cursor: 'pointer', fontWeight: '700' }}>
                            <span style={{color: "#4df5a6"}}>CHAT NGAY</span>
                          </a>
                        </p>
                      </div>
                      {/* <button className="button-box"><h1 className="green">continue</h1></button> */}
                    </div>
                    <div></div>
                  </div>
                </div>
              </div>
            )}
          </section>
        </div>
      )}
    </>
  );
};

export default Pricing;
