const vi = {
    Basic: "Cơ Bản",
    Pro: "Pro",
    month: "tháng",
    year: "năm",
    button: {
        getStarted: 'Bắt đầu ngay',
        try: 'Sử dụng phiên bản mới ↗',
        viewall: 'Xem tất cả',
        basic: 'Cơ Bản',
        pro: 'Pro',
        generate: 'Bắt Đầu Thiết Kế',
        backHome: 'Trở Về Trang Chủ',
        googleSignin: 'Google',
        googleSignup: 'Google',
        emailSignin: 'Đăng nhập',
        emailSignup: 'Đăng ký',
        submit: 'Xác nhận',
        choose: 'Chọn',
        download: 'Tải xuống mẫu này',
        upgrade: 'Mua lượt thiết kế',
        subscribe: 'Mua gói này',
        upgradePro: 'Nâng cấp',
        confirmPayment: 'Xác nhận thanh toán xong'
    }
    ,
    header: {
        nav: {
            signIn: 'Đăng nhập',
            signUp: 'Đăng ký tài khoản',
            signOut: 'Đăng xuất',
            supportTitle: 'Hỗ trợ',
            supportContact: 'Liên hệ ngay',
            myFlowers: 'Thiết kế của tôi'
        }
    },
    landing: {
        title: 'Thiết kế nhà trong vài giây',
        input: 'Vui lòng cho tôi biết kiểu hoa muốn tạo?',
        inside: 'Không gian trong nhà',
        outside: 'Không gian khác'
    },
    showcase: {
        title: 'Hoa Mẫu',
        status: {
            pending: 'đang tạo',
            upscaling: 'đang nâng cấp',
            choosing: 'đang chọn',
            finished: 'hoàn thành'
        },
        mode: {
            pro: 'pro',
            basic: 'Cơ Bản'
        }
    },
    home: {
        title: 'Thiết kế của bạn',
        credits: 'lượt thiết kế',
        uploadTitle: 'Tải lên không gian của bạn',
        uploadPlaceHolder: 'Thả hình ảnh, chạm để chọn, hoặc chụp ảnh (tối đa 10MB)',
        roomTitle: 'Chọn loại phòng (29)',
        themeTitle: 'Chọn phong cách (34)',
        pro: {
            title: 'Sáng Tạo Với Mọi Loại Hoa',
            input: 'Miêu tả hoa của bạn...',
        },
        basic: {
            title: 'Sáng Tạo Không Giới Hạn Với Pro',
            chooseFlowerTitle: 'Hoa',
            flowers: 'Phòng khách',
            styles: 'Kiểu',
            decoration: 'Trang trí',
            events: 'Sự kiện',
            backgrounds: 'Nền'
        },
    },
    profile: {
        title: 'Thiết Kế Của Tôi',
        before: 'Trước -',
        after: 'Sau -'
    },
    terms: {
        title: 'Điều Khoản Và Điều Kiện',
        content: ''
    },
    privacy: {
        title: 'Chính Sách Bảo Mật',
        one: 'Quyền riêng tư của bạn rất quan trọng với chúng tôi. Chính sách của noithatai.com là tôn trọng quyền riêng tư của bạn đối với bất kỳ thông tin nào chúng tôi có thể thu thập từ bạn trên trang web của chúng tôi, noithatai.com và các trang web khác mà chúng tôi sở hữu và vận hành.',
        two: 'Chúng tôi chỉ yêu cầu thông tin cá nhân khi chúng tôi thực sự cần nó để cung cấp dịch vụ cho bạn. Chúng tôi thu thập nó bằng các phương tiện công bằng và hợp pháp, với sự hiểu biết và đồng ý của bạn. Chúng tôi cũng cho bạn biết lý do tại sao chúng tôi thu thập nó và nó sẽ được sử dụng như thế nào.',
        three: 'Chúng tôi chỉ lưu giữ thông tin đã thu thập trong thời gian cần thiết để cung cấp cho bạn dịch vụ mà bạn yêu cầu. Dữ liệu nào chúng tôi lưu trữ, chúng tôi sẽ bảo vệ bằng các biện pháp được chấp nhận về mặt thương mại để ngăn ngừa mất mát và trộm cắp, cũng như truy cập, tiết lộ, sao chép, sử dụng hoặc sửa đổi trái phép.',
        four: 'Chúng tôi không chia sẻ bất kỳ thông tin nhận dạng cá nhân nào một cách công khai hoặc với bên thứ ba, trừ khi luật pháp yêu cầu.',
        five: 'Trang web của chúng tôi có thể liên kết đến các trang bên ngoài không do chúng tôi điều hành. Xin lưu ý rằng chúng tôi không kiểm soát nội dung và thực tiễn của các trang web này và không thể chịu trách nhiệm hoặc trách nhiệm pháp lý đối với các chính sách bảo mật tương ứng của họ.',
        six: 'Bạn có thể từ chối yêu cầu của chúng tôi về thông tin cá nhân của bạn, với sự hiểu biết rằng chúng tôi có thể không cung cấp cho bạn một số dịch vụ mong muốn của bạn.',
        seven: 'Việc bạn tiếp tục sử dụng trang web của chúng tôi sẽ được coi là chấp nhận các thông lệ của chúng tôi về quyền riêng tư và thông tin cá nhân. Nếu bạn có bất kỳ câu hỏi nào về cách chúng tôi xử lý dữ liệu người dùng và thông tin cá nhân, vui lòng liên hệ với chúng tôi.',
        eight: 'Chính sách này có hiệu lực kể từ ngày 29 tháng 5 năm 2023.'
    },
    footer: {
        terms: 'Điều Khoản',
        privacy: 'Chính Sách Bảo Mật'
    },
    signin: {
        title: 'Đăng nhập',
        another: 'Hoặc với Email',
        emailPlaceHolder: 'Nhập email',
        passwordPlaceHolder: 'Nhập mật khẩu',
        rememberTitle: 'Nhớ cho lần sau',
        forgetPassword: 'Quên mật khẩu?',
        noAccount: 'Chưa tạo tài khoản?'
    },
    signup: {
        title: 'Đăng ký',
        another: 'Hoặc với Email',
        firstNamePlaceHolder: 'Tên',
        lastNamePlaceHolder: 'Họ',
        emailPlaceHolder: 'Nhập email',
        passwordPlaceHolder: 'Nhập mật khẩu',
        rememberTitle: 'Nhớ cho lần sau',
        forgetPassword: 'Quên mật khẩu?',
        gotAccount: 'Bạn đã có tài khoản?'
    },
    forgetPassword: {
        title: 'Lấy lại mật khẩu',
        content: 'Bạn sẽ nhận được email lấy lại mật khẩu',
        emailPlaceHolder: 'Vui lòng nhập Email',
    },
    pricing: {
        yearlySubscription: '1000 lượt',
        monthlySubscription: '100 lượt',
        saveDesk: 'Giảm 10%',
        saveMobile: 'Giảm 10% khi mua 1000 lượt',
        popular: 'Phổ biến nhất',
        basic: {
            title: 'Cơ Bản',
            one: '100 lượt thiết kế',
            oneDetail: '100 lượt thiết kế với bất kỳ mẫu nào',
            two: 'Tải xuống tất cả thiết kế',
            twoDetail: 'Không giới hạn lượt tải xuống',
            three: 'Sử dụng hình ảnh thương mại',
            threeDetail: 'Sử dụng hình ảnh thương mại bất kỳ đâu',
            four: 'Truy cập mọi tính năng mới',
            fourDetail: 'Sử dụng bất kì tính năng mới nào trong gói Cơ bản trong tương lai',
            five: 'Hỗ trợ chuyên nghiệp',
            fiveDetail: 'Hỗ trợ qua điện thoại, email và trò chuyện trên web, 9 giờ sáng - 9 giờ tối Thứ Hai-Chủ Nhật',
            six: 'Tải lên hình ảnh dưới 5MB',
            sixDetail: 'Hình ảnh tải lên sẽ giới hạn 5MB, khuyên dùng gói Pro với giới hạn 10MB',
            subscribed: 'Đã mua gói này',
            recommendTitle: 'Phù hợp với Cá nhân'
        },
        pro: {
            title: 'Pro',
            one: 'Tất cả tính năng có trong gói Cơ bản',
            oneDetail: 'Tải lên hình ảnh, Tải xuống tất cả thiết kế, Sử dụng hình ảnh thương mại, Truy cập mọi tính năng mới',
            two: 'Lưu trữ thiết kế không giới hạn',
            twoDetail: 'Tất cả thiết kế được lưu trữ an toàn và bảo mật trên Cloud, không giới hạn lưu trữ',
            three: 'Nâng độ phân giải của thiết kế',
            threeDetail: 'Sử dụng AI nâng độ phân giải cao nhất cho thiết kế',
            four: '100 lượt thiết kế',
            fourDetail: '100 lượt thiết kế với bất kỳ mẫu nào',
            five: 'Tải lên hình ảnh dưới 10MB',
            fiveDetail: 'Hình ảnh tải lên sẽ giới hạn 10MB',
            six: 'Download Your Images',
            sixDetail: 'Download Your Images',
            seven: 'Included All Next Features',
            sevenDetail: 'Included All Next Features',
            recommendTitle: 'Phù hợp với Doanh nghiệp/Đại lý'
        },
        currency: 'đ',
        perMonth: '/tháng',
        safePayment: 'Thanh toán bảo mật',
        qr: 'Hoặc sử dụng mã QR'
    },
    bill: {
        tittle: 'Thanh toán của bạn',
        name: 'Gói: ',
        credits: 'Số lượt thiết kế: ',
        duration: 'Thời hạn: ',
        price: 'Giá: ',
        discount: 'Giảm ',
        total: 'Tổng cộng: ',
        payment: {
            title: 'Phương thức thanh toán',
            internalATM: 'Chuyển khoản ngân hàng',
            momo: 'Ví MoMo',
            zalopay: 'Ví ZaloPay',
            vnpay: 'Ví VNPAY'
        },
        transfer: {
            internalATM: {
            title: 'Chuyển khoản',
            account: 'STK: 0441000810601',
            name: 'Chủ TK: PHUNG THANH AN',
            content: 'Nội dung: '
            },
            momo: {
                title: 'Chuyển Momo',
                account: 'SĐT: 0973898494',
                name: 'Tên: PHUNG THANH AN',
                content: 'Nội dung: '
            },
            zalopay: {
                title: 'Chuyển ZaloPay',
                account: 'SĐT: 0973898494',
                name: 'Tên: PHUNG THANH AN',
                content: 'Nội dung: '
            },
            vnpay: {
                title: 'Chuyển VNPAY',
                account: 'SĐT: 0973898494',
                name: 'Tên: PHUNG THANH AN',
                content: 'Nội dung: '
            }
        },
        notes: {
            title: 'LƯU Ý',
            one: 'Vui lòng kiểm tra chính xác THÔNG TIN và NỘI DUNG trước khi chuyển khoản',
            oneDetail: 'Chúng tôi sẽ không chịu trách nhiệm nếu bạn thực hiện sai giao dịch',
            two: 'Vui lòng liên hệ với chúng tôi nếu bạn gặp khó khăn trong quá trình thanh toán.',
            twoDetail: 'Chat với chúng tôi',
        },
        afterPayment: {
            thankyouMess: 'Cảm ơn Bạn!',
            checking: 'Giao dịch của bạn đang được xử lý vui lòng chờ trong giây lát'
        }
    },
    milestoneMessage: {
        first: 'Khách hàng hài lòng hơn',
        second: 'thiết kế đã được tạo'
    },
    tos: {
        one: '1. Giới thiệu',
        oneDetail: 'Bằng cách sử dụng noithatai.com, bạn xác nhận việc chấp nhận và đồng ý bị ràng buộc bởi các điều khoản và điều kiện này.',
        two: '2. Thỏa thuận với các Điều khoản và Điều kiện',
        twoDetail: 'Thỏa thuận này có hiệu lực kể từ ngày bạn sử dụng ứng dụng noithatai.com lần đầu tiên.',
        three: '3. Các tính năng Cơ bản và Pro',
        threeDetail: 'Bạn sẽ có thể sử dụng tất cả các tính năng Cơ bản và Pro sau khi đăng ký một gói trả phí cụ thể. Chi tiết về các tính năng cơ bản và chuyên nghiệp có sẵn trên trang giá cả.',
        four: '4. Chính sách hoàn tiền',
        fourDetail: 'Chúng tôi cho phép hoàn lại tiền cho các giao dịch bị thất bại trong vòng 14 ngày kể từ ngày mua. Liên hệ với bộ phận hỗ trợ để yêu cầu hoàn lại tiền.',
        five: '4. Sử dụng sản phẩm',
        fiveDetail: 'Bằng cách sử dụng noithatai.com, bạn đồng ý nhận các bản cập nhật sản phẩm quan trọng từ noithatai.com qua email được liên kết với tài khoản Google của bạn hoặc email bạn đã sử dụng để đăng ký tài khoản của mình. Bạn có thể chọn không nhận các bản cập nhật sản phẩm này bất kỳ lúc nào bằng cách nhấp vào liên kết "Hủy đăng ký" ở cuối mỗi email. Chúng tôi chỉ gửi các bản cập nhật sản phẩm quan trọng.',
        six: '5. Miễn trừ trách nhiệm',
        sixDetail: 'Không đảm bảo rằng noithatai.com sẽ đáp ứng yêu cầu của bạn hoặc hoạt động của nó sẽ không bị gián đoạn hoặc không có lỗi. Tất cả các bảo đảm hoặc điều kiện rõ ràng và ngụ ý không được nêu trong Thỏa thuận này (bao gồm nhưng không giới hạn, mất lợi nhuận, mất hoặc hỏng dữ liệu, gián đoạn kinh doanh hoặc mất hợp đồng), cho đến khi loại trừ hoặc từ chối trách nhiệm đó được cho phép theo luật hiện hành, được loại trừ và từ chối rõ ràng. Thỏa thuận này không ảnh hưởng đến các quyền theo luật định của bạn.',
        seven: '6. Bảo hành và Giới hạn trách nhiệm pháp lý',
        sevenDetail: 'noithatai.com không đưa ra bất kỳ bảo hành, đảm bảo hoặc điều khoản nào khác về chất lượng, tính phù hợp với mục đích hoặc mặt khác của phần mềm. noithatai.com sẽ không chịu trách nhiệm với bạn vì lý do của bất kỳ tuyên bố nào (trừ khi gian lận), hoặc bất kỳ bảo hành, điều kiện hoặc điều khoản ngụ ý nào, hoặc bất kỳ nghĩa vụ nào theo luật thông thường, đối với bất kỳ tổn thất lợi nhuận hoặc bất kỳ tổn thất gián tiếp, đặc biệt hoặc do hậu quả nào, thiệt hại, chi phí, phí tổn hoặc các khiếu nại khác (dù là do sơ suất của noithatai.com hay sơ suất của nhân viên hoặc đại lý của noithatai.com hoặc nguyên nhân khác) phát sinh từ hoặc liên quan đến việc cung cấp bất kỳ hàng hóa hoặc dịch vụ nào của noithatai.com. noithatai.com sẽ không chịu trách nhiệm hoặc bị coi là vi phạm hợp đồng do bất kỳ sự chậm trễ nào trong việc thực hiện hoặc không thực hiện bất kỳ nghĩa vụ nào của mình nếu sự chậm trễ hoặc không thực hiện được là do bất kỳ nguyên nhân nào nằm ngoài tầm kiểm soát hợp lý của noithatai.com. Bất kể các điều khoản trái ngược trong Thỏa thuận này, trong trường hợp noithatai.com được coi là chịu trách nhiệm pháp lý với bạn do vi phạm Thỏa thuận này, bạn đồng ý rằng trách nhiệm pháp lý của noithatai.com được giới hạn ở số tiền bạn thực sự trả cho các dịch vụ hoặc phần mềm của mình, số tiền này được tính toán dựa trên khoản này. Theo đây, bạn giải phóng noithatai.com khỏi bất kỳ và tất cả các nghĩa vụ, trách nhiệm pháp lý và khiếu nại vượt quá giới hạn này.',
        eight: '7. Điều khoản chung và luật',
        eightDetail: 'Bạn thừa nhận rằng không có mối quan hệ liên doanh, hợp tác, việc làm hoặc đại lý nào tồn tại giữa bạn và noithatai.com do bạn sử dụng các dịch vụ này. Bạn đồng ý không thể hiện mình là đại diện, đại lý hoặc nhân viên của noithatai.com. Bạn đồng ý rằng noithatai.com sẽ không chịu trách nhiệm pháp lý về bất kỳ đại diện, hành động hoặc thiếu sót nào trong hành động của bạn.'
    },
    message: {
        success: {
            redering: 'AI đang thiết kế, vui lòng chờ trong giây lát',
            rederingDone: 'AI đã tạo xong thiết kế',
            activated: 'Cảm ơn bạn! Chúc bạn tạo ra thật nhiều thiết kế đẹp và ưng ý',
        },
        error: {
            uploadPhoto: 'Vui lòng tải lên không gian của bạn',
            chooseRoom: 'Vui lòng chọn loại phòng',
            chooseTheme: 'Vui lòng chọn phong cách của phòng',
            fileOverBasic: 'Vui lòng tải lên file có dung lượng nhỏ hơn 5MB, hoặc nâng cấp Pro để tải file có dung lượng lớn',
            fileOverPro: 'Vui lòng tải lên file có dung lượng nhỏ hơn 10MB',
            wrongFile: 'File không hợp lệ, vui lòng thử lại file khác',
            busy: 'Kết nối bị gián đoạn, vui lòng thử lại'
        },
        warning: {
            runOutBasicCredits: 'Bạn đã dùng hết lượt của gói Cơ bản, vui lòng mua thêm lượt',
            runOutProCredits: 'Bạn đã dùng hết lượt của gói Pro, vui lòng mua thêm lượt',
        }
    }
};

export default vi;  