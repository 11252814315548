const LANDING = '/';
const SIGN_UP = '/signup';
const SIGN_IN = '/signin';
const HOME = '/app';
const FORGET_PASSWORD = '/forget-password';
const SIGN_OUT = 'signout';
const CAMPAIGN_DETAIL = '/campaign';
const MY_DASHBOARD = '/my-dashboard';
const MY_FLOWERS = 'my-designs';
const CREATORS = 'creators';
const LIVE_STREAMERS = 'live-streamers';
const TERMS_CONDITIONS = 'tos';
const PRIVACY = 'privacy';
const APP = '/app';
const AGENCIES= '/agencies'

export { LANDING, TERMS_CONDITIONS, PRIVACY, SIGN_UP, SIGN_IN, APP, HOME, FORGET_PASSWORD, SIGN_OUT, CAMPAIGN_DETAIL, MY_DASHBOARD, MY_FLOWERS, CREATORS, LIVE_STREAMERS, AGENCIES };
