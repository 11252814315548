const en = {
    Basic: "Basic",
    Pro: "Pro",
    month: "month",
    year: "year",
    button: {
        getStarted: 'Get Started',
        try: 'Get your ideas now',
        viewall: 'View All',
        basic: 'Basic',
        pro: 'Pro',
        generate: 'Render here',
        backHome: 'Back Home',
        googleSignin: 'Sign in with Google',
        googleSignup: 'Sign up with Google',
        emailSignin: 'Sign in with Email',
        emailSignup: 'Sign up with Email',
        submit: 'Submit',
        choose: 'Choose',
        download: 'Download photo',
        upgrade: 'Buy credits as you go',
        subscribe: 'Buy it',
        upgradePro: 'Upgrade',
        confirmPayment: 'Confirm'
    }
    ,
    header: {
        nav: {
            signIn: 'Sign In',
            signUp: 'Sign Up',
            signOut: 'Sign Out',
            supportTitle: 'Support & Help',
            supportContact: 'Contact Support',
            myFlowers: 'My designs'
        }
    },
    landing: {
        title: 'Redesign your home with AI',
        input: 'How can I help you?',
        livingRoom: 'Living room',
        bedroom: 'Bedroom'
    },
    showcase: {
        title: 'Sample Flowers',
        status: {
            pending: 'pending',
            upscaling: 'upscaling',
            choosing: 'choosing',
            finished: 'finished'
        },
        mode: {
            pro: 'pro',
            basic: 'basic'
        }
    },
    home: {
        title: 'Latest renders',
        credits: 'credits',
        uploadTitle: 'Upload a photo of your room',
        roomTitle: 'Select Room Type',
        themeTitle: 'Select Room Themes',
        uploadPlaceHolder: 'Drop an image, click to select, take a photo (Limit 10MB)',
        pro: {
            title: 'Generate Your Designs With Me',
            input: 'Describe your requirements here',
        },
        basic: {
            title: 'More Magic With Pro Mode',
            chooseFlowerTitle: 'Flowers',
            flowers: 'Living room',
            styles: 'Styles',
            decoration: 'Decoration',
            events: 'Events',
            backgrounds: 'Backgrounds'
        },
    },
    profile: {
        title: 'Your Designs',
        before: 'Before -',
        after: 'After -'
    },
    terms: {
        title: 'Terms and Conditions',
        content: ''
    },
    privacy: {
        title: 'Privacy Policy',
        one: 'Your privacy is important to us. It is genhomeai.com’s policy to respect your privacy regarding any information we may collect from you across our website, genhomeai.com, and other sites we own and operate.',
        two: 'We only ask for personal information when we truly need it to provide a service to you. We collect it by fair and lawful means, with your knowledge and consent. We also let you know why we’re collecting it and how it will be used.',
        three: 'We only retain collected information for as long as necessary to provide you with your requested service. What data we store, we’ll protect within commercially acceptable means to prevent loss and theft, as well as unauthorised access, disclosure, copying, use or modification.',
        four: 'We don’t share any personally identifying information publicly or with third-parties, except when required to by law.',
        five: 'Our website may link to external sites that are not operated by us. Please be aware that we have no control over the content and practices of these sites, and cannot accept responsibility or liability for their respective privacy policies.',
        six: 'You are free to refuse our request for your personal information, with the understanding that we may be unable to provide you with some of your desired services.',
        seven: 'Your continued use of our website will be regarded as acceptance of our practices around privacy and personal information. If you have any questions about how we handle user data and personal information, feel free to contact us.',
        eight: 'This policy is effective as of 29 May 2023.'
    },
    footer: {
        terms: 'Terms',
        privacy: 'Privacy'
    },
    signin: {
        title: 'Sign In',
        another: 'Or Sign in with',
        emailPlaceHolder: 'Email address',
        passwordPlaceHolder: 'Password',
        rememberTitle: 'Remember me',
        forgetPassword: 'Forget Password?',
        noAccount: 'Don’t have an account?'
    },
    signup: {
        title: 'Sign Up',
        another: 'Or Sign up with',
        firstNamePlaceHolder: 'First Name',
        lastNamePlaceHolder: 'Last Name',
        emailPlaceHolder: 'Email address',
        passwordPlaceHolder: 'Password',
        rememberTitle: 'Remember me',
        forgetPassword: 'Forget Password?',
        gotAccount: ' Have an account?'
    },
    forgetPassword: {
        title: 'Reset Password',
        content: 'We’ll send reset password link mail address.',
        emailPlaceHolder: 'Email address',
    },
    pricing: {
        yearlySubscription: 'Yearly',
        monthlySubscription: 'Monthly',
        saveDesk: 'Save 10%',
        saveMobile: 'Save 10% on Yearly Plans',
        popular: 'Most popular',
        basic: {
            title: 'Basic',
            one: '100 room redesigns',
            oneDetail: '100 room redesigns with any theme.',
            two: 'Download photos',
            twoDetail: 'Download photos',
            three: 'Commercial usage of photos',
            threeDetail: 'Commercial usage of photos',
            four: 'Early access to new features',
            fourDetail: 'Early access to new features',
            five: 'Premium support by email',
            fiveDetail: 'Phone, Email and Web Chat support, 9am-9pm EST Mon-Fri, 2pm-7pm EST Sat-Sun',
            six: 'Upload Photo Upto 1MB',
            sixDetail: 'Upload Photo Upto 1MB',
            subscribed: 'Subscribed'
        },
        pro: {
            title: 'Pro',
            one: 'All features in Basic included',
            oneDetail: 'All in Basic included',
            two: 'Unlimited Photo Storage on Cloud',
            twoDetail: 'Unlimited Photo Storage on Cloud',
            three: 'High Photos Resolution',
            threeDetail: 'High Photos Resolution',
            four: 'Included All Next Features',
            fourDetail: 'Included All Next Features',
            five: 'Upload Photo Upto 5MB',
            fiveDetail: 'Upload Photo Upto 5MB',
            six: 'Download Your Images',
            sixDetail: 'Download Your Images',
            seven: 'Included All Next Features',
            sevenDetail: 'Included All Next Features',
        },
        currency: '$',
        perMonth: '/month',
        safePayment: 'Your payment is protected'
    },
    bill: {
        tittle: 'Your Bill',
        name: 'Name: ',
        credits: 'Credits: ',
        duration: 'Duration: ',
        price: 'Price: ',
        discount: 'Discount: ',
        total: 'Total: ',
        payment: {
            title: 'Payment methods',
            internalATM: 'Internal ATM',
            momo: 'MoMo Wallet',
            zalopay: 'ZaloPay Wallet',
            vnpay: 'VNPAY'
        },
        transfer: {
            internalATM: {
            title: 'Bank Transfer',
            account: 'Account Number: 0441000810601',
            name: 'Name: PHUNG THANH AN',
            content: 'Content: '
            },
            momo: {
                title: 'Momo Transfer',
                account: 'Phone Number: 0973898494',
                name: 'Name: PHUNG THANH AN',
                content: 'Content: '
            },
            zalopay: {
                title: 'ZaloPay Transfer',
                account: 'Phone Number: 0973898494',
                name: 'Name: PHUNG THANH AN',
                content: 'Content: '
            },
            vnpay: {
                title: 'VNPAY Transfer',
                account: 'Phone Number: 0973898494',
                name: 'Name: PHUNG THANH AN',
                content: 'Content: '
            }
        },
        notes: {
            title: 'NOTES',
            one: 'Please fill in the transfer details correctly to make the automatic activate.',
            oneDetail: 'Please fill in the transfer details correctly to make the automatic activate.',
            two: 'Please contact us if you got any problems with this process. We are here!',
            twoDetail: 'Please contact us if you got any problems with this process. We are here!',
        },
        afterPayment: {
            thankyouMess: 'Thank You!',
            checking: 'We are checking and activate your account Immediately ♥'
        }
    },
    milestoneMessage: {
        first: 'Thanks for over',
        second: 'home designs are created'
    },
    tos: {
        one: '1. Introduction',
        oneDetail: 'By using genhomeai.com you confirm your acceptance of, and agree to be bound by, these term and conditions.',
        two: '2. Agreement to Terms and Conditions',
        twoDetail: 'This Agreement takes effect on the date on which you first use the genhomeai.com application.',
        three: '3. Basic and Pro features',
        threeDetail: 'You will be able to use all the basic and pro features after you subscribe to a particular paid plan. The details of the basic and pro features are available on the pricing page.',
        four: '4. Product usage',
        fourDetail: 'By using genhomeai.com, you agree to receive important product updates from genhomeai.com via the email linked with your Google account or the email you used to register your account. You can opt-out of these product updates anytime by clicking the "Unsubscribe" link at the bottom of each email. We only send important product updates.',
        five: '5. Disclaimer',
        fiveDetail: 'It is not warranted that genhomeai.com will meet your requirements or that its operation will be uninterrupted or error-free. All express and implied warranties or conditions not stated in this Agreement (including without limitation, loss of profits, loss or corruption of data, business interruption, or loss of contracts), so far as such exclusion or disclaimer is permitted under the applicable law, are excluded and expressly disclaimed. This Agreement does not affect your statutory rights.',
        six: '6. Warranties and Limitation of Liability',
        sixDetail: 'genhomeai.com does not give any warranty, guarantee, or other term as to the quality, fitness for purpose, or otherwise of the software. genhomeai.com shall not be liable to you by reason of any representation (unless fraudulent), or any implied warranty, condition, or other term, or any duty at common law, for any loss of profit or any indirect, special, or consequential loss, damage, costs, expenses, or other claims (whether caused by genhomeai.com’s negligence or the negligence of its servants or agents or otherwise) which arise out of or in connection with the provision of any goods or services by genhomeai.com. genhomeai.com shall not be liable or deemed to be in breach of contract by reason of any delay in performing, or failure to perform, any of its obligations if the delay or failure was due to any cause beyond its reasonable control. Notwithstanding contrary clauses in this Agreement, in the event that genhomeai.com is deemed liable to you for breach of this Agreement, you agree that genhomeai.com’s liability is limited to the amount actually paid by you for your services or software, which amount is calculated in reliance upon this clause. You hereby release genhomeai.com from any and all obligations, liabilities, and claims in excess of this limitation.',
        seven: '7. General Terms and Law',
        sevenDetail: 'This Agreement is governed by the laws of Wyoming, United States. You acknowledge that no joint venture, partnership, employment, or agency relationship exists between you and genhomeai.com as a result of your use of these services. You agree not to hold yourself out as a representative, agent, or employee of genhomeai.com. You agree that genhomeai.com will not be liable by reason of any representation, act, or omission to act by you.'
    },
    message: {
        success: {
            redering: 'Your photo is Rendering',
            rederingDone: 'Your photo is Done',
            activated: 'Thank you! Your account is Ready',
        },
        error: {
            uploadPhoto: 'Please upload your photo',
            chooseRoom: 'Please choose your room type',
            chooseTheme: 'Please choose your theme type',
            fileOverBasic: 'File is over 5MB please try another smaller than 5MB!',
            fileOverPro: 'File is over 10MB please try another smaller than 10MB!',
            wrongFile: 'File is not accepted!',
            busy: 'The connection is broken, please try it again!'
        },
        warning: {
            runOutBasicCredits: 'You are not enough credits please buy it',
            runOutProCredits: 'You are not enough credits please buy it',
        }
    }
};

export default en;
