import React from 'react';
import { connect } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import * as routes from '../constants/routes';

const AuthRoute = ({ user }) => {
  return (!user) ?
    <>
      <Outlet />
    </> : <Navigate to={routes.HOME} replace />;
}

const mapStateToProps = state => ({
  user: state.user,
});

export default connect(mapStateToProps)(AuthRoute);
