import React, { useEffect, useState, useRef } from 'react';
import { message } from 'antd';
import confetti from 'canvas-confetti';
import ShopFurniture from '../ShopFurniture';
import axios from 'axios';
import Searching from '../Loading';
import Spinner from '../Spinner';
import ImageAgency from '../ImageAgency';
import AgencyForm from '../AgencyForm';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Modal from '@mui/material/Modal';

const modes = {
  pro: 'Pro',
  basic: 'Basic',
};

const APP_URL = process.env.REACT_APP_APP_URL;

const FindAgency = ({ t, isCreating, firebase, onFetchUser, userData}) => {
  const scrollableRef = useRef(null);

  const [jobsList, setJobsList] = useState([]);
  const [productsList, setProductsList] = useState([]);
  const [showShopFurniture, setShowShopFurniture] = useState(false);
  const [imageShop, setImageShop] = useState(null);
  const [searching, setSearching] = useState(false);
  const [openForm, setOpenForm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [lastJobCreatedAt, setLastJobCreatedAt] = useState("");
  const [isSpinning, setIsSpinning] = useState(false);

  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(3);
  const [openModal, setOpenModal] = useState(false);
  const [modalImage, setModalImage] = useState('');

  const columns = [
    { id: 'status', label: 'Trạng thái', minWidth: 120 },
    { id: 'construction', label: 'Xây dựng', minWidth: 50 },
    { id: 'furniture', label: 'Nội thất', minWidth: 50 },
    { id: 'decorItems', label: 'Vật tư', minWidth: 50 },
    { id: 'contact', label: 'Liên hệ', minWidth: 120 },
    { id: 'address', label: 'Địa chỉ', minWidth: 170 },
    {
      id: 'imageUrl',
      label: 'Thiết kế',
      minWidth: 100,
      field: 'img',
      render: imageUrl => <img src={imageUrl} alt="" border="3" height="100" width="100" />
    },
    { id: 'createdAt', label: 'Ngày tạo', minWidth: 100 },
    // { id: 'code', label: 'ISO\u00a0Code', minWidth: 100 },
    // {
    //   id: 'population',
    //   label: 'Population',
    //   minWidth: 170,
    //   align: 'right',
    //   format: (value) => value.toLocaleString('en-US'),
    // },
    // {
    //   id: 'size',
    //   label: 'Size\u00a0(km\u00b2)',
    //   minWidth: 170,
    //   align: 'right',
    //   format: (value) => value.toLocaleString('en-US'),
    // },
    // {
    //   id: 'density',
    //   label: 'Density',
    //   minWidth: 170,
    //   align: 'right',
    //   format: (value) => value.toFixed(2),
    // },
  ];

  const handleOpenModal = (imageUrl) => {
    setModalImage(imageUrl);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  useEffect(() => {
    setIsSpinning(true);
    firebase.getFirstJobs(firebase.auth.currentUser.uid)
    .then((result) => {
      const jobsArray = result.docs
        .map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

      if (jobsArray.length > 1) {
        setJobsList(jobsArray);
        setLastJobCreatedAt(jobsArray[jobsArray.length - 1].createdAt);
      }
      setTimeout(() => {
        setIsSpinning(false);
      }, 1000);
    })
    .catch((error) => {
      console.log(error);
    });

    firebase.getRequests(firebase.auth.currentUser.uid)
    .then((result) => {
      const jobsArray = result.docs
        .map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
      
        const updatedData = jobsArray.map((item) => {
          const date = new Date(item.createdAt);
          const formattedDate = `${date.toISOString().substr(11, 8)} ${date.toISOString().substr(8, 2)}-${date.toISOString().substr(5, 2)}-${date.toISOString().substr(0, 4)}`;
          
          return {
            ...item,
            status: convertStatus(item.status),
            construction: item.construction ? 'Có' : 'Không',
            furniture: item.furniture ? 'Có' : 'Không',
            decorItems: item.decorItems ? 'Có' : 'Không',
            contact: item.phoneNumber,
            address: item.detailAddress + ", " + item.currentWard + ", " + item.currentDistrict + ", " + item.currentProvince,
            createdAt: formattedDate,
            imageUrl: (
              <>
                <svg width="12px" height="12px" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                  <path fill="#000000" d="M8,2 C14,2 16,8 16,8 C16,8 14,14 8,14 C2,14 0,8 0,8 C0,8 2,2 8,2 Z M8,4 C5.76219,4 4.27954,5.08865 3.28644,6.28037 C2.78373,6.88363 2.42604,7.49505 2.1951,7.95693 L2.17372,8 L2.1951,8.04307 C2.42604,8.50495 2.78373,9.11637 3.28644,9.71963 C4.27954,10.9113 5.76219,12 8,12 C10.2378,12 11.7205,10.9113 12.7136,9.71963 C13.2163,9.11637 13.574,8.50495 13.8049,8.04307 L13.8263,8 L13.8049,7.95693 C13.574,7.49505 13.2163,6.88363 12.7136,6.28037 C11.7205,5.08865 10.2378,4 8,4 Z M8,5 C8.30747,5 8.60413,5.04625 8.88341,5.13218 C8.36251,5.36736 8,5.89135 8,6.5 C8,7.32843 8.67157,8 9.5,8 C10.1087,8 10.6326,7.63749 10.8678,7.11659 C10.9537,7.39587 11,7.69253 11,8 C11,9.65685 9.65685,11 8,11 C6.34315,11 5,9.65685 5,8 C5,6.34315 6.34315,5 8,5 Z"/>
                </svg>
                <a href={item.imageUrl} target="_blank" style={{color: "#000", marginLeft: "2px"}} rel="noopener noreferrer">
                  Xem
                </a>
              </>
            )
          };
        });


      setRows(updatedData);
    })
    .catch((error) => {
      console.log(error);
    });

    return () => {
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const { scrollTop } = scrollableRef.current;
      if (
        window.innerHeight + scrollTop >=
        scrollableRef.current.scrollHeight - 1
      ) {
        loadMorePhotos(isLoading, jobsList, lastJobCreatedAt);
      }
    };

    // Attach the scroll event listener to the scrollable element
    scrollableRef.current?.addEventListener('scroll', handleScroll);

    return () => {
      // Remove the scroll event listener when the component unmounts
      scrollableRef.current?.removeEventListener('scroll', handleScroll);
    };
  }, [isLoading, lastJobCreatedAt, jobsList]);

  function convertStatus(status) {
    switch (status) {
      case "pending":
        return "Đang chờ"
        break;
      case "processing":
        return "Đang thi công"
        break;
      case "finished":
        return "Đã hoàn thành"
        break;
    
      default:
        return ""
        break;
    }
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const loadMorePhotos = async (loading, jobs, lastCreatedAt) => {
    if (!loading && lastCreatedAt !== "") {
      setIsLoading(true);
      firebase.getJobsPagination(firebase.auth.currentUser.uid, lastCreatedAt)
        .then((result) => {

          const jobsArray = result.docs
            .filter((doc) => doc.data().mode !== "BASIC")
            .map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }));

          if (jobsArray.length > 0) {
            setTimeout(() => {
              const mergedJobsList = [...jobs, ...jobsArray];
              setLastJobCreatedAt(jobsArray[jobsArray.length - 1].createdAt);
              setJobsList(mergedJobsList);
              setIsLoading(false);
            }, 500);
          } else {
            setLastJobCreatedAt("");
            setIsLoading(true);
          }

        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  function generateRandomFileName(extension) {
    const randomNumber = Math.floor(Math.random() * 1000000000); // Generate a random number between 0 and 999999999
    return `noithatai-${randomNumber}.${extension}`;
  }

  const onFindAgency = (image) => {
    setOpenForm(true);
    setImageShop(image);
    setShowShopFurniture(false);
  }

  const onCloseShopFurniture = () => {
    setShowShopFurniture(false);
  }

  const onCloseAgencyForm = (getCustomerReqs=false) => {
    if (getCustomerReqs) {
      firebase.getRequests(firebase.auth.currentUser.uid)
      .then((result) => {
        const jobsArray = result.docs
          .map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
        
          const updatedData = jobsArray.map((item) => {
            const date = new Date(item.createdAt);
            const formattedDate = `${date.toISOString().substr(11, 8)} ${date.toISOString().substr(8, 2)}-${date.toISOString().substr(5, 2)}-${date.toISOString().substr(0, 4)}`;
            
            return {
              ...item,
              status: convertStatus(item.status),
              construction: item.construction ? 'Có' : 'Không',
              furniture: item.furniture ? 'Có' : 'Không',
              decorItems: item.decorItems ? 'Có' : 'Không',
              contact: item.phoneNumber,
              address: item.detailAddress + ", " + item.currentWard + ", " + item.currentDistrict + ", " + item.currentProvince,
              createdAt: formattedDate,
              imageUrl: (
                <>
                  <svg width="12px" height="12px" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                    <path fill="#000000" d="M8,2 C14,2 16,8 16,8 C16,8 14,14 8,14 C2,14 0,8 0,8 C0,8 2,2 8,2 Z M8,4 C5.76219,4 4.27954,5.08865 3.28644,6.28037 C2.78373,6.88363 2.42604,7.49505 2.1951,7.95693 L2.17372,8 L2.1951,8.04307 C2.42604,8.50495 2.78373,9.11637 3.28644,9.71963 C4.27954,10.9113 5.76219,12 8,12 C10.2378,12 11.7205,10.9113 12.7136,9.71963 C13.2163,9.11637 13.574,8.50495 13.8049,8.04307 L13.8263,8 L13.8049,7.95693 C13.574,7.49505 13.2163,6.88363 12.7136,6.28037 C11.7205,5.08865 10.2378,4 8,4 Z M8,5 C8.30747,5 8.60413,5.04625 8.88341,5.13218 C8.36251,5.36736 8,5.89135 8,6.5 C8,7.32843 8.67157,8 9.5,8 C10.1087,8 10.6326,7.63749 10.8678,7.11659 C10.9537,7.39587 11,7.69253 11,8 C11,9.65685 9.65685,11 8,11 C6.34315,11 5,9.65685 5,8 C5,6.34315 6.34315,5 8,5 Z"/>
                  </svg>
                  <a href={item.imageUrl} target="_blank" style={{color: "#000", marginLeft: "2px"}} rel="noopener noreferrer">
                    Xem
                  </a>
                </>
              )
            };
          });
  
  
        setRows(updatedData);
      })
      .catch((error) => {
        console.log(error);
      });
    }
    setOpenForm(false);
  }

  return (
    <>
      <div className="my-design" ref={scrollableRef}>
      <h3>Yêu cầu thi công</h3>
        <p style={{ color: '#8c8c8c', fontSize: '14px', marginTop: "0", marginBottom: "1rem"}}>
          {"Lịch sử yêu cầu thi công của bạn."}
        </p>
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format && typeof value === 'number'
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[3, 10, 25]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <Modal open={openModal} onClose={handleCloseModal}>
        <div>
          <img src={modalImage} alt="Modal" />
        </div>
      </Modal>
    </Paper>
        <h3 style={{ marginTop: "20px" }}>Chọn thiết kế bạn muốn thi công</h3>
        <p style={{ color: '#8c8c8c', fontSize: '14px', marginTop: "0", marginBottom: "1rem"}}>
          Nội thất AI sẽ kết nối bạn tới các đơn vị gần bạn uy tín và chất lượng chuyên về thi công nội thất, xây dựng và vật tư.
        </p>
        <div className="rnftg-h-full rnftg-w-full">
          <div className="rnftg-h-full dark:rnftg-bg-gray-900">
            <div className="rnftg-flex rnftg-flex-col pd-2">
              <div className="rnftg-grid rnftg-gap-6 rnftg-grid-flow-row rnftg-grid-cols-1 md:rnftg-grid-cols-2 lg:rnftg-grid-cols-3 xl:rnftg-grid-cols-3">
              {jobsList.length > 0 &&
                jobsList.map((job, key) => (
                  <ImageAgency key={key} job={job} onFindAgency={onFindAgency}/>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <section className="selection-thanks-customer">
        <span className="container display-4 fw-bold-600">{t('milestoneMessage.first')}<span className="particletext hearts"> 10.000 </span>{t('milestoneMessage.second')}</span>
      </section> */}
      { showShopFurniture && <ShopFurniture productsList={productsList} firebase={firebase} t={t} image={imageShop} onClose={onCloseShopFurniture}></ShopFurniture>}
      { searching && <Searching title="Searching products"/> }
      { openForm && <AgencyForm userData={userData} productsList={productsList} firebase={firebase} t={t} image={imageShop} onClose={onCloseAgencyForm}></AgencyForm>}
      {isSpinning && <Spinner />}
    </>
  );
};

export default FindAgency;
