import React, { useRef } from 'react';

const FAQ = () => {
  const faqToggles = useRef([]);

  const handleToggleClick = (index) => {
    faqToggles.current[index].parentNode.classList.toggle('active');
  };

  return (
    <div className="faq-wrapper">
      <h2 className="mx-auto relative z-[10] t-50 text-white mb-5 md:mb-6 font-bold text-center mx-auto md:text-center">
        Các câu hỏi thường gặp
      </h2>
      <div className="faqs-container">
        <div className="faq">
          <h3 className="faq-title">
            Nội thất AI hoạt động như thế nào?
          </h3>
          <p className="faq-text">
            Chỉ cần tải ảnh lên hoặc chọn từ thư viện sau đó chọn mẫu phòng của chúng tôi và các thuật toán AI của chúng tôi sẽ tạo ra các thiết kế hoàn hảo cho không gian của bạn. Bạn có thể khám phá nhiều phong cách khác nhau, thử nghiệm các tùy chọn trang trí và nội thất khác nhau mà không cần thay đổi vật lý.
          </p>
          <button className="faq-toggle" onClick={() => handleToggleClick(0)} ref={(element) => (faqToggles.current[0] = element)}>
            <span className="fas fa-chevron-down">
              <svg xmlns="http://www.w3.org/2000/svg" height="25" viewBox="0 0 20 20" fill="currentColor"  className="relative w-6 h-6 text-gray-500 group-hover:text-gray-600"><path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
            </span>
            <span className="fas fa-times">
            <svg fill="currentColor" width="25" viewBox="-6 -6 24 24" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMinYMin" className="jam jam-close">
              <path d="M7.314 5.9l3.535-3.536A1 1 0 1 0 9.435.95L5.899 4.485 2.364.95A1 1 0 1 0 .95 2.364l3.535 3.535L.95 9.435a1 1 0 1 0 1.414 1.414l3.535-3.535 3.536 3.535a1 1 0 1 0 1.414-1.414L7.314 5.899z"/>
            </svg>
          </span>
          </button>
        </div>
        <div className="faq">
          <h3 className="faq-title">
            Thông tin cá nhân và dữ liệu thiết kế của tôi có được bảo mật không?
          </h3>
          <p className="faq-text">
            Chắc chắn là có, chúng tôi làm việc trực tiếp với nền tảng của Google. Bảo vệ sự riêng tư của bạn là vô cùng quan trọng đối với chúng tôi. Nội thất AI thực hiện các biện pháp cần thiết để bảo mật thông tin cá nhân và dữ liệu thiết kế của bạn. Chúng tôi có sẵn các giao thức bảo mật mạnh mẽ để đảm bảo rằng dữ liệu của bạn được bảo mật và chỉ được sử dụng cho mục đích cung cấp dịch vụ của chúng tôi. Để biết thông tin chi tiết, vui lòng tham khảo chính sách bảo mật trên trang web của chúng tôi.
          </p>
          <button className="faq-toggle" onClick={() => handleToggleClick(1)} ref={(element) => (faqToggles.current[1] = element)}>
            <span className="fas fa-chevron-down">
              <svg xmlns="http://www.w3.org/2000/svg" height="25" viewBox="0 0 20 20" fill="currentColor"  className="relative w-6 h-6 text-gray-500 group-hover:text-gray-600"><path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
            </span>
            <span className="fas fa-times">
            <svg fill="currentColor" width="25" viewBox="-6 -6 24 24" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMinYMin" className="jam jam-close">
              <path d="M7.314 5.9l3.535-3.536A1 1 0 1 0 9.435.95L5.899 4.485 2.364.95A1 1 0 1 0 .95 2.364l3.535 3.535L.95 9.435a1 1 0 1 0 1.414 1.414l3.535-3.535 3.536 3.535a1 1 0 1 0 1.414-1.414L7.314 5.899z"/>
            </svg>
          </span>
          </button>
        </div>
        <div className="faq">
          <h3 className="faq-title">
            Chỉ định thay đổi là gì và nó hoạt động như thế nào?
          </h3>
          <p className="faq-text">
            Chỉ định thay đổi là một kỹ thuật cho phép bạn thay đổi bất kỳ nơi nào bạn muốn với bất kỳ đồ vật hay phong cách bạn muốn bằng cách sử dụng công nghệ AI.</p>
          <button className="faq-toggle" onClick={() => handleToggleClick(2)} ref={(element) => (faqToggles.current[2] = element)}>
            <span className="fas fa-chevron-down">
              <svg xmlns="http://www.w3.org/2000/svg" height="25" viewBox="0 0 20 20" fill="currentColor"  className="relative w-6 h-6 text-gray-500 group-hover:text-gray-600"><path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
            </span>
            <span className="fas fa-times">
            <svg fill="currentColor" width="25" viewBox="-6 -6 24 24" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMinYMin" className="jam jam-close">
              <path d="M7.314 5.9l3.535-3.536A1 1 0 1 0 9.435.95L5.899 4.485 2.364.95A1 1 0 1 0 .95 2.364l3.535 3.535L.95 9.435a1 1 0 1 0 1.414 1.414l3.535-3.535 3.536 3.535a1 1 0 1 0 1.414-1.414L7.314 5.899z"/>
            </svg>
          </span>
          </button>
        </div>
        <div className="faq">
          <h3 className="faq-title">
          Nội thất AI có thể giúp tôi thay đổi thiết kế cả Nội thất và Ngoại thất được không?
          </h3>
          <p className="faq-text">
          Bạn có thể, Nội thất AI có thể hỗ trợ bạn thay đổi thiết kế Nội thất và Ngoại thất chỉ trong vài giây.
          </p>
          <button className="faq-toggle" onClick={() => handleToggleClick(3)} ref={(element) => (faqToggles.current[3] = element)}>
            <span className="fas fa-chevron-down">
              <svg xmlns="http://www.w3.org/2000/svg" height="25" viewBox="0 0 20 20" fill="currentColor"  className="relative w-6 h-6 text-gray-500 group-hover:text-gray-600"><path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
            </span>
            <span className="fas fa-times">
            <svg fill="currentColor" width="25" viewBox="-6 -6 24 24" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMinYMin" className="jam jam-close">
              <path d="M7.314 5.9l3.535-3.536A1 1 0 1 0 9.435.95L5.899 4.485 2.364.95A1 1 0 1 0 .95 2.364l3.535 3.535L.95 9.435a1 1 0 1 0 1.414 1.414l3.535-3.535 3.536 3.535a1 1 0 1 0 1.414-1.414L7.314 5.899z"/>
            </svg>
          </span>
          </button>
        </div>
        <div className="faq">
          <h3 className="faq-title">
          Tôi có thể chỉnh sửa thiết kế sau khi hoàn thành không?
          </h3>
          <p className="faq-text">
          Bạn có thể, Nội thất AI hiểu rằng sở thích thiết kế có thể phát triển hoặc ý tưởng mới có thể nảy sinh. Nền tảng của chúng tôi cho phép bạn xem lại các dự án của mình, sửa đổi thiết kế và thử nghiệm các yếu tố khác nhau bất kỳ lúc nào.
          </p>
          <button className="faq-toggle" onClick={() => handleToggleClick(4)} ref={(element) => (faqToggles.current[4] = element)}>
            <span className="fas fa-chevron-down">
              <svg xmlns="http://www.w3.org/2000/svg" height="25" viewBox="0 0 20 20" fill="currentColor"  className="relative w-6 h-6 text-gray-500 group-hover:text-gray-600"><path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
            </span>
            <span className="fas fa-times">
            <svg fill="currentColor" width="25" viewBox="-6 -6 24 24" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMinYMin" className="jam jam-close">
              <path d="M7.314 5.9l3.535-3.536A1 1 0 1 0 9.435.95L5.899 4.485 2.364.95A1 1 0 1 0 .95 2.364l3.535 3.535L.95 9.435a1 1 0 1 0 1.414 1.414l3.535-3.535 3.536 3.535a1 1 0 1 0 1.414-1.414L7.314 5.899z"/>
            </svg>
          </span>
          </button>
        </div>
        <div className="faq">
          <h3 className="faq-title">
          Thiết kế của tôi có được lưu trữ lâu dài không?
          </h3>
          <p className="faq-text">
          Chắc chắn là có, thiết kế của bạn sẽ được chúng tôi lưu trữ lên đám mây.
          </p>
          <button className="faq-toggle" onClick={() => handleToggleClick(5)} ref={(element) => (faqToggles.current[5] = element)}>
            <span className="fas fa-chevron-down">
              <svg xmlns="http://www.w3.org/2000/svg" height="25" viewBox="0 0 20 20" fill="currentColor"  className="relative w-6 h-6 text-gray-500 group-hover:text-gray-600"><path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
            </span>
            <span className="fas fa-times">
            <svg fill="currentColor" width="25" viewBox="-6 -6 24 24" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMinYMin" className="jam jam-close">
              <path d="M7.314 5.9l3.535-3.536A1 1 0 1 0 9.435.95L5.899 4.485 2.364.95A1 1 0 1 0 .95 2.364l3.535 3.535L.95 9.435a1 1 0 1 0 1.414 1.414l3.535-3.535 3.536 3.535a1 1 0 1 0 1.414-1.414L7.314 5.899z"/>
            </svg>
          </span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default FAQ;
