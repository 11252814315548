import React, { useEffect, useState, useRef } from 'react';
import ShopFurniture from '../ShopFurniture';
import axios from 'axios';
import Searching from '../Loading';
import Spinner from '../Spinner';
import AgencyForm from '../AgencyForm';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Modal from '@mui/material/Modal';
import {
  Checkbox,
  TextField,
  MenuItem,
  FormControl,
  FormControlLabel,
  RadioGroup,
  FormLabel,
  Select,
  InputLabel,
  FormHelperText,
  Button,
  Snackbar
} from '@mui/material';
import { message } from 'antd';

const modes = {
  pro: 'Pro',
  basic: 'Basic',
};

const REFRATIO = 0.1 // 10% for each one

const APP_URL = process.env.REACT_APP_APP_URL;

const CopyToClipboardButton = ({refLink}) => {
  const [open, setOpen] = useState(false)
  const handleClick = () => {
    setOpen(true)
    navigator.clipboard.writeText(refLink)
  }

  function shortenLink(link, maxLength=30) {
    if (link.length > maxLength) {
      return link.substring(0, maxLength) + '...';
    }
    return link;
  }
  
  return (
      <>
        <Button onClick={handleClick} style={{textTransform: 'lowercase'}}>{shortenLink(refLink)}</Button>
        <Snackbar
          open={open}
          onClose={() => setOpen(false)}
          autoHideDuration={2000}
          message="Đã copy đường dẫn"
        />
      </>
  )
}

const Referal = ({ t, isCreating, firebase, onFetchUser, userData }) => {
  const scrollableRef = useRef(null);
  const [isSpinning, setIsSpinning] = useState(false);

  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);
  const [withdrawRows, setWithdrawRows] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(3);
  const [openModal, setOpenModal] = useState(false);
  const [modalImage, setModalImage] = useState('');
  const [refLink, setRefLink] = useState('');
  const [currentCredits, setCurrentCredits] = useState(0);
  const [paidCredits, setPaidCredits] = useState(0);

  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [bankOwnerName, setBankOwnerName] = useState('');
  const [bankOwnerNumber, setBankOwnerNumber] = useState('');
  const [banksList, setBanksList] = useState([]);
  const [currentBank, setCurrentBank] = useState('');
  const [currentBankLabel, setCurrentBankLabel] = useState('');

  const [formError, setFormError] = useState({
    email: false,
    phoneNumber: false,
    bankOwnerName: false,
    bankOwnerNumber: false,
    currentBank: false,
  });


  const columns = [
    { id: 'isPaid', label: 'Thanh toán', minWidth: 100 },
    { id: 'newName', label: 'Tên', minWidth: 100 },
    { id: 'package', label: 'Gói đã mua', minWidth: 70 },
    { id: 'price', label: 'Hoa hồng nhận được', minWidth: 70 },
    { id: 'createdAt', label: 'Ngày mua', minWidth: 120 },
  ];

  const withdrawColumns = [
    { id: 'status', label: 'Thanh toán', minWidth: 100 },
    { id: 'bankOwnerName', label: 'Tên tài khoản', minWidth: 100 },
    { id: 'bankOwnerNumber', label: 'Số tài khoản', minWidth: 70 },
    { id: 'bankName', label: 'Ngân hàng', minWidth: 70 },
    { id: 'amount', label: 'Hoa hồng', minWidth: 70 },
    { id: 'realAmount', label: 'Thực nhận', minWidth: 70 },
    { id: 'createdAt', label: 'Ngày tạo', minWidth: 120 },
  ];

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  useEffect(() => {
    setIsSpinning(true);

    firebase
    .getRefLink(firebase.auth.currentUser.uid)
    .then(result => {
      
      const refLinkArray = result.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));

      if (refLinkArray.length > 0) {
        setRefLink(refLinkArray[0].refLink);

        // Get ref list
        firebase
        .getAffRefs(refLinkArray[0].code)
        .then(result => {
          const affRefsArray = result.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
          }));

          const unpaid = affRefsArray
          .filter(job => !job.isPaid) // Filter out jobs where isPaid is true
          .filter(job => !job.isDeleted) // Filter out jobs where isPaid is true
          .map(job => job.price || 0) // Extract prices
          .reduce((total, price) => total + price, 0); // Calculate total

          const paid = affRefsArray
          .filter(job => job.isPaid) // Filter out jobs where isPaid is true
          .map(job => job.price || 0) // Extract prices
          .reduce((total, price) => total + price, 0); // Calculate total

          setCurrentCredits(unpaid * REFRATIO);
          setPaidCredits(paid * REFRATIO);

  
          const updatedData = affRefsArray.map(item => {
            const date = new Date(item.createdAt);
            const formattedDate = `${date.toISOString().substr(11, 8)} ${date.toISOString().substr(8, 2)}-${date
              .toISOString()
              .substr(5, 2)}-${date.toISOString().substr(0, 4)}`;
  
            return {
              ...item,
              isPaid: convertStatus(item.isPaid),
              createdAt: formattedDate,
              price: formatCurrency(item.price * REFRATIO)
            };
          });
  
          setRows(updatedData);
        })
        .catch(error => {
          console.log(error);
        });

        // Get withdraw requests
        firebase
        .getWithdrawRequests(firebase.auth.currentUser.uid)
        .then(result => {
          const withdrawsArray = result.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
          }));

          const updatedData = withdrawsArray.map(item => {
            const date = new Date(item.createdAt);
            const formattedDate = `${date.toISOString().substr(11, 8)} ${date.toISOString().substr(8, 2)}-${date
              .toISOString()
              .substr(5, 2)}-${date.toISOString().substr(0, 4)}`;
  
            return {
              ...item,
              status: convertWithdrawStatus(item.status),
              bankOwnerName: item.bankOwnerName,
              bankOwnerNumber: item.bankOwnerNumber,
              bankName: item.bankName,
              amount: item.amount,
              realAmount: formatCurrency(item.realAmount),
              createdAt: formattedDate,
            };
          });
  
          setWithdrawRows(updatedData);
        })
        .catch(error => {
          console.log(error);
        });
      }
      setTimeout(() => {
        setIsSpinning(false);
      }, 1000);
    })
    .catch(error => {
      console.log(error);
    });

    return () => {};
  }, []);

  useEffect(async () => {
    if (userData.email !== (null || undefined)) {
      setEmail(userData.email);
    }
    if (userData.phoneNumber !== (null || undefined)) {
      setPhoneNumber(userData.phoneNumber);
    }

    if (banksList.length <= 0) {
      const result = await axios.get(`https://api.vietqr.io/v2/banks`, {}, {});

      const convertedData = result.data.data.map(item => ({
        value: item.id,
        label: "Ngân hàng " + item.shortName,
        logo: item.logo
      }));

      setBanksList(convertedData);
    }

    return () => {};
  }, []);

  function handleChangeEmail(event) {
    setFormError(prevData => ({
      ...prevData,
      email: false,
    }));
    setEmail(event.target.value);
  }

  function handleChangePhoneNumber(event) {
    setFormError(prevData => ({
      ...prevData,
      phoneNumber: false,
    }));
    setPhoneNumber(event.target.value);
  }

  function handleChangeBankOwnerName(event) {
    setFormError(prevData => ({
      ...prevData,
      bankOwnerName: false,
    }));
    setBankOwnerName(event.target.value);
  }

  function handleChangeBankOwnerNumber(event) {
    setFormError(prevData => ({
      ...prevData,
      bankOwnerNumber: false,
    }));
    setBankOwnerNumber(event.target.value);
  }

  function isValidPhoneNumber(phoneNumber) {
    if (phoneNumber[0] === '0' || phoneNumber[0] === '8') {
      return true;
    }
    return false;
  }

  function formatCurrency(price) {
    const formattedPrice = new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(price);
    return formattedPrice;
  }

  function convertStatus(status) {
    switch (status) {
      case true:
        return 'Đã xong';
      case false:
        return 'Chưa';
      default:
        return '';
    }
  }

  function convertWithdrawStatus(status) {
    switch (status) {
      case "done":
        return 'Đã xong';
      case "pending":
        return 'Đang xử lý';
      default:
        return '';
    }
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  function handleSubmit(e) {
    e.preventDefault();
    if (
      email === '' ||
      email === null ||
      phoneNumber === '' ||
      phoneNumber === null ||
      bankOwnerName === '' ||
      bankOwnerName === null ||
      bankOwnerNumber === '' ||
      bankOwnerNumber === null ||
      currentBank === '' ||
      currentBank === null
    ) {
      setFormError({
        email: email === '' || email === null ? true : false,
        phoneNumber: phoneNumber === '' || phoneNumber === null || !isValidPhoneNumber(phoneNumber) ? true : false,
        bankOwnerName: bankOwnerName === '' || bankOwnerName === null ? true : false,
        bankOwnerNumber: bankOwnerNumber === '' || bankOwnerNumber === null ? true : false,
        currentBank: currentBank === '' || currentBank === null ? true : false,
      });
      return;
    }

    // Send error message when balance is smaller than 1mils vnd
    if (currentCredits < 1000000) {
      message.error("Bạn chưa đủ điều kiện nhận thưởng");
      return;
    } 

    setIsSpinning(true);

    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${firebase.auth.currentUser.accessToken}`,
    };

    const data = {
      "bankOwnerName": bankOwnerName,
      "bankOwnerNumber": bankOwnerNumber,
      "bankName": currentBankLabel,
      "email": email,
      "phoneNumber": phoneNumber,
      "amount": formatCurrency(currentCredits),
      "uid": firebase.auth.currentUser.uid,
    };

    console.log(data);

    axios.post(`${APP_URL}/request-reward`, { data: data },{ headers })
    .then(response => {
      if (response.data.status === "pending") {
        alert("Bạn đang có 1 yêu cầu rút thưởng, vui lòng chờ hoàn tất giao dịch");
        setIsSpinning(false);
        return;
      }
      // Handle the response data
      setIsSpinning(false);
      message.success("Giao dịch của bạn đang được xử lý");
      firebase
      .getWithdrawRequests(firebase.auth.currentUser.uid)
      .then(result => {
        const withdrawsArray = result.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));

        const updatedData = withdrawsArray.map(item => {
          const date = new Date(item.createdAt);
          const formattedDate = `${date.toISOString().substr(11, 8)} ${date.toISOString().substr(8, 2)}-${date
            .toISOString()
            .substr(5, 2)}-${date.toISOString().substr(0, 4)}`;

          return {
            ...item,
            status: convertWithdrawStatus(item.status),
            bankOwnerName: item.bankOwnerName,
            bankOwnerNumber: item.bankOwnerNumber,
            bankName: item.bankName,
            amount: item.amount,
            realAmount: item.amount,
            createdAt: formattedDate,
            
          };
        });

        setWithdrawRows(updatedData);
      })
      .catch(error => {
        console.log(error);
      });
    })
    .catch(error => {
      message.error(t('message.error.busy'));
      setIsSpinning(false);
    });
  }

  async function handleChangeBank(event) {
    setFormError(prevData => ({
      ...prevData,
      currentBank: false,
    }));
    setCurrentBank(event.target.value);
    // Find the selected province label
    const selectedLabel = banksList.find((bank) => bank.value === event.target.value)?.label || '';
    setCurrentBankLabel(selectedLabel);
  }

  return (
    <>
      <div className="my-reward" ref={scrollableRef}>
        <h3>Phần thưởng</h3>
        <p style={{ fontSize: '14px', marginTop: '0', marginBottom: '1rem' }}>Nhận ngay <span style={{color: '#d425f9', fontWeight: '500'}}>10% tiền thưởng trên mọi giao dịch</span> khi giới thiệu bạn bè sử dụng AI thành công.</p>
        <hr/>
        <h3 style={{ marginTop: '20px' }}>Gửi link giới thiệu</h3>
        {refLink === "" ? (
          <p style={{ fontSize: '14px', marginTop: '0', marginBottom: '1rem' }}>Bạn chưa có link giới thiệu, để kích hoạt vui lòng <span style={{color: '#d425f9', fontWeight: '500'}}>Mua lượt thiết kế và sử dụng AI</span>, đảm bảo bạn hiểu ứng dụng và hướng dẫn cho người mới.</p>
        ):(
          <>
            {/* <p style={{ fontSize: '14px', marginTop: '0', marginBottom: '1rem' }}>Lấy link</p> */}
            <span style={{ fontSize: '14px'}}>Chọn để copy:</span><CopyToClipboardButton refLink={refLink}/>
          </>
        )}
        <hr/>
        {/* <h3 style={{ marginTop: '20px' }}>Giới thiệu của bạn</h3> */}
        <p style={{ color: '#8c8c8c', fontSize: '14px', marginTop: '0', marginBottom: '1rem' }}>{'Lịch sử giới thiệu bạn bè của bạn.'}</p>
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
          <TableContainer sx={{ maxHeight: 440 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map(column => (
                    <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(row => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                      {columns.map(column => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {column.format && typeof value === 'number' ? column.format(value) : value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[3, 10, 25]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          <Modal open={openModal} onClose={handleCloseModal}>
            <div>
              <img src={modalImage} alt="Modal" />
            </div>
          </Modal>
        </Paper>
        <h3 style={{ marginTop: '20px' }}>Gửi yêu cầu nhận thưởng</h3>
        <p style={{ color: '#8c8c8c', fontSize: '14px', marginTop: '0', marginBottom: '1rem' }}>{'Số dư hoa hồng tối thiểu 1.000.000 đ sẽ đủ điều kiện nhận thưởng.'}</p>
        <p style={{ fontSize: '14px', marginTop: '20px', marginBottom: '1rem' }}>Số dư hoa hồng: {formatCurrency(currentCredits)}</p>
        <form onSubmit={handleSubmit}>
          <FormControl>
            <FormLabel id="demo-row-radio-buttons-group-label">
              <span style={{ color: 'red', marginRight: '2px'}}>*</span><span style={{ fontSize: '14px' }}>{`Thông tin nhận thưởng (quan trọng vui lòng điền chính xác)`}</span>
            </FormLabel>
            <FormControl fullWidth className="mt-2">
              <InputLabel id="demo-simple-select-label">Tên ngân hàng</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={currentBank}
                label="Tên ngân hàng"
                onChange={handleChangeBank}
                MenuProps={{
                  style: { zIndex: 9999 },
                }}
                error={formError.currentBank}
                helpertext={formError.currentBank ? 'Vui lòng chọn Ngân hàng' : ''}
              >
                {banksList.map((c, i) => {
                  return (
                    <MenuItem key={i} value={parseInt(c.value)}>
                      <img style={{width: "70px", marginRight: "5px"}} src={c.logo}/> {c.label}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <TextField
              className="mt-2"
              id="outlined-basic"
              sx={{ width: '100%' }}
              label="Tên chủ tài khoản"
              variant="outlined"
              type="address"
              onChange={handleChangeBankOwnerName}
              value={bankOwnerName}
              error={formError.bankOwnerName}
              helpertext={formError.bankOwnerName ? 'Vui lòng tên chủ tài khoản nhận thưởng' : ''}
            />
            <TextField
              className="mt-2"
              id="outlined-basic"
              sx={{ width: '100%' }}
              label="Số tài khoản"
              variant="outlined"
              type="address"
              onChange={handleChangeBankOwnerNumber}
              value={bankOwnerNumber}
              error={formError.bankOwnerNumber}
              helpertext={formError.bankOwnerNumber ? 'Vui lòng tên chủ tài khoản nhận thưởng' : ''}
            />
            <TextField
              className="mt-2"
              id="outlined-basic"
              sx={{ width: '100%' }}
              label="Địa chỉ email"
              variant="outlined"
              onChange={handleChangeEmail}
              value={email}
              type="email"
              error={formError.email}
              helpertext={formError.email ? 'Vui lòng nhập địa chỉ email' : ''}
            />
            <TextField
              className="mt-2"
              id="outlined-basic"
              sx={{ width: '100%' }}
              label="Số điện thoại"
              variant="outlined"
              type="number"
              onChange={handleChangePhoneNumber}
              value={phoneNumber}
              error={formError.phoneNumber}
              helpertext={formError.phoneNumber ? 'Vui lòng nhập số điện thoại' : ''}
            />
            <div className="create-imagine-button generate-btn">
              <Button type="submit" className="generate-button w-button" style={{textTransform: "unset"}}>
                Xác nhận yêu cầu
              </Button>
            </div>
          </FormControl>
        </form>
        <hr/>
        <p style={{ color: '#8c8c8c', fontSize: '14px', marginTop: '0'}}>{'Lịch sử nhận thưởng của bạn.'}</p>
        <p style={{ fontSize: '14px', marginTop: '0', marginBottom: '1rem' }}>Đã nhận tổng cộng: {formatCurrency(paidCredits)}</p>
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
          <TableContainer sx={{ maxHeight: 440 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {withdrawColumns.map(column => (
                    <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {withdrawRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(row => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                      {withdrawColumns.map(column => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {column.format && typeof value === 'number' ? column.format(value) : value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[3, 10, 25]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          <Modal open={openModal} onClose={handleCloseModal}>
            <div>
              <img src={modalImage} alt="Modal" />
            </div>
          </Modal>
        </Paper>
      </div>
      {isSpinning && <Spinner />}
    </>
  );
};

export default Referal;
