import React, { useEffect, useState } from 'react';
import { message } from 'antd';
import confetti from 'canvas-confetti';
import LoaderLineMask from '../LoaderLineMask';
import CompareImage from '../CompareImage';
import ShopFurniture from '../ShopFurniture';
import axios from 'axios';
import Searching from '../Loading';
import EditImage from '../EditImage';
import ImageResult from '../ImageResult';
import AgencyForm from '../AgencyForm';

const APP_URL = process.env.REACT_APP_APP_URL;

const modes = {
  pro: 'Pro',
  basic: 'Basic',
};

const AllShowCase = ({ userData, currentJob, t, isCreating, firebase, onGenFill, onFetchUser, checkCredits, onUpImageResolution}) => {
  const [jobsList, setJobsList] = useState([]);
  const [productsList, setProductsList] = useState([]);
  const [imageSrcCompare, setImageSrcCompare] = useState({});
  const [isDownloading, setIsDownloading] = useState(false);
  const [showCompareImage, setShowCompareImage] = useState(false);
  const [showShopFurniture, setShowShopFurniture] = useState(false);
  const [imageShop, setImageShop] = useState(null);
  const [imageSrcEdit, setImageSrcEdit] = useState();
  const [showEditImage, setShowEditImage] = useState(false);
  const [searching, setSearching] = useState(false);
  const [openForm, setOpenForm] = useState(false);

  useEffect(() => {
    function hearts() {
      const elements = document.querySelectorAll('.particletext.hearts');
      elements.forEach(element => {
        const heartcount = (element.offsetWidth / 50) * 2;
        for (let i = 0; i <= heartcount; i++) {
          const size = Math.floor(Math.random() * (12 - 6 + 1)) + 6;
          const top = Math.floor(Math.random() * (80 - 20 + 1)) + 20;
          const left = Math.floor(Math.random() * (95 - 0 + 1)) + 0;
          const delay = Math.floor(Math.random() * (30 - 0 + 1)) + 0;
          const particle = document.createElement('span');
          particle.classList.add('particle');
          particle.style.top = `${top}%`;
          particle.style.left = `${left}%`;
          particle.style.width = `${size}px`;
          particle.style.height = `${size}px`;
          particle.style.animationDelay = `${delay / 10}s`;
          element.appendChild(particle);
        }
      });
    }
    hearts();
  }, []);

  useEffect(() => {
    if (currentJob && currentJob.images.before !== "") {
      if ((currentJob.images.before === currentJob.images.after)) {
        try {
          var reader = new FileReader();
          reader.onload = function(event) {
            var dataURL = event.target.result;
            document.getElementById('img_src_loader').src = dataURL;
          };
          reader.readAsDataURL(currentJob.images.after);
        } catch (error) {
          // message.error(t('message.error.uploadPhoto'));
          console.log(error);
        }
      } else {
        const mergedJobsList = [...[currentJob], ...jobsList];
        setJobsList(mergedJobsList);
      }
    }
  }, [currentJob]);

  function generateRandomFileName(extension) {
    const randomNumber = Math.floor(Math.random() * 1000000000); // Generate a random number between 0 and 999999999
    return `noithatai-${randomNumber}.${extension}`;
  }

  const onCompareImage = (images) => {
    setImageSrcCompare(images)
    setShowCompareImage(true);
  }

  const searchProduct = (imageUrl) => {
    
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${firebase.auth.currentUser.accessToken}`,
    };

    const requestDataEng = {
      imageUrl: imageUrl,
      packageName: modes.pro
    };

    axios.post(`${APP_URL}/search-product`, requestDataEng,{ headers })
    .then(response => {
      // Handle the response data
      setProductsList(response.data);
      setSearching(false);
      setShowShopFurniture(true);
      onFetchUser();
    })
    .catch(error => {
      message.error(t('message.error.busy'));
    });
  }

  const onFindAgency = (image) => {
    setOpenForm(true);
    setImageShop(image);
    setShowShopFurniture(false);
  }

  const onShopFurniture = (image) => {

    if (checkCredits()) {
      setShowShopFurniture(false);
      return;
    }

    setSearching(true);
    setImageShop(image);
    setShowShopFurniture(false);
    searchProduct(image);
  }

  const onEditImage = (imageUrl) => {

    if (checkCredits()) {
      setShowEditImage(false);
      return;
    }

    setImageSrcEdit(imageUrl);
    setShowEditImage(true);
  }

  const onCloseImageCompare = () => {
    setShowCompareImage(false);
  }

  const onCloseShopFurniture = () => {
    setShowShopFurniture(false);
  }

  const onCloseImageEdit = () => {
    setShowEditImage(false);
  }

  const onDownloadImage = (image) => {
    setIsDownloading(true);

    try {
      setTimeout(async () => {
      const response = await fetch(image);
      const blob = await response.blob();
  
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;

      const fileName = generateRandomFileName('jpeg');
      link.download = fileName;
      link.click();
  
      // Clean up the temporary URL
      URL.revokeObjectURL(url);
      setIsDownloading(false);

      confetti({
        origin: { y: 0.5 },
        spread: 400,
        particleCount: 100,
      });

      }, 2000);
    } catch (error) {
      console.error('Error downloading image:', error);
    }
  }

  const onChangeImageSrc = (image) => {
    setImageSrcEdit(image);
  }

  const onCloseAgencyForm = () => {
    setOpenForm(false);
  }

  return (
    <>
      <div className="results-area">
        <h3 style={{marginBottom: "15px"}}>{t('home.title')}</h3>
        <div className="rnftg-h-full rnftg-w-full">
          <div className="rnftg-h-full dark:rnftg-bg-gray-900">
            <div className="rnftg-flex rnftg-flex-col pd-2">
              <div className="rnftg-grid rnftg-gap-6 rnftg-grid-flow-row rnftg-grid-cols-1 md:rnftg-grid-cols-2 lg:rnftg-grid-cols-2 xl:rnftg-grid-cols-4">
              {isCreating &&
                <article className="rnftg-item rnftg-rounded-2xl dark:rnftg-bg-gray-800 rnftg-shadow-lg hover:rnftg-shadow-xl rnftg-transition rnftg-duration-300">
                  <div className="rnftg-item__img-wrapper">
                    <div className="result">
                      <LoaderLineMask/>
                      <span className="rnftg-no-underline rnftg-text-black dark:rnftg-text-gray-200">
                        <img
                          className="rnftg-w-full rnftg-h-full rnftg-object-cover rnftg-cursor-pointer rnftg-rounded-t-2xl"
                          id="img_src_loader"
                          loading="lazy"
                        />
                        <div className="img_src_loader"></div>
                        <div className="prompt-label">AI đang thiết kế</div>
                      </span>
                    </div>
                  </div>
                </article>
              }
              {jobsList.length > 0 &&
                jobsList.map((job, key) => {
                  job.originalUrl = job.images.before;
                  job.upscaleUrl = job.images.after;

                  return (
                  <ImageResult key={key} job={job} onCompareImage={onCompareImage} onDownloadImage={onDownloadImage} onFindAgency={onFindAgency} onEditImage={onEditImage} isDownloading={isDownloading} onUpImageResolution={onUpImageResolution}/>
                  )}
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <section className="selection-thanks-customer">
        <span className="container display-4 fw-bold-600">{t('milestoneMessage.first')}<span className="particletext hearts"> 10.000 </span>{t('milestoneMessage.second')}</span>
      </section> */}
      { showCompareImage && <CompareImage images={imageSrcCompare} onClose={onCloseImageCompare}></CompareImage>}
      { showShopFurniture && <ShopFurniture checkCredits={checkCredits} productsList={productsList} firebase={firebase} t={t} image={imageShop} onClose={onCloseShopFurniture}></ShopFurniture>}
      { showEditImage && <EditImage checkCredits={checkCredits} imageUrl={imageSrcEdit} onClose={onCloseImageEdit} firebase={firebase} t={t} onGenFill={onGenFill} onChangeImageSrc={onChangeImageSrc} onFetchUser={onFetchUser}></EditImage>}
      { searching && <Searching title="Searching products"/> }
      { openForm && <AgencyForm userData={userData} productsList={productsList} firebase={firebase} t={t} image={imageShop} onClose={onCloseAgencyForm}></AgencyForm>}
    </>
  );
};

export default AllShowCase;
