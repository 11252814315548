import React from 'react';
import { Button } from 'antd';

const ImageAgency = ({ job, onCompareImage, onDownloadImage, onFindAgency, onEditImage, isDownloading }) => {

  return (
    <article className="rnftg-item rnftg-rounded-2xl dark:rnftg-bg-gray-800 rnftg-shadow-lg hover:rnftg-shadow-xl rnftg-transition rnftg-duration-300">
      <div className="rnftg-item__img-wrapper">
        <div className="result">
          <img
            className="rnftg-w-full rnftg-h-full rnftg-object-cover rnftg-cursor-pointer rnftg-rounded-t-2xl result-wrapper"
            loading="lazy"
            src={job.upscaleUrl}
          />
          <div className="agency-actions">
            <Button
              onClick={() => onFindAgency(job.upscaleUrl)}
              className="image_action open_shop"
              type="button"
            >
            <svg fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path d="M21,2H3A1,1,0,0,0,2,3V21a1,1,0,0,0,1,1h7a1,1,0,0,0,1-1V11H21a1,1,0,0,0,1-1V3A1,1,0,0,0,21,2ZM20,9H19V7a1,1,0,0,0-2,0V9H15V7a1,1,0,0,0-2,0V9H11V7A1,1,0,0,0,9,7V9H7a1,1,0,0,0,0,2H9v2H7a1,1,0,0,0,0,2H9v2H7a1,1,0,0,0,0,2H9v1H4V4H20Z"/>
            </svg>
            </Button>
            <span>Tư vấn và thi công</span>
          </div>
          <div className="blur-image" />
        </div>
      </div>
    </article>
  );
};

export default ImageAgency;
