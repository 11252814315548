import React, { useEffect, useRef, useState } from 'react';
import Swiper from 'swiper';
import ProgressBar from '../ProgressBar';
import axios from 'axios';
import { message } from 'antd';

const APP_URL = process.env.REACT_APP_APP_URL;

const themesImageStyle = {
  border: "3px solid #ffa227",
  borderRadius: "14px",
}

const Themes = ({onSetThemes, themesList, t}) => {

  // const [themes, setThemes] = useState('');
  const [selectedImage, setSelectedImage] = useState(null);

  const onShowImage = (index, themes) => {
    setSelectedImage(index);
    onSetThemes(themes);
  }

  return (
    <div className="w-100">
      <div className="page-header mb-0 page-header-reset mb-2"/>
      <div className="swiper">
        <div className="themes-wrapper">
        {themesList.length > 0 && themesList.map((theme, index) => (
          <div key={index} className="swiper-slide">
            <div className="listing-tile w-100 h-100" data-controller="listing-tile">
              <div className="theme-box" onClick={()=>onShowImage(index, theme)}>
                <div className="media" >
                    <img src={theme.url} loading='lazy' alt="Finished" style={selectedImage === index? themesImageStyle: null}/>
                </div>
                <div className="prompt-label">{theme.label}</div>
              </div>
            </div>
          </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Themes;
