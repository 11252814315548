import React, { useState } from 'react';
import Header from './Header';
import Image from './Image';
import BeforeAfter from '../BeforeAfter';

const CompareImage = ({ images, onClose}) => {
  
  const [rotationDeg, setRotationDeg] = useState(0);
  const [move, setMove] = React.useState({
    x: 0,
    y: 0
  });

  
  const toggleRotate = (event) => {
    event.preventDefault();
  
    if (rotationDeg === 360) setRotationDeg(90);
    else setRotationDeg(rotationDeg + 90);
  };

  return (
      <div>
        <div className="react_modal_images__modal_container"
          style={{
            position: 'fixed',
            zIndex: '5000',
            left: '0',
            top: '0',
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
            touchAction: 'none',
            overflow: 'hidden'
          }}>
          <div className="react_modal_images__modal_content" style={{
            position: 'relative',
            height: '100%',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}>
            <BeforeAfter
              images={images}
            />
          </div>
          <Header
            toggleRotate={toggleRotate}
            onClose={onClose}
            enableRotate={false}
          />
        </div>
      </div>
  );
};

export default CompareImage;
