import React from 'react';

const LoaderLineMask = () => {

  return (
    <div className="line-mask">
      <div className="loader-wrapper">
      <p/><p/><p/><p/>
      </div>
    </div>
  );
};

export default LoaderLineMask;
