import React, { useEffect, useState, useRef } from 'react';
import { message } from 'antd';
import confetti from 'canvas-confetti';
import CompareImage from '../CompareImage';
import ShopFurniture from '../ShopFurniture';
import axios from 'axios';
import Searching from '../Loading';
import EditImage from '../EditImage';
import Spinner from '../Spinner';
import ImageResult from '../ImageResult';
import AgencyForm from '../AgencyForm';

const modes = {
  pro: 'Pro',
  basic: 'Basic',
};

const APP_URL = process.env.REACT_APP_APP_URL;

const MyDesign = ({ userData, t, isCreating, firebase, onGenFill, onFetchUser, checkCredits, onUpImageResolution }) => {
  const scrollableRef = useRef(null);
  const [jobsList, setJobsList] = useState([]);
  const [productsList, setProductsList] = useState([]);
  const [imageSrcCompare, setImageSrcCompare] = useState({});
  const [isDownloading, setIsDownloading] = useState(false);
  const [showCompareImage, setShowCompareImage] = useState(false);
  const [showShopFurniture, setShowShopFurniture] = useState(false);
  const [imageShop, setImageShop] = useState(null);
  const [imageSrcEdit, setImageSrcEdit] = useState();
  const [showEditImage, setShowEditImage] = useState(false);
  const [searching, setSearching] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [lastJobCreatedAt, setLastJobCreatedAt] = useState("");
  const [isSpinning, setIsSpinning] = useState(false);
  const [openForm, setOpenForm] = useState(false);

  useEffect(() => {
    setIsSpinning(true);
    firebase.getFirstJobs(firebase.auth.currentUser.uid)
      .then((result) => {
        const jobsArray = result.docs
          .map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));

        if (jobsArray.length > 1) {
          setJobsList(jobsArray);
          setLastJobCreatedAt(jobsArray[jobsArray.length - 1].createdAt);
        }
        setTimeout(() => {
          setIsSpinning(false);
        }, 1000);
      })
      .catch((error) => {
        console.log(error);
      });
    return () => {
    };
  }, []);
  useEffect(() => {
    const handleScroll = () => {
      const { scrollTop } = scrollableRef.current;
      if (
        window.innerHeight + scrollTop >=
        scrollableRef.current.scrollHeight - 1
      ) {
        loadMorePhotos(isLoading, jobsList, lastJobCreatedAt);
      }
    };

    // Attach the scroll event listener to the scrollable element
    scrollableRef.current?.addEventListener('scroll', handleScroll);

    return () => {
      // Remove the scroll event listener when the component unmounts
      scrollableRef.current?.removeEventListener('scroll', handleScroll);
    };
  }, [isLoading, lastJobCreatedAt, jobsList]);

  const loadMorePhotos = async (loading, jobs, lastCreatedAt) => {
    if (!loading && lastCreatedAt !== "") {
      setIsLoading(true);
      firebase.getJobsPagination(firebase.auth.currentUser.uid, lastCreatedAt)
        .then((result) => {

          const jobsArray = result.docs
            .filter((doc) => doc.data().mode !== "BASIC")
            .map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }));

          if (jobsArray.length > 0) {
            setTimeout(() => {
              const mergedJobsList = [...jobs, ...jobsArray];
              setLastJobCreatedAt(jobsArray[jobsArray.length - 1].createdAt);
              setJobsList(mergedJobsList);
              setIsLoading(false);
            }, 500);
          } else {
            setLastJobCreatedAt("");
            setIsLoading(true);
          }

        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  function generateRandomFileName(extension) {
    const randomNumber = Math.floor(Math.random() * 1000000000); // Generate a random number between 0 and 999999999
    return `noithatai-${randomNumber}.${extension}`;
  }

  const onCompareImage = (images) => {
    setImageSrcCompare(images)
    setShowCompareImage(true);
  }

  const searchProduct = (imageUrl) => {

    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${firebase.auth.currentUser.accessToken}`,
    };

    const requestDataEng = {
      imageUrl: imageUrl,
      packageName: modes.pro
    };

    axios.post(`${APP_URL}/search-product`, requestDataEng, { headers })
      .then(response => {
        // Handle the response data
        setProductsList(response.data);
        setSearching(false);
        setShowShopFurniture(true);
        onFetchUser();
      })
      .catch(error => {
        message.error(t('message.error.busy'));
      });
  }

  const onFindAgency = (image) => {
    setOpenForm(true);
    setImageShop(image);
    setShowShopFurniture(false);
  }

  const onShopFurniture = (image) => {

    if (checkCredits()) {
      setShowShopFurniture(false);
      return;
    }

    setSearching(true);
    setImageShop(image);
    setShowShopFurniture(false);
    searchProduct(image);
  }

  const onEditImage = (imageUrl) => {

    if (checkCredits()) {
      setShowEditImage(false);
      return;
    }

    setImageSrcEdit(imageUrl);
    setShowEditImage(true);
  }

  const onCloseImageCompare = () => {
    setShowCompareImage(false);
  }

  const onCloseShopFurniture = () => {
    setShowShopFurniture(false);
  }

  const onCloseImageEdit = () => {
    setShowEditImage(false);
  }

  const onDownloadImage = (image) => {
    setIsDownloading(true);

    try {
      setTimeout(async () => {
        const response = await fetch(image);
        const blob = await response.blob();

        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;

        const fileName = generateRandomFileName('jpeg');
        link.download = fileName;
        link.click();

        // Clean up the temporary URL
        URL.revokeObjectURL(url);
        setIsDownloading(false);

        confetti({
          origin: { y: 0.5 },
          spread: 400,
          particleCount: 100,
          origin: { y: 0.4 },
        });

      }, 2000);
    } catch (error) {
      console.error('Error downloading image:', error);
    }
  }

  const onChangeImageSrc = (image) => {
    setImageSrcEdit(image);
  }

  const onCloseAgencyForm = () => {
    setOpenForm(false);
  }



  return (
    <>
      <div className="my-design" ref={scrollableRef}>
        <h3>{t('header.nav.myFlowers')}</h3>
        <p style={{ color: '#8c8c8c', fontSize: '14px', marginTop: "0", marginBottom: "1rem"}}>
          {"Cuộn để xem tất cả thiết kế của bạn."}
        </p>
        <div className="rnftg-h-full rnftg-w-full">
          <div className="rnftg-h-full dark:rnftg-bg-gray-900">
            <div className="rnftg-flex rnftg-flex-col pd-2">
              <div className="rnftg-grid rnftg-gap-6 rnftg-grid-flow-row rnftg-grid-cols-1 md:rnftg-grid-cols-2 lg:rnftg-grid-cols-3 xl:rnftg-grid-cols-3">
                {jobsList.length > 0 &&
                  jobsList.map((job, key) => (
                    <ImageResult key={key} job={job} onCompareImage={onCompareImage} onDownloadImage={onDownloadImage} onFindAgency={onFindAgency} onEditImage={onEditImage} isDownloading={isDownloading} onUpImageResolution={onUpImageResolution}/>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <section className="selection-thanks-customer">
        <span className="container display-4 fw-bold-600">{t('milestoneMessage.first')}<span className="particletext hearts"> 10.000 </span>{t('milestoneMessage.second')}</span>
      </section> */}
      {showCompareImage && <CompareImage images={imageSrcCompare} onClose={onCloseImageCompare}></CompareImage>}
      {showShopFurniture && <ShopFurniture productsList={productsList} firebase={firebase} t={t} image={imageShop} onClose={onCloseShopFurniture}></ShopFurniture>}
      {showEditImage && <EditImage checkCredits={checkCredits} imageUrl={imageSrcEdit} onClose={onCloseImageEdit} firebase={firebase} t={t} onGenFill={onGenFill} onChangeImageSrc={onChangeImageSrc} onFetchUser={onFetchUser}></EditImage>}
      {searching && <Searching title="Searching products" />}
      {openForm && <AgencyForm userData={userData} productsList={productsList} firebase={firebase} t={t} image={imageShop} onClose={onCloseAgencyForm}></AgencyForm>}
      {isSpinning && <Spinner />}
    </>
  );
};

export default MyDesign;
