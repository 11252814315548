import { initializeApp } from 'firebase/app';
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signInWithPhoneNumber,
  signOut,
  sendEmailVerification,
  sendPasswordResetEmail,
  updatePassword,
  signInWithPopup,
  GoogleAuthProvider,
  RecaptchaVerifier,
  linkWithCredential,
} from 'firebase/auth';
import {
  getFirestore,
  doc,
  setDoc,
  getDoc,
  updateDoc,
  collection,
  getDocs,
  addDoc,
  query,
  orderBy,
  limit,
  where,
  startAfter,
} from 'firebase/firestore';
// import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

import firebaseConfig from './config';
import { getStorage, ref, getDownloadURL, uploadBytesResumable, uploadBytes } from 'firebase/storage';

class Firebase {
  constructor() {
    this.firebase = initializeApp(firebaseConfig);

    this.auth = getAuth();
    this.firestore = getFirestore();
    this.storage = getStorage(this.app);
  }

  signUp = (email, password) => createUserWithEmailAndPassword(this.auth, email, password);

  signIn = (email, password) => signInWithEmailAndPassword(this.auth, email, password);

  signInWithGoogle = () => signInWithPopup(this.auth, new GoogleAuthProvider());

  signOut = () => signOut(this.auth);

  sendEmailVerificationLink = () =>
    // sendEmailVerification(this.auth.currentUser, {
    //   url: process.env.REACT_APP_EMAIL_CONFIRMATION_REDIRECT,
    // });
    sendEmailVerification(this.auth.currentUser);

  resetPassword = email => sendPasswordResetEmail(this.auth, email);

  updatePassword = password => updatePassword(this.auth.currentUser, password);

  addUser = (uid, data) => setDoc(doc(this.firestore, 'users', uid), data);

  getUser = uid => getDoc(doc(this.firestore, 'users', uid));

  getFlowers = () => getDocs(collection(this.firestore, 'flowers'));

  updateProfile = (uid, data) => updateDoc(doc(this.firestore, 'users', uid), data);

  generateRecaptchaVerifier = onSignup => {
    window.recaptchaVerifier = new RecaptchaVerifier(
      'recaptcha-container',
      {
        size: 'normal',
        callback: onSignup,
        'expired-callback': function () {
          console.log('expired-callback');
        },
      },
      this.auth
    );
  };

  verifyWithPhoneNumber = phoneNumber => {
    const appVerifier = window.recaptchaVerifier;
    return signInWithPhoneNumber(this.auth, phoneNumber, appVerifier);
  };

  mergeUser = (previousUser, userCredential) => {
    const result = this.auth.currentUser
      .delete()
      .then(() => {
        // Now we're connecting the previousUser which represents the provider account that the user used to
        linkWithCredential(previousUser, userCredential);
      })
      .catch(err => {
        console.log(err);
      });

    return result;
  };

  addJob = data => addDoc(collection(this.firestore, 'jobs'), data);
  getJobs = uid => getDocs(query(collection(this.firestore, 'jobs'), where('uid', '==', uid), orderBy('createdAt', 'desc'), limit(5)));

  getFirstJobs = async uid => getDocs(query(collection(this.firestore, 'jobs'), where('uid', '==', uid), orderBy('createdAt', 'desc'), limit(20)));
  getJobsPagination = (uid, lastJobCreatedAt) =>
    getDocs(
      query(
        collection(this.firestore, 'jobs'),
        where('uid', '==', uid),
        orderBy('createdAt', 'desc'),
        startAfter(lastJobCreatedAt),
        limit(20)
      )
    );

  updateJob = (jobId, data) => updateDoc(doc(this.firestore, 'jobs', jobId), data);

  getStyles = () => getDocs(query(collection(this.firestore, 'styles')));
  getDetailedStyles = () => getDocs(query(collection(this.firestore, 'detailedStyles')));
  getEvents = () => getDocs(query(collection(this.firestore, 'events')));
  getBackGrounds = () => getDocs(query(collection(this.firestore, 'backgrounds')));
  getFlowerFamilies = () => getDocs(query(collection(this.firestore, 'families')));
  getDecoration = () => getDocs(query(collection(this.firestore, 'decoration')));
  getGrasses = () => getDocs(query(collection(this.firestore, 'grasses')));

  getTransactions = uid =>
    getDocs(
      query(
        collection(this.firestore, 'transactions'),
        where('uid', '==', uid),
        where('isDeleted', '==', false),
        orderBy('createdAt', 'desc'),
        limit(1)
      )
    );
  getThemes = () => getDocs(collection(this.firestore, 'themes'));
  getRooms = () => getDocs(collection(this.firestore, 'rooms'));

  getRequests = uid => getDocs(query(collection(this.firestore, 'requests'), where('uid', '==', uid), orderBy('createdAt', 'desc')));
  getAffRefs = refCode => getDocs(query(collection(this.firestore, 'affRefs'), where('refCode', '==', refCode), orderBy('createdAt', 'desc')));
  getWithdrawRequests = uid => getDocs(query(collection(this.firestore, 'withdrawRequests'), where('uid', '==', uid), orderBy('createdAt', 'desc')));

  // uploadFile = (file) => {

  //   if (!file) return null ;

  //   const storageRef = ref(this.storage, `files/${file.name}`);
  //   const uploadTask = uploadBytesResumable(storageRef, file);

  //   uploadTask.on("state_changed",
  //     (snapshot) => {
  //       const progress =
  //         Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
  //     },
  //     (error) => {
  //       alert(error);
  //     }
  //   );
  //   return uploadTask;
  // }
  uploadFile = file => {
    if (!file) return Promise.reject('No file provided.');

    const storageRef = ref(this.storage, `files/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    return new Promise((resolve, reject) => {
      uploadTask.on(
        'state_changed',
        snapshot => {
          const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
        },
        error => {
          reject(error);
        },
        () => {
          // Upload completed
          getDownloadURL(uploadTask.snapshot.ref)
            .then(downloadURL => {
              resolve(downloadURL);
            })
            .catch(error => {
              reject(error);
            });
        }
      );
    });
  };

  uploadFileFromLink = async (link, filename) => {
    return fetch(link)
      .then(response => response.blob())
      .then(blob => {
        const storageRef = ref(this.storage, `files/${filename}`);
        return uploadBytes(storageRef, blob);
      })
      .then(snapshot => {
        return getDownloadURL(snapshot.ref);
      })
      .catch(error => {
        console.error('Upload error:', error);
        throw error;
      });
  };

  getDownloadURL = uploadTask => {
    return getDownloadURL(uploadTask.snapshot.ref);
  };

  getDownloadURLByFileName = async fileName => {
    return getDownloadURL(ref(this.storage, fileName))
      .then(url => {
        // This can be downloaded directly:
        const xhr = new XMLHttpRequest();
        xhr.responseType = 'blob';
        xhr.onload = event => {
          const blob = xhr.response;
        };
        xhr.open('GET', url);
        xhr.send();

        // Or inserted into an <img> element
        const img = document.getElementById('myimg');
        img.setAttribute('src', url);
      })
      .catch(error => {
        // Handle any errors
      });
  };

  getRefLink = uid => getDocs(query(collection(this.firestore, 'affMembers'), where('uid', '==', uid), limit(1)));
}

export default Firebase;
